// @ts-ignore
import React, {Component} from "react";
import Menu from "../menu/Menu";
import Cookies from "universal-cookie";
import axios from "axios";
import DeleteStore from "./deleteStore/DeleteStore";

export class EditStore extends Component <any, any> {
    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: false,
            idStore: this.props.idStore,
            store: [],
            users: [],
            name: '',
            emailUser: '',

            cookies: cookies.get('bearer'),


        };
        this.changeSettingsStore = this.changeSettingsStore.bind(this);
        this.changeName = this.changeName.bind(this);
        this.changeEmailUser = this.changeEmailUser.bind(this);

    }

    changeName(event) {
        console.log(event.target.value)
        this.setState({name: event.target.value});
    }

    changeEmailUser(event) {
        this.setState({emailUser: event.target.value});
    }

    componentDidMount() {
        const data = {
            store_id: this.props.idStore,

        };
        axios({

            url: 'https://api.cliclishop.ru/lk/store/settings/show',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.setState(
                {
                    name: result.data.name,
                    users: result.data.users,
                    isLoaded: true,
                });
        })


    }

    changeSettingsStore() {

        const data = {
            store_id: this.props.idStore,
            nameStore: this.state.name,
            email: this.state.emailUser,

        };
        axios({

            url: 'https://api.cliclishop.ru/lk/store/settings/change',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.setState(
                {});
        })


    }


    render() {
        const {store, isLoaded} = this.state;
        if (isLoaded) {
            return (<div>
                <Menu/>
                <div id="right_dark_space"></div>
                <div style={window.screen.width > 650 ? {width: 'calc(100% - 100px)'} : {width: '100%'}}
                     id="right_space">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Главная</a></li>
                            <li className="breadcrumb-item"><a href="/store">Магазины</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Настройки магазина</li>
                        </ol>
                    </nav>
                    <h1 className="text-center" style={{margin: '40px auto 20px auto'}}>Настройки магазина</h1>
                    <div className="shadow-card" style={{margin: '40px'}}>

                        <h4 className="text-center" style={{margin: '0 auto 20px auto'}}>Поделиться управлением
                            магазином</h4>
                        <div className="row">
                            <div className="col-4">
                                <form className="form-inline">
                                    <div className="form-group mx-sm-3 mb-2">
                                        <input value={this.state.email} onChange={this.changeEmailUser} type="text"
                                               className="form-control"
                                               placeholder="EmailUser"/>
                                    </div>
                                    <button onClick={() => this.changeSettingsStore()} type="button"
                                            className="btn btn-primary mb-2">Добавить
                                    </button>
                                </form>
                                <h5 className="text-center">Пользователи имеющие доступ к управлению магазином</h5>
                                <div>
                                    <p className="text-center">Email пользователей:</p>
                                    {this.state.users.map(items => (
                                        <p className="text-center">{items}</p>
                                    ))}
                                </div>
                            </div>
                            <div className="col-8">
                                <div className="alert alert-danger">
                                    Что будет если выключить магазин?<br/>
                                    - Он будет автоматически скрыт на вашем сайте. Если в этот момент клиент начал
                                    делать заказ -
                                    ему придет уведомление о том, что продавец скрыл все товары.<br/><br/>
                                    Удалятся ли каталоги и товары из этого магазина, если его выключить?<br/>
                                    - Все каталоги и товары останутся, просто станут недоступны для просмотра на вашем
                                    сайте.<br/><br/>
                                    Смогу ли я изменять информацию в выключенном магазине?<br/>
                                    - Да, можно будет добавлять, удалять каталоги, товары и изменять любую информацию в
                                    них.
                                </div>
                            </div>
                        </div>

                        <hr/>

                        <h4 className="text-center" style={{margin: '20px auto'}}>Изменение названия магазина</h4>
                        <div className="row">
                            <div className="col-4">
                                <form className="form-inline">
                                    <div className="form-group mx-sm-3 mb-2">
                                        <input value={this.state.name} onChange={this.changeName} type="text"
                                               className="form-control" id="inputPassword2"
                                               placeholder="Название"/>
                                    </div>
                                    <button onClick={() => this.changeSettingsStore()} type="button"
                                            className="btn btn-primary mb-2">Сохранить
                                    </button>
                                </form>
                            </div>
                            <div className="col-8">
                                <div className="alert alert-danger">
                                    Что будет если изменить название магазина?<br/>
                                    - Если название магазина отображается на вашем сайте - оно будет изменено. Вся
                                    остальная
                                    информация останется неизменной, включая каталоги, категории и товары.
                                </div>
                            </div>
                        </div>

                        <hr/>

                        <h4 className="text-center" style={{margin: '20px auto'}}>Удаление магазина</h4>
                        <DeleteStore idStore={this.props.idStore}/>
                    </div>
                </div>
            </div>)
        } else {
            return (<div>
                <Menu/>
                <div id="right_dark_space"></div>
                <div style={window.screen.width > 650 ? {width: 'calc(100% - 100px)'} : {width: '100%'}}
                     id="right_space">

                    <div className="preloader d-flex justify-content-center">
                        <div className="align-middle spinner-grow text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>

            </div>)
        }


    }
}

// @ts-ignore
export default EditStore;