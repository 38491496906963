// @ts-ignore
import React, {Component} from "react";
// import Menu from "../menu/Menu";
// import Cookies from "universal-cookie";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore
import Menu from "../menu/Menu";
// @ts-ignore
import Cookies from "universal-cookie";
import axios from "axios";
import Notification from "../Notification";

export class Category extends Component <any, any> {

    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: false,
            idProduct: this.props.idProduct,
            product: [],
            image: [],
            cookies: cookies.get('bearer'),
            idStore: this.props.idStore,
            idCatalog: this.props.idCatalog,
            category: [],
            categoryActive: [],
            newCategoryName: '',
            newCategoryParent: '0',
            editCategoryName: '',
            editCategoryId: '',
            editCategoryParent: '',
            deleteCategoryId: '',
            deleteCategoryParent: null,
            deleteCategoryDeleteActive: false,
            deleteCategoryHiddenActive: false,
            selectedFile: [],
            editCategoryLink: '',


        };

        this.handleChangeNewCategoryName = this.handleChangeNewCategoryName.bind(this);
        this.handleChangeNewCategoryParent = this.handleChangeNewCategoryParent.bind(this);
        this.handleChangeEditCategoryName = this.handleChangeEditCategoryName.bind(this);
        this.handleChangeEditCategoryParent = this.handleChangeEditCategoryParent.bind(this);
        this.handleChangeEditCategoryId = this.handleChangeEditCategoryId.bind(this);
        this.handleChangeDeleteCategoryId = this.handleChangeDeleteCategoryId.bind(this);
        this.handleChangeDeleteCategoryParentId = this.handleChangeDeleteCategoryParentId.bind(this);
        this.handleChangeDeleteCategoryHiddenActive = this.handleChangeDeleteCategoryHiddenActive.bind(this);
        this.handleChangeDeleteCategoryDeleteActive = this.handleChangeDeleteCategoryDeleteActive.bind(this);
    }

    handleChangeDeleteCategoryId(event) {
        console.log(event.target.value)
        this.setState({deleteCategoryId: event.target.value});
    }

    handleChangeDeleteCategoryDeleteActive(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        console.log(value)
        this.setState({deleteCategoryDeleteActive: value});
    }

    handleChangeDeleteCategoryHiddenActive(event) {
        // console.log(event.target.value)
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({deleteCategoryHiddenActive: value});
    }

    handleChangeDeleteCategoryParentId(event) {
        console.log(event.target.value)
        this.setState({deleteCategoryParent: event.target.value});
    }

    handleChangeEditCategoryName(event) {
        console.log(event.target.value)
        this.setState({editCategoryName: event.target.value});
    }

    handleChangeEditCategoryId(event) {
        console.log(event.target.value)
        const temp1 = this.state.category.filter(item => item.id == event.target.value);
        console.log(temp1)
        let temp2
        if (temp1[0].parent) {
            temp2 = temp1[0].parent.id
        } else {
            temp2 = '0'
        }
        this.setState({
                editCategoryId: event.target.value,
                editCategoryName: temp1[0].name,
                editCategoryLink: temp1[0].image,
                editCategoryParent: temp2
            },
        );
    }

    notification(text) {
        const data = this.state.notification
        const element = {text: text, time: 25};
        const newArray = [...data, element];
        this.setState(
            {
                notification: newArray,

            });
    }

    handleChangeEditCategoryParent(event) {
        console.log(event.target.value)
        this.setState({editCategoryParent: event.target.value});
    }

    handleChangeNewCategoryName(event) {
        console.log(event.target.value)
        this.setState({newCategoryName: event.target.value});
    }

    handleChangeNewCategoryParent(event) {
        console.log(event.target.value)
        this.setState({newCategoryParent: event.target.value});
    }

    componentDidMount() {
        const data = {
            store_id: this.state.idStore,
            catalog_id: this.state.idCatalog,
            product_id: this.props.idProduct
        };
        axios({

            url: 'https://api.cliclishop.ru/lk/catalog/categories',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.setState(
                {
                    category: result.data,

                    isLoaded: true,
                });
            if (result.data.length > 0) {
                this.setState(
                    {

                        editCategoryLink: result.data[0].image,
                        editCategoryId: result.data[0].id,
                        editCategoryName: result.data[0].name
                    });
            }
        })


    }

    menuNode(item) {


        if (item.parent == null) {
            return (<li>{item.name}
                <ul>{item.categories.map(items => (
                    this.childrenCategory(items)
                ))}</ul>
            </li>)
        }
    }

    childrenCategory(item) {
        if (item.categories.length > 0) {
            return (<li>{item.name}
                <ul>{item.categories.map(items => (
                    this.childrenCategory(items)
                ))}</ul>
            </li>)
        }
        if (item.categories.length === 0) {
            return (<li>{item.name}</li>)
        }
    }

    addNewCategory(link, size) {
        const data = {
            store_id: this.state.idStore,
            catalog_id: this.state.idCatalog,
            product_id: this.props.idProduct,
            categoryParent_id: this.state.newCategoryParent,
            nameCategory: this.state.newCategoryName,
            link: link,
            size: size,
        };
        axios({

            url: 'https://api.cliclishop.ru/lk/catalog/categories/add',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.componentDidMount()
            this.setState(
                {
                    newCategoryName: '',
                });
            // if(result.data.text){
            //     this.notification(result.data.text)
            // }
        })
    }

    onClickHandler = () => {
        if (this.state.selectedFile.length > 0) {
            for (var x = 0; x < this.state.selectedFile.length; x++) {
                const data = new FormData()
                console.log(this.state.selectedFile[x])
                data.append('file', this.state.selectedFile[x])
                console.log(data)
                axios.post('https://image.cliclishop.ru/image', data, {
                    headers: {}
                    // receive two    parameter endpoint url ,form data
                }).then(res => { // then print response status

                    this.addNewCategory(res.data.link, res.data.size)

                })
            }
        } else {
            this.addNewCategory(null, null)
        }


    }
    onClickEditImage = () => {
        if (this.state.selectedFile == null) {

            this.editCategory(null, null)


        } else {
            for (var x = 0; x < this.state.selectedFile.length; x++) {
                const data = new FormData()
                console.log(this.state.selectedFile[x])
                data.append('file', this.state.selectedFile[x])
                console.log(data)
                axios.post('https://image.cliclishop.ru/image', data, {
                    headers: {}
                    // receive two    parameter endpoint url ,form data
                }).then(res => { // then print response status
                    this.setState({
                        selectedFile: null
                    })

                    this.editCategory(res.data.link, res.data.size)

                })
            }
        }


    }

    editCategory(link, size) {
        const data = {
            store_id: this.state.idStore,
            catalog_id: this.state.idCatalog,
            product_id: this.props.idProduct,
            categoryParent_id: this.state.editCategoryParent,
            nameCategory: this.state.editCategoryName,
            idCategory: this.state.editCategoryId,
            link: link,
        };
        console.log(data)
        axios({

            url: 'https://api.cliclishop.ru/lk/catalog/' + this.state.idCatalog + '/categories/edit',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.componentDidMount()
            this.setState(
                {
                    editCategoryName: '',
                });
            // if(result.data.text){
            //     this.notification(result.data.text)
            // }
        })

    }

    deleteCategory() {
        const data = {
            store_id: this.state.idStore,
            catalog_id: this.state.idCatalog,
            product_id: this.props.idProduct,
            idCategory: this.state.deleteCategoryId,
            hidden: this.state.deleteCategoryHiddenActive,
            delete: this.state.deleteCategoryDeleteActive,
            deleteCategoryParent: this.state.deleteCategoryParent,
        };
        axios({

            url: 'https://api.cliclishop.ru/lk/catalog/categories/delete',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.componentDidMount()
            this.setState(
                {
                    editCategoryName: '',
                });
            // if(result.data.text){
            //     this.notification(result.data.text)
            // }
        })


    }

    maxSelectFile = (event) => {
        let files = event.target.files // create file object
        if (files.length > 10) {
            const msg = 'Only 3 images can be uploaded at a time'
            event.target.value = null // discard selected file
            console.log(msg)
            return false;

        }
        return true;

    }
    onChangeHandler = event => {
        var files = event.target.files
        if (this.maxSelectFile(event)) {
            // if return true allow to setState
            this.setState({
                selectedFile: files
            })
        }
    }

    render() {
        const {isLoaded, category} = this.state;
        if (isLoaded) {
            return (<div>
                <h2 className="text-center" style={{marginBottom: '20px', marginTop: '30px'}}>Настройки категорий</h2>
                <h4 className={'text-center'} style={{marginBottom: '20px', marginTop: '30px'}}>Список категорий</h4>
                <ul>
                    {category.map(items => (

                        this.menuNode(items)
                    ))

                    }
                </ul>
                <hr/>
                <h4 className={'text-center'} style={{marginBottom: '20px', marginTop: '30px'}}>Добавить новую
                    категорию</h4>
                <form>
                    <h4 className="text-center" style={{marginBottom: '20px', marginTop: '30px'}}>Изображения
                        товара</h4>

                    <div className="form-group">
                        <label htmlFor="exampleFormControlFile1">Выберите изображения</label>

                        <input multiple name="file" onChange={this.onChangeHandler} type="file"
                               className="form-control-file" id="exampleFormControlFile1"/>
                    </div>

                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Наименование новой категории</label>
                        <input value={this.state.newCategoryName}
                               onChange={this.handleChangeNewCategoryName} type="text" className="form-control"
                               id="exampleInputEmail1"
                               aria-describedby="emailHelp"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Родительская категория</label>
                        <select value={this.state.newCategoryParent}
                                onChange={this.handleChangeNewCategoryParent} className="form-control"
                                id="exampleFormControlSelect1">
                            <option value='0'>Без родительской категории</option>
                            {category.map(items => (

                                <option value={items.id}>{items.name}</option>
                            ))}
                        </select>
                    </div>

                    <div style={{marginBottom: '30px'}} className="text-center">
                        <div className="middle_button text-center">
                            <a onClick={() => this.onClickHandler()} style={{color: "white", textDecoration: "none"}}>Добавить
                                категорию</a>
                        </div>

                    </div>
                </form>
                <hr/>
                <h4 className={'text-center'} style={{marginBottom: '20px', marginTop: '30px'}}>Изменить категорию</h4>
                <form className={'row'}>
                    <div className={'col-3'}>
                        <img style={{height: 'auto', width: '100%'}} className="image_from_goods_list"
                             src={this.state.editCategoryLink}/>

                    </div>
                    <div className={'col-9'}>
                        <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Выберите категорию, которую хотите изменить</label>
                            <select value={this.state.editCategoryId}
                                    onChange={this.handleChangeEditCategoryId} className="form-control"
                                    id="exampleFormControlSelect1">
                                {category.map(items => (

                                    <option value={items.id}>{items.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Новое название категории</label>
                            <input value={this.state.editCategoryName}
                                   onChange={this.handleChangeEditCategoryName} type="text" className="form-control"
                                   id="exampleInputEmail1"
                                   aria-describedby="emailHelp"/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Выберите родительскую категорию</label>
                            <select value={this.state.editCategoryParent}
                                    onChange={this.handleChangeEditCategoryParent} className="form-control"
                                    id="exampleFormControlSelect1">
                                <option value='0'>Без родительской категории</option>
                                {category.map(items => (

                                    <option value={items.id}>{items.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleFormControlFile1">Выберите изображения</label>

                            <input multiple name="file" onChange={this.onChangeHandler} type="file"
                                   className="form-control-file" id="exampleFormControlFile1"/>
                        </div>
                        <div style={{marginBottom: '30px'}} className="text-center">
                            <div className="middle_button text-center">
                                <a onClick={() => this.onClickEditImage()}
                                   style={{color: "white", textDecoration: "none"}}>Изменить
                                    категорию</a>
                            </div>

                        </div>
                    </div>

                </form>
                <h4 className={'text-center'} style={{marginBottom: '20px', marginTop: '30px'}}>Удалить категорию</h4>
                <form>
                    <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Выберите категорию, которую хотите удалить</label>
                        <select value={this.state.deleteCategoryId}
                                onChange={this.handleChangeDeleteCategoryId} className="form-control"
                                id="exampleFormControlSelect1">
                            {category.map(items => (

                                <option value={items.id}>{items.name}</option>
                            ))}
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="exampleInputPassword1">После удаления категории, товары принадлежащие удаляемой
                            категории переместить в категорию:</label>
                        <select value={this.state.deleteCategoryParent}
                                onChange={this.handleChangeDeleteCategoryParentId} className="form-control"
                                id="exampleFormControlSelect1">
                            <option value='0'>Без родительской категории</option>
                            {category.map(items => (

                                <option value={items.id}>{items.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group form-check">
                        <input checked={this.state.deleteCategoryHiddenActive}
                               onChange={this.handleChangeDeleteCategoryHiddenActive} type="checkbox"
                               className="form-check-input" id="exampleCheck1"/>
                        <label className="form-check-label" htmlFor="exampleCheck1">Cкрыть товары</label>
                    </div>
                    <div className="form-group form-check">
                        <input checked={this.state.deleteCategoryDeleteActive}
                               onChange={this.handleChangeDeleteCategoryDeleteActive} type="checkbox"
                               className="form-check-input" id="exampleCheck1"/>
                        <label className="form-check-label" htmlFor="exampleCheck1">Удалить товары</label>
                    </div>
                    <div onClick={() => this.deleteCategory()} style={{marginBottom: '30px'}} className="text-center">
                        <div className="middle_button text-center">
                            <a style={{color: "white", textDecoration: "none"}}>Удалить категорию</a>
                        </div>

                    </div>
                </form>
            </div>)
        } else {
            return (<div className="preloader d-flex justify-content-center">
                <div className="align-middle spinner-grow text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>)
        }


    }
}

// @ts-ignore
export default Category;