// @ts-ignore
import React, {Component} from "react";
// import Menu from "../menu/Menu";
// import Cookies from "universal-cookie";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore
import Menu from "../menu/Menu";
// @ts-ignore
import Cookies from "universal-cookie";
import SettingsCatalog from "./SettingsCatalog";
import Shop from "./Shop";
import Pay from "./Pay";
import Page from "./Page";
import Delivery from "./Delivery";
import {Toast} from "react-bootstrap";
import axios from "axios";
import SettingsInfoCatalog from "./SettingsInfoCatalog";

export class EditCatalog extends Component <any, any> {
    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: true,
            cookies: cookies.get('bearer'),
            idStore: this.props.idStore,
            idCatalog: this.props.idCatalog,
            activeSettings: true,
            activeShop: false,
            activePay: false,
            activePage: false,
            activeCustomization: false,
            activeDelivery: false,


        };

        this.ActivateMenu = this.ActivateMenu.bind(this);
    }

    componentDidMount() {


    }

    ActivateMenu(param) {
        switch(param) {
            case 'settings':
                return(this.setState({
                    activeSettings: true,
                    activeShop: false,
                    activePay: false,
                    activePage: false,
                    activeCustomization: false,
                    activeDelivery: false,

                }))

            case 'shop':
                return(this.setState({
                    activeSettings: false,
                    activeShop: true,
                    activePay: false,
                    activePage: false,
                    activeCustomization: false,
                    activeDelivery: false,

                }))
            case 'pay':
                return(this.setState({
                    activeSettings: false,
                    activeShop: false,
                    activePay: true,
                    activePage: false,
                    activeCustomization: false,
                    activeDelivery: false,

                }))
            case 'page':
                return(this.setState({
                    activeSettings: false,
                    activeShop: false,
                    activePay: false,
                    activePage: true,
                    activeCustomization: false,
                    activeDelivery: false,

                }))
            case 'customization':
                return(this.setState({
                    activeSettings: false,
                    activeShop: false,
                    activePay: false,
                    activePage: false,
                    activeCustomization: true,
                    activeDelivery: false,

                }))
            case 'delivery':
                return(this.setState({
                    activeSettings: false,
                    activeShop: false,
                    activePay: false,
                    activePage: false,
                    activeCustomization: false,
                    activeDelivery: true,

                }))
        }
    }
    body(){
        const {activeSettings,activePay,activeShop,activePage,activeCustomization,activeDelivery} = this.state;

        if( activeSettings){
            return(<SettingsCatalog idStore={this.state.idStore} idCatalog={this.state.idCatalog}/>);
        }
        else if(activePay){
            return(<Pay idStore={this.state.idStore} idCatalog={this.state.idCatalog} />);
        }
        else if(activeShop){
            return(<Shop idStore={this.state.idStore} idCatalog={this.state.idCatalog} />);
        }
        else if(activePage){
            return(<Page idStore={this.state.idStore} idCatalog={this.state.idCatalog}/>);

        }
        else if(activeCustomization){
            return(<SettingsInfoCatalog idStore={this.state.idStore} idCatalog={this.state.idCatalog}/>);
        }

        else if(activeDelivery){
            return(<Delivery idStore={this.state.idStore} idCatalog={this.state.idCatalog}/>);
        }
    }

    render() {
        const {product, isLoaded, idStore, activeDelivery,activeSettings,activePay,activeShop,activePage,activeCustomization} = this.state;
        if (isLoaded) {
            return (<div style={{margin:'30px'}}>
                <Menu/>

                <div id="right_dark_space"></div>
                <div style={{width: 'calc(100% - 130px)'}} id="right_space">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Главная</a></li>
                            <li className="breadcrumb-item"><a href="/store">Магазины</a></li>
                            <li className="breadcrumb-item"><a href={"/store/"+this.props.idStore+"/catalog/"+this.props.idCatalog}>Каталог</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Настройки</li>
                        </ol>
                    </nav>
                    <div><h1 className="text-center margin_t_b_20">Настройки каталога</h1></div>
                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <a className={'nav-link'+ (activeSettings ? ' active':'')} onClick={() => this.ActivateMenu('settings')} href="#">Настройки</a>
                        </li>

                        <li className="nav-item">
                            <a className={'nav-link'+ (activeShop ? ' active':'')} onClick={() => this.ActivateMenu('shop')} href="#">Точки продаж</a>
                        </li>
                        <li className="nav-item">
                            <a className={'nav-link'+ (activePay ? ' active':'')} onClick={() => this.ActivateMenu('pay')} href="#">Оплата</a>
                        </li>
                        {/*<li className="nav-item">*/}
                        {/*    <a className={'nav-link'+ (activePage ? ' active':'')} onClick={() => this.ActivateMenu('page')} href="#">Дополнительные страницы</a>*/}
                        {/*</li>*/}
                        <li className="nav-item">
                            <a className={'nav-link'+ (activeCustomization ? ' active':'')} onClick={() => this.ActivateMenu('customization')} href="#">Настройки каталога</a>
                        </li>
                    </ul>

                    {this.body()}
                </div>
            </div>)
        } else {
            return ('Загрузка...')
        }


    }
}

// @ts-ignore
export default EditCatalog;
