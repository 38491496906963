// @ts-ignore
import React, {Component} from 'react';
import Menu from "../menu/Menu";
// import Cookies from 'js-cookie';
import Cookies from 'universal-cookie';
import axios from "axios";


export class Finance extends Component <any, any> {

    constructor(props: any) {

        super(props);
        const cookies = new Cookies();
        this.state = {
            info: [],
            logging: [],
            order: [],
            isLoadedLogging: false,
            isLoadedOrder: false,
            isLoadedInfo: false,

            cookies: cookies.get('bearer'),


        };


    }

    componentDidMount() {
        axios('https://api.cliclishop.ru/lk/profile', {

            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.setState(
                {
                    info: result.data,
                    isLoaded: true,
                });
        })


    }
    promocode(){
        return(
            <div className="col-12 col-sm-6 m-0 p-0">
                <div className="shadow-card h-100 ">
                    <h4 className="text-center">Активировать промокод</h4>
                    <div className={'row'}>
                        <div className={'col-6'}>
                            <div className="m-0 form-group">
                                <input type="text" className=" form-control" id="exampleFormControlInput1"
                                       placeholder="Промокод"/>
                            </div>
                        </div>
                        <div className={'col-6'}>
                            <div  className="text-center">
                                <div className="middle_button m-0 text-center">
                                    <a style={{color: "white", textDecoration: "none"}}>Применить промокод</a>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
    balance() {
        return (

            <div className="col-12 col-sm-6 m-0 p-0">
                <div className="shadow-card h-100">
                    <h4 className="text-center">Пополнить баланс</h4>
                    <p>Для пополнения баланса обратитесть в техническую поддержку</p>
                    <div className='row'>

                        <div style={{padding:'15px'}} className='col-6'>
                            <div  className="text-center">
                                <div className="middle_button m-0 text-center">
                                    <a style={{color: "white", textDecoration: "none"}}>Безналичным способом</a>
                                </div>

                            </div>
                        </div>
                        <div style={{padding:'15px'}} className='col-6'>
                            <div  className="text-center">
                                <div className="middle_button m-0 text-center">
                                    <a style={{color: "white", textDecoration: "none"}}>Банковской картой</a>
                                </div>

                            </div>
                        </div>
                        <div style={{padding:'15px'}} className='col-6'>
                            <div  className="text-center">
                                <div className="middle_button m-0 text-center">
                                    <a style={{color: "white", textDecoration: "none"}}>Квитанцией через банк</a>
                                </div>

                            </div>
                        </div>
                        <div style={{padding:'20px'}} className='col-6'></div>

                    </div>
                    {/*<h4 className="text-center">Автоматический платеж</h4>*/}
                    {/*<div className="text-center">*/}
                    {/*    <div className="middle_button text-center">*/}
                    {/*        Привязать карту*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>


            {/*<div className="col-6 m-0 p-0">*/}
            {/*    <div className="shadow-card h-100" style={{marginBottom:'20px'}}>*/}
            {/*        <h4 className="text-center">Дополнительные услуги</h4>*/}
            {/*        <div className="text-center">*/}
            {/*            <table className="table table-hover">*/}
            {/*                <thead>*/}
            {/*                <tr>*/}
            {/*                    <th scope="col">Наименование</th>*/}
            {/*                    <th scope="col">Стоимость в день</th>*/}
            {/*                    <th scope="col"></th>*/}
            {/*                    <th scope="col"></th>*/}
            {/*                </tr>*/}
            {/*                </thead>*/}
            {/*                <h5>Нет подключенных услуг</h5>*/}
            {/*                <tbody>*/}
            {/*                <tr>*/}
            {/*                    <td>Сайт</td>*/}
            {/*                    <td>11</td>*/}
            {/*                    <td style={{color: '#1c94c4', cursor: 'pointer'}}>Перейти</td>*/}
            {/*                    <td style={{color:'red', cursor: 'pointer'}}>Отключить</td>*/}
            {/*                </tr>*/}
            {/*                <tr>*/}
            {/*                    <td>Чат</td>*/}
            {/*                    <td>5</td>*/}
            {/*                    <td style={{color: '#1c94c4', cursor: 'pointer'}}>Перейти</td>*/}
            {/*                    <td style={{color:'red', cursor: 'pointer'}}>Отключить</td>*/}
            {/*                </tr>*/}
            {/*                </tbody>*/}
            {/*            </table>*/}
            {/*            <div className="middle_button text-center">*/}
            {/*                Выбрать услугу*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>)
    }


    render() {
        const {info, logging, isLoaded, order, categories, Breadcrumbs1ID, Breadcrumbs2ID, sort} = this.state;
        console.log(info)
        if (isLoaded) {
            return (<div>
                    <Menu/>

                    <div id="right_dark_space"></div>
                    <div style={window.screen.width>650?{width: 'calc(100% - 100px)'}:{width: '100%'}} id="right_space">
                        <div className="text-center">
                            <h1 className="text-center" style={{margin: '40px', display: 'inline-block'}}>Баланс и
                                оплата</h1>
    <div className="row margin_padding_0" style={{boxSizing: 'border-box', marginBottom: '20px'}}></div>
                            {this.promocode()}
                            {this.balance()}
                        </div>
                    </div>
                </div>
            )

        } else {
            return (<div>
                <Menu/>
                <div id="right_dark_space"></div>
                <div style={window.screen.width>650?{width: 'calc(100% - 100px)'}:{width: '100%'}} id="right_space">

                    <div className="preloader d-flex justify-content-center">
                        <div className="align-middle spinner-grow text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>

            </div>)
        }


    }
}

// @ts-ignore
export default Finance;