// @ts-ignore
import React, {Component} from "react";
import Menu from "../menu/Menu";
import Cookies from "universal-cookie";
import axios from "axios";
import Notification from "../Notification";

export class ShowItemSupport extends Component <any, any> {
    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: false,
            support: [],
            notification: [],
            name: '',
            text: '',


        };
        this.changeName = this.changeName.bind(this);
        this.changeContent = this.changeContent.bind(this);


    }

    changeName(event) {
        this.setState({name: event.target.value});
    }

    changeContent(event) {
        this.setState({text: event.target.value});
    }

    updateData = (value) => {
        var filteredList = this.state.notification.filter(function (item) {
            return item.text.toLowerCase().search(value.toLowerCase()) == -1;
        });
        this.setState({notification: filteredList});
    }

    notification(text) {
        const data = this.state.notification
        const element = {text: text, time: 25};
        const newArray = [...data, element];
        this.setState(
            {
                notification: newArray,

            });
    }

    componentDidMount() {
        const data = {
            title: this.state.name,
            text: this.state.text,
        };
        axios({

            url: 'https://api.cliclishop.ru/lk/support/show/' + this.props.idSupport,
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.setState(
                {
                    support: result.data,
                    isLoaded: true,
                });

        })


    }

    addSupport() {

        const data = {
            title: this.state.name,
            text: this.state.text,
        };
        axios({

            url: 'https://api.cliclishop.ru/lk/support/add/' + this.props.idSupport,
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            // if(result.data.error == 1){
            //     this.notification('Превышен лимит на количество магазинов.')
            // }
            // else {
            //     window.location.assign('/store/'+result.data.idStore+'/catalog');
            // }

        })
    }

    render() {
        const {support, isLoaded} = this.state;
        if (isLoaded) {
            return (<div>
                <Menu/>
                <div id="right_dark_space"></div>
                <div style={window.screen.width > 650 ? {width: 'calc(100% - 100px)'} : {width: '100%'}}
                     id="right_space">
                    <Notification updateData={this.updateData} NotificationArray={this.state.notification}/>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Главная</a></li>
                            <li className="breadcrumb-item"><a href="/support">Техническая поддержка</a></li>
                            <li className="breadcrumb-item active" aria-current="page">{support[0].title}</li>
                        </ol>
                    </nav>
                    <h1 className="text-center" style={{margin: '40px auto 20px auto'}}>{support[0].title}</h1>

                    {/*{support.map(items => (<div className={ items.author != 0 ? 'container-support time-right' : 'container-support time-left'}>*/}
                    {/*    <img style={{width:'100%'}} className={items.author != 0 ? "right" : ""} src="/w3images/bandmember.jpg" alt="Avatar"/>*/}
                    {/*    {items.text}*/}
                    {/*    <span className={items.author != 0 ? 'time-right' : 'time-left'}>{items.datetime}</span>*/}
                    {/*</div>))}*/}
                    {support.map(items => (
                        <div className="shadow-card col-sm-9 row" style={{margin: '0 auto', marginBottom: '15px'}}>
                            <div className="col-sm-3 row">
                                {items.author != 0 ? 'Вы' : 'Администратор'}
                                <br/>

                            </div>
                            <div className="col-sm-9 row">
                                {items.text}
                            </div>
                        </div>))}


                    <div className="shadow-card col-sm-9" style={{margin: '0 auto'}}>

                        <form><br/>


                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Опишите подробно, с чем у Вас возникли
                                    проблемы</label>
                                <textarea onChange={this.changeContent} className="form-control"
                                          id="exampleFormControlTextarea1" rows={6}
                                          value={this.state.content}></textarea>
                            </div>


                        </form>

                        <div onClick={() => this.addSupport()} style={{marginBottom: '30px'}} className="text-center">
                            <div className="middle_button text-center">
                                <a style={{color: "white", textDecoration: "none"}}>Отправить</a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>)
        } else {
            return (<div>
                <Menu/>
                <div id="right_dark_space"></div>
                <div style={window.screen.width > 650 ? {width: 'calc(100% - 100px)'} : {width: '100%'}}
                     id="right_space">

                    <div className="preloader d-flex justify-content-center">
                        <div className="align-middle spinner-grow text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>

            </div>)
        }


    }
}

// @ts-ignore
export default ShowItemSupport;