// @ts-ignore
import React, {Component} from "react";
// import Menu from "../menu/Menu";
// import Cookies from "universal-cookie";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore
import Menu from "../menu/Menu";
// @ts-ignore
import Cookies from "universal-cookie";

import {Toast} from "react-bootstrap";
import axios from "axios";

export class Profile extends Component <any, any> {
    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: true,
            productId: '',
            orders: [],
            category: [],
            categoryId: null,
            commentSeller: ' ',
            cookies: cookies.get('bearer'),
            idStore: this.props.idStore,
            idOrder: this.props.idOrder,
            idCatalog: this.props.idCatalog,


        };

        // this.ActivateMenu = this.ActivateMenu.bind(this);
        this.changeCategory = this.changeCategory.bind(this);
        this.changeContent = this.changeContent.bind(this);

    }

    changeContent(event) {
        this.setState({commentSeller: event.target.value});
    }

    changeCategory(event) {
        this.setState({categoryId: event.target.value});
    }



    componentDidMount() {
        const data = {id_order: this.state.idOrder};
        axios({

            url: 'https://api.cliclishop.ru/lk/orders/item',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            console.log(result.data.content.statusOrder.id)
            this.setState(
                {
                    orders: result.data,
                    commentSeller: result.data.content.commentSeller,
                    categoryId: result.data.content.statusOrder.id,
                    isLoaded: true,
                });
        })
            .catch(error => {
                console.log(error)
            })


    }



    render() {
        const {isLoaded, category, idCatalog, orders, activeCategory, activePhoto, activeSpecifications, activeCustomization} = this.state;

        if (isLoaded) {
            console.log(orders.products)
            return (<div style={{margin: '30px'}}>
                <Menu/>

                <div id="right_dark_space"></div>

                <div style={{width: 'calc(100% - 130px)'}} id="right_space">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Главная</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Настройки профиля</li>
                        </ol>
                    </nav>
                    <h1 className="text-center margin_40">Настройки профиля</h1>
                    <div className="exit_button">
                        <a href="/logout">
                            <div className="middle_button text-center">
                                Выход
                            </div>
                        </a>
                    </div>

                    <div className="row margin_padding_0">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-6">
                                    <div className="shadow-card" style={{padding:'40px'}}>
                                        <h3 className="text-center">Общая информация</h3>
                                        <form>
                                            <div className=" mb-3">
                                                <label htmlFor="firstName">ФИО:</label>
                                                <input type="text" className="form-control" id="firstName"
                                                       placeholder=""
                                                       value=""
                                                      />
                                                    <div className="invalid-feedback">
                                                        Valid first name is required.
                                                    </div>
                                            </div>
                                            <div className=" mb-3">
                                                <label htmlFor="firstName">Телефон:</label>
                                                <input type="text" className="form-control" id="firstName"
                                                       placeholder=""
                                                       value=""
                                                       />
                                                    <div className="invalid-feedback">
                                                        Valid first name is required.
                                                    </div>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="address">Email</label>
                                                <input type="email" className="form-control" id="email" placeholder=""
                                                       value=""/>
                                                    <div className="invalid-feedback">
                                                        Please enter a valid email address for shipping updates.
                                                    </div>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="address">Адрес сайта</label>
                                                <input type="text" className="form-control" id="address" placeholder=""
                                                       value=""/>
                                                    <div className="invalid-feedback">
                                                        Please enter a valid email address for shipping updates.
                                                    </div>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="address">Тип(физ/юр/ип)</label>
                                                <input type="text" className="form-control" id="address" placeholder=""
                                                       value=""/>
                                                    <div className="invalid-feedback">
                                                        Please enter a valid email address for shipping updates.
                                                    </div>
                                            </div>

                                            <button className="btn btn-lg btn-primary btn-block">
                                                <a href="shopList.html"
                                                   style={{color:"white", textDecoration:'none'}}>Сохранить</a>
                                            </button>
                                        </form>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="shadow-card" style={{padding:'40px'}}>
                                        <h3 className="text-center">Физ. лицо</h3>
                                        <div className="mb-3">
                                            <label htmlFor="address">Серия паспорта</label>
                                            <input type="text" className="form-control" id="address" placeholder=""
                                                   value=""/>
                                                <div className="invalid-feedback">
                                                    Please enter a valid email address for shipping updates.
                                                </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="address">Номер паспорта</label>
                                            <input type="text" className="form-control" id="address" placeholder=""
                                                   value=""/>
                                                <div className="invalid-feedback">
                                                    Please enter a valid email address for shipping updates.
                                                </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="address">Дата рождения</label>
                                            <input type="date" className="form-control" id="address" placeholder=""
                                                   value=""/>
                                                <div className="invalid-feedback">
                                                    Please enter a valid email address for shipping updates.
                                                </div>
                                        </div>
                                        <button type="submit" className="btn btn-primary">Изменить</button>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="shadow-card" style={{padding:'40px'}}>
                                        <h3 className="text-center">Юр. лицо</h3>
                                        <div className="mb-3">
                                            <label htmlFor="address">Название организации</label>
                                            <input type="text" className="form-control" id="address" placeholder=""
                                                   value=""/>
                                                <div className="invalid-feedback">
                                                    Please enter a valid email address for shipping updates.
                                                </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="email">Юридический адрес</label>
                                            <input type="text" className="form-control" id="address" placeholder=""
                                                   value=""/>
                                                <div className="invalid-feedback">
                                                    Please enter a valid email address for shipping updates.
                                                </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="email">ИНН</label>
                                            <input type="text" className="form-control" id="address" placeholder=""
                                                   value=""/>
                                                <div className="invalid-feedback">
                                                    Please enter a valid email address for shipping updates.
                                                </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="email">КПП</label>
                                            <input type="text" className="form-control" id="address" placeholder=""
                                                   value=""/>
                                                <div className="invalid-feedback">
                                                    Please enter a valid email address for shipping updates.
                                                </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="email">Почтовый адрес</label>
                                            <input type="text" className="form-control" id="address" placeholder=""
                                                   value=""/>
                                                <div className="invalid-feedback">
                                                    Please enter a valid email address for shipping updates.
                                                </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="email">Телефон организации</label>
                                            <input type="text" className="form-control" id="address" placeholder=""
                                                   value=""/>
                                                <div className="invalid-feedback">
                                                    Please enter a valid email address for shipping updates.
                                                </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="email">Телефон бухгалтерии</label>
                                            <input type="text" className="form-control" id="address" placeholder=""
                                                   value=""/>
                                                <div className="invalid-feedback">
                                                    Please enter a valid email address for shipping updates.
                                                </div>
                                        </div>
                                        <button type="submit" className="btn btn-primary">Изменить</button>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="shadow-card" style={{padding:'40px'}}>
                                        <h3 className="text-center">ИП</h3>
                                        <div className="mb-3">
                                            <label htmlFor="address">Название организации</label>
                                            <input type="text" className="form-control" id="address" placeholder=""
                                                   value=""/>
                                                <div className="invalid-feedback">
                                                    Please enter a valid email address for shipping updates.
                                                </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="email">Полное название организации</label>
                                            <input type="text" className="form-control" id="address" placeholder=""
                                                   value=""/>
                                                <div className="invalid-feedback">
                                                    Please enter a valid email address for shipping updates.
                                                </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="email">Юридический адрес</label>
                                            <input type="text" className="form-control" id="address" placeholder=""
                                                   value=""/>
                                                <div className="invalid-feedback">
                                                    Please enter a valid email address for shipping updates.
                                                </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="email">Фактический адрес</label>
                                            <input type="text" className="form-control" id="address" placeholder=""
                                                   value=""/>
                                                <div className="invalid-feedback">
                                                    Please enter a valid email address for shipping updates.
                                                </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="email">ИНН</label>
                                            <input type="text" className="form-control" id="address" placeholder=""
                                                   value=""/>
                                                <div className="invalid-feedback">
                                                    Please enter a valid email address for shipping updates.
                                                </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="email">ОГРНИП</label>
                                            <input type="text" className="form-control" id="address" placeholder=""
                                                   value=""/>
                                                <div className="invalid-feedback">
                                                    Please enter a valid email address for shipping updates.
                                                </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="email">ОКПО</label>
                                            <input type="text" className="form-control" id="address" placeholder=""
                                                   value=""/>
                                                <div className="invalid-feedback">
                                                    Please enter a valid email address for shipping updates.
                                                </div>
                                        </div>
                                        <button type="submit" className="btn btn-primary">Изменить</button>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="shadow-card" style={{padding:'40px'}}>
                                        <h3 className="text-center">Смена пароля</h3>
                                        <div className="form-group">
                                            <label htmlFor="exampleInputPassword1">Старый пароль</label>
                                            <input type="password" className="form-control" id="exampleInputPassword1"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="exampleInputPassword1">Новый пароль</label>
                                            <input type="password" className="form-control" id="exampleInputPassword1"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="exampleInputPassword1">Повторите новый пароль</label>
                                            <input type="password" className="form-control" id="exampleInputPassword1"/>
                                        </div>
                                        <button type="submit" className="btn btn-primary">Изменить</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            </div>)
        } else {
            return ('Загрузка...')
        }


    }
}

// @ts-ignore
export default Profile;