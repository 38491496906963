// @ts-ignore
import React, {Component} from "react";
// import Menu from "../menu/Menu";
// import Cookies from "universal-cookie";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore
import Menu from "../menu/Menu";
// @ts-ignore
import Cookies from "universal-cookie";

import {Toast} from "react-bootstrap";
import axios from "axios";

export class OrderItem extends Component <any, any> {
    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: false,
            productId: '',
            orders: [],
            category: [],
            categoryId: null,
            commentSeller: " ",
            cookies: cookies.get('bearer'),
            idStore: this.props.idStore,
            idOrder: this.props.idOrder,
            idCatalog: this.props.idCatalog,



        };

        // this.ActivateMenu = this.ActivateMenu.bind(this);
        this.changeCategory = this.changeCategory.bind(this);
        this.changeContent = this.changeContent.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
    }

    changeContent(event) {
        console.log(event.target.value)
        this.setState({commentSeller: event.target.value});
    }

    changeCategory(event) {
        this.setState({categoryId: event.target.value});
    }

    onClickSave() {
        const data = {
            id_order: this.state.idOrder,
            idStatusOrder: this.state.categoryId,
            commentSeller: this.state.commentSeller
        }
        console.log(data)
        axios({

            url: 'https://api.cliclishop.ru/lk/orders/item/edit',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status

            this.setState(
                {});
        })
            .catch(error => {
                console.log(error)
            })
    }

    componentDidMount() {
        const data = {id_order: this.state.idOrder};
        axios({

            url: 'https://api.cliclishop.ru/lk/orders/item',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            console.log(result.data.content.statusOrder.id)
            this.setState(
                {
                    orders: result.data,
                    commentSeller: result.data.content.commentSeller,
                    categoryId: result.data.content.statusOrder.id,
                    isLoaded: true,
                });
        })
            .catch(error => {
                console.log(error)
            })
        this.category()

    }

    category() {

        axios({

            url: 'https://api.cliclishop.ru/lk/orders/category',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",


            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.setState(
                {
                    category: result.data,

                });
        })
            .catch(error => {
                console.log(error)
            })
    }

    infoDelivery() {
        const {orders} = this.state;
        if (orders.content.shop) {
            return (
                <div className="mb-5">
                    <h4 className={'text-muted mb-3'}>Пункт самовывоза</h4>
                    <h5>Адрес самовывоза: {orders.content.shop}</h5>
                </div>)
        } else {
            return (
                <div className="mb-5">
                    <h4 className={'text-muted mb-3'}>Адрес доставки</h4>
                    <h5>Доставка по адресу: {orders.content.address}</h5>
                    <h5>Индекс: {orders.content.info.zip}</h5>
                </div>)

        }
    }

    infoPay() {
        const {orders} = this.state;
        if (orders.content.info.pay === '2') {
            return (<div>
                <h5>Оплата при получении</h5></div>)
        } else {
            return (<div>
                <h5>Оплата будет производится онлаин</h5></div>)

        }
    }

    render() {
        const {isLoaded, category, idCatalog, orders, activeCategory, activePhoto, activeSpecifications, activeCustomization} = this.state;

        if (isLoaded) {
            console.log(orders.products)
            return (<div >
                <Menu/>

                <div id="right_dark_space"></div>

                <div style={{width: 'calc(100% - 130px)'}} id="right_space">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Главная</a></li>
                            <li className="breadcrumb-item"><a href="/orders">Заказы</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Заказ #{this.state.idOrder}</li>
                        </ol>
                    </nav>
                    <div className="text-center"><h1 className="top_header">Заказ #{this.state.idOrder}</h1></div>
                    <div className={"row"}>
                        <div className={'col col-md-6'}>
                            <div className="mb-5">
                                <h4 className={'text-muted mb-3'}>Информация о заказе</h4>
                                <h5>Фамилия: {this.state.orders.content.surname ? " " + this.state.orders.content.surname : " Не указана"}</h5>
                                <h5>Имя: {this.state.orders.content.name}</h5>
                                <h5>Email: {this.state.orders.content.email}</h5>
                            </div>

                            <div>
                                {this.infoDelivery()}
                            </div>
                            <div className="mb-5">
                                <h4 className={'text-muted mb-3'}>Информация об оплате</h4>
                                {this.infoPay()}
                            </div>

                            <div className="mb-5">
                                <h4 className={'text-muted mb-3'}>Комментарий покупателя</h4>
                                <b>{this.state.orders.content.comment ? " " + this.state.orders.content.comment : " Не указан"}</b>
                            </div>


                            <div className="shadow-card m-0 mb-5">
                                <form>
                                    <div className="mb-3">
                                        <h4 className="text-muted mb-3 text-center">Статус заказа</h4>
                                        <div className="form-group col-12 p-0 m-0">
                                            <label className="sr-only" htmlFor="exampleFormControlSelect1">Изменить
                                                статус
                                                заказа</label>
                                            <select onChange={this.changeCategory} value={this.state.categoryId}
                                                    className="form-control" id="exampleFormControlSelect1">
                                                {category.map(items => (
                                                    <option value={items.id}>{items.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <h4 className="text-muted text-center mb-3">Заметка</h4>
                                        <div className="form-group p-0 m-0">
                                            {/*<label className="sr-only" htmlFor="exampleFormControlTextarea1">Комментарий к заказу:</label><br/>*/}

                                            <textarea onChange={this.changeContent} value={this.state.commentSeller}
                                                      className="form-control" id="exampleFormControlTextarea1"
                                                      rows={3}></textarea>
                                            <small>*Заметка не видна покупателю</small>
                                        </div>
                                    </div>
                                </form>
                                {/*<button onClick={() => this.onClickSave()} type="button"*/}
                                {/*        className="btn btn-primary">Сохранить изменения*/}
                                {/*</button>*/}
                                <div className="text-center">
                                    <a onClick={() => this.onClickSave()}>
                                        <div className="middle_button text-center">
                                            Сохранить изменения
                                        </div>
                                    </a>
                                </div>
                            </div>


                        </div>
                        <div className={'col col-md-6'}>
                            <div className="mb-3">
                                <h4 className={'text-muted mb-3'}>Товары</h4>
                                <h5>Стоимость без доставки: {orders.fullprice} рублей</h5>
                            </div>

                            <div>
                                {orders.product.map(items => (
                                    <div className="row shadow-card">

                                        <div className={"col-2"}>
                                            <img style={{width: '100%'}} src={items.product.image?items.product.image.link:'/img/no_image.png'}/>
                                        </div>
                                        <div className={"col-10"}>
                                            Наименование: {items.product.name}<br/>
                                            Количество: {items.quantity}<br/>
                                            Стоимость за ед (на момент заказа): {items.price} руб<br/>
                                            Общая стоимость: {items.quantity * items.price} руб<br/>
                                            Артикул: {items.product.vendorCode}
                                            <br/>Код товара: {items.product.code}
                                        </div>
                                        <div className={"col-3"}></div>
                                        <div className={"col-2"}></div>

                                    </div>
                                ))}

                            </div>
                        </div>

                    </div>


                </div>
            </div>)
        } else {
            return (<div>
                <Menu/>
                <div id="right_dark_space"></div>
                <div style={{width: 'calc(100% - 100px)'}} id="right_space">

                    <div className="preloader d-flex justify-content-center">
                        <div className="align-middle spinner-grow text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>

            </div>)
        }


    }
}

// @ts-ignore
export default OrderItem;