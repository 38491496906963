// @ts-ignore
import React, {Component} from "react";
// import Menu from "../menu/Menu";
// import Cookies from "universal-cookie";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore
import Menu from "../menu/Menu";
// @ts-ignore
import Cookies from "universal-cookie";
import axios from "axios";

export class Specifications extends Component <any, any> {

    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: true,
            idProduct: this.props.idProduct,
            product: [],
            specifications: [],
            cookies: cookies.get('bearer'),
            idStore: this.props.idStore,
            idCatalog: this.props.idCatalog,
            addSpecificationsName: '',
            addSpecificationsValue: '',
            updateSpecificationsName: '',
            updateSpecificationsValue: '',
            updateSpecificationsId: '',


        };
        this.handleChangeAddSpecificationsName = this.handleChangeAddSpecificationsName.bind(this);
        this.handleChangeAddSpecificationsValue = this.handleChangeAddSpecificationsValue.bind(this);
        this.handleChangeUpdateSpecificationsValue = this.handleChangeUpdateSpecificationsValue.bind(this);
        this.handleChangeUpdateSpecificationsValue = this. handleChangeUpdateSpecificationsValue.bind(this);
        this.handleChangeUpdateSpecificationsName = this.handleChangeUpdateSpecificationsName.bind(this);
        this.updateSpecifications = this.updateSpecifications.bind(this);
        this.updateSpecificationsApi = this.updateSpecificationsApi.bind(this);

    }

    handleChangeAddSpecificationsName(event) {
        this.setState({addSpecificationsName: event.target.value});
    }

    handleChangeAddSpecificationsValue(event) {
        this.setState({addSpecificationsValue: event.target.value});
    }

    handleChangeUpdateSpecificationsName(event) {
        this.setState({updateSpecificationsName: event.target.value});
    }

    handleChangeUpdateSpecificationsValue(event) {
        this.setState({updateSpecificationsValue: event.target.value});
    }

    componentDidMount() {
        const data = {
            store_id: this.state.idStore,
            catalog_id: this.state.idCatalog,
            product_id: this.props.idProduct
        };
        axios({

            url: 'https://api.cliclishop.ru/lk/product/specifications/show',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.setState(
                {
                    specifications: result.data,
                    isLoaded: true,
                });
        })


    }

    addSpecifications() {
        const data = {
            store_id: this.state.idStore,
            catalog_id: this.state.idCatalog,
            product_id: this.props.idProduct,
            name_specifications: this.state.addSpecificationsName,
            content_specifications: this.state.addSpecificationsValue,
        };
        axios({

            url: 'https://api.cliclishop.ru/lk/product/specification/add',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.componentDidMount()
            this.setState(
                {
                    addSpecificationsName: '',
                    addSpecificationsValue: '',
                });
        })



    }

    deleteSpecifications(id) {
        const data = {
            store_id: this.state.idStore,
            catalog_id: this.state.idCatalog,
            product_id: this.props.idProduct,
            id_specifications: id,
        };
        axios({

            url: 'https://api.cliclishop.ru/lk/specifications/delete',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.componentDidMount()
            this.setState(
                {
                    updateSpecificationsName: '',
                    updateSpecificationsValue: '',
                    updateSpecificationsId: '',
                });
        })

    }

    updateSpecifications(items) {
        console.log(items)

        this.setState({
            updateSpecificationsName: items.name,
            updateSpecificationsValue: items.content,
            updateSpecificationsId: items.id,

        });
    }

    updateSpecificationsApi() {
        const dataOrder = {
            store_id: this.state.idStore,
            catalog_id: this.state.idCatalog,
            product_id: this.props.idProduct,
            content: this.state.updateSpecificationsValue,
            name: this.state.updateSpecificationsName,
            id_specifications: this.state.updateSpecificationsId,
        };
        axios({

            url: 'https://api.cliclishop.ru/lk/specification/edit',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: dataOrder,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.componentDidMount()
            this.setState(
                {
                    updateSpecificationsName: '',
                    updateSpecificationsValue: '',
                    updateSpecificationsId: '',
                });
        })


    }


    render() {
        const {isLoaded, specifications} = this.state;
        if (isLoaded) {
            return (<div>
                <h4 className="text-center" style={{marginBottom: '20px', marginTop: '30px'}}>Характеристики</h4>

                <div className="overflow-auto"
                >
                    <table className="table table-striped">
                        <tbody>
                        {specifications.map(items => (
                            <tr>
                                <td>{items.name}</td>
                                <td>{items.content}   </td>
                                <td><a className="delete_specification"
                                       onClick={() => this.updateSpecifications(items)}>Изменить</a>
                                </td>
                                <td><a className="delete_specification"
                                       onClick={() => this.deleteSpecifications(items.id)}>Удалить</a>
                                </td>
                            </tr>

                        ))

                        }

                        </tbody>
                    </table>
                </div>
                <hr/>
                <h4 className="text-center" style={{marginBottom: '20px', marginTop: '30px'}}>Добавить
                    характеристику</h4>
                <form>
                    <div className={"row"}>
                        <div className="col-md-6 form-group">
                            <label htmlFor="exampleInputEmail1">Наименование</label>
                            <input value={this.state.addSpecificationsName}
                                   onChange={this.handleChangeAddSpecificationsName} type="text"
                                   className="form-control"
                                   id="exampleInputEmail1"
                                   aria-describedby="emailHelp"/>
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="exampleInputEmail1">Значение</label>
                            <input value={this.state.addSpecificationsValue}
                                   onChange={this.handleChangeAddSpecificationsValue} type="text"
                                   className="form-control"
                                   id="exampleInputEmail1"
                                   aria-describedby="emailHelp"/>
                        </div>
                    </div>


                    <div onClick={() => this.addSpecifications()} style={{marginBottom: '30px'}}
                         className="text-center">
                        <div className="middle_button text-center">
                            <a style={{color: "white", textDecoration: "none"}}>Отправить</a>
                        </div>

                    </div>
                </form>
                <hr/>
                <h4 className="text-center" style={{marginBottom: '20px', marginTop: '30px'}}>Изменить
                    характеристику</h4>
                <form>
                    <div className={"row"}>
                        <div className="form-group col-6">
                            <label htmlFor="exampleInputEmail1">Наименование</label>
                            <input value={this.state.updateSpecificationsName}
                                   onChange={this.handleChangeUpdateSpecificationsName} type="text"
                                   className="form-control"
                                   id="exampleInputEmail1"
                                   aria-describedby="emailHelp"/>
                        </div>
                        <div className="form-group col-6">
                            <label htmlFor="exampleInputEmail1">Значение</label>
                            <input value={this.state.updateSpecificationsValue}
                                   onChange={this.handleChangeUpdateSpecificationsValue} type="text"
                                   className="form-control"
                                   id="exampleInputEmail1"
                                   aria-describedby="emailHelp"/>
                        </div>

                    </div>

                    <div onClick={() => this.updateSpecificationsApi()} style={{marginBottom: '30px'}}
                         className="text-center">
                        <div className="middle_button text-center">
                            <a style={{color: "white", textDecoration: "none"}}>Отправить</a>
                        </div>

                    </div>
                </form>

            </div>)
        } else {
            return ('Загрузка...')
        }


    }
}

// @ts-ignore
export default Specifications;