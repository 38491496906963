// @ts-ignore
import React, {Component} from "react";
import Menu from "../../menu/Menu";
import Cookies from "universal-cookie";
import axios from "axios";
import Global from "../../GlobalVariable";


export class Pages extends Component <any, any> {
    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: false,
            pages: [],
            size:window.screen.width,
            activeSite:JSON.parse(localStorage.getItem('activeSite')as string)

        };




    }
    componentDidMount() {

        const data = {idActiveSite: this.props.idSite};
        axios({

            url: Global.host()+'/admin/pages',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.setState(
                {
                    pages: result.data,
                    isLoaded: true,
                });
        })


    }
    pagesShow(){
        const {pages,size} = this.state;
        if(pages.length>0){
            return (<div className="row" style={{margin: "20px 0", padding: "0"}}>
                <div className="col-12">
                    <div className="shadow-card"  style={size>650?{paddingRight:'40px', paddingLeft:'40px'}:{}}>

                        <table className="table table-hover">
                            <thead style={{borderTop:'none'}}>
                            <tr>
                                <th scope="col" style={{borderTop:'none'}}>Название</th>
                                <th scope="col" style={{borderTop:'none'}}></th>
                                <th scope="col" style={{borderTop:'none'}}></th>
                            </tr>
                            </thead>
                            <tbody>
                            {pages.map(items => (<tr>

                                <td>{items.title}</td>
                                <td>


                                    <a href={"/page/change/"+items.id} style={{color:'red'}}>Изменить </a>
                                </td>
                            </tr>))}



                            </tbody>
                        </table>

                    </div>
                </div>
            </div>)
        }
        else {
            return (
                <h3 className="text-center">Вы еще не создали страницы</h3>
            )
        }
    }



    render() {
        const {pages, isLoaded,size} = this.state;
        if(isLoaded){
            return(<div>
                <Menu/>
                <div id="right_dark_space"></div>
                <div style={size>650?{width: 'calc(100% - 100px)'}:{width: '100%'}} id="right_space">

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Главная</a></li>
                            <li className="breadcrumb-item"><a href={"/site/info/"+this.props.idSite}>{this.state.activeSite.activeSiteAlias}</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Страницы</li>
                        </ol>
                    </nav>
                    <h1 className="text-center" style={{margin:'margin: 40px auto 20px auto'}}>Страницы</h1>
                    <div className="text-center">
                        <div className="middle_button text-center">
                            <a href={"/site/pages/"+this.props.idSite+"/add"} style={{color: "white", textDecoration: "none"}}>Добавить новую страницу</a>
                        </div>
                    </div>
                    {this.pagesShow()}
                </div>
            </div>)
        }
        else {
            return (<div>
                <Menu/>
                <div id="right_dark_space"></div>
                <div style={{width: 'calc(100% - 100px)'}} id="right_space">

                    <div className="preloader d-flex justify-content-center">
                        <div className="align-middle spinner-grow text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>

            </div>)
        }



    }
}

// @ts-ignore
export default Pages;