import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    Redirect
} from "react-router-dom";
import Home from './components/home/Home';
import Store from "./components/store/Store";
import DetailStore from "./components/store/DetailStore";
import CatalogsShow from "./components/catalogs/CatalogsShow";
import Product from "./components/product/Product";
import * as serviceWorker from './serviceWorker';
import Cookies from 'universal-cookie';
import 'bootstrap/dist/css/bootstrap.css';
import Orders from "./components/order/Orders";
import OrderItem from "./components/order/OrderItem";
import OrdersSettings from "./components/order/Settings";
import Finance from "./components/finance/Finance";
import Services from "./components/service/Services";
import Profile from "./components/profile/Profile";
import Supports from "./components/support/Supports";
import AddSupport from "./components/support/AddSupport";
import ShowItemSupport from "./components/support/ShowItemSupport";
import EditStore from "./components/store/EditStore";
import EditCatalog from "./components/catalogs/EditCatalog";
import AddStore from "./components/store/AddStore";
import AddCatalog from "./components/catalogs/AddCatalog";
import axios from "axios";
import Login from "./components/login/Login";
import ResetPassword from "./components/login/ResetPassword";
import ChangePassword from "./components/login/ChangePassword";
import ProductAllChange from "./components/catalogs/ProductAllChange";
import HomeSite from "./components/site/HomeSite";
import Pages from "./components/site/pages/Pages";
import AddPage from "./components/site/pages/AddPage";
import Sites from "./components/site/Sites";
import ChangePage from "./components/site/pages/ChangePage";
import Stat from "./components/site/stat/Stat";
import Settings from "./components/site/settings/Settings";
import AddSite from "./components/site/AddSite";
import ProductMassCreate from "./components/catalogs/ProductMassCreate";


const cookies = new Cookies();


if (window.location.pathname != '/login' && window.location.search != "?resetNo") {
    console.log(cookies.get('jwt_hp'))
    if (!cookies.get('jwt_hp')) {
        if (cookies.get('refresh_token')) {
            let errors = false;
            const data = {refresh_token: cookies.get('refresh_token')};
            axios({

                url: 'https://api.cliclishop.ru/api/token/refresh',
                headers: {'Content-Type': 'application/json'},
                withCredentials: true,
                method: "POST",
                data: data,
                // receive two    parameter endpoint url ,form data
            })
                .then(result => { // then print response status
                    window.location.reload()

                }).catch(error => {
                // window.location.href = '/login'
                console.log(error)
            })
        } else {
            console.log('99999')
            // window.location.href = '/login'
        }

    } else {
        console.log('777')
        // window.location.href = '/login'
    }

} else {

}


ReactDOM.render(
    <Router>

        <Switch>


            <Route path="/store/:idStore/catalog/:idCatalog/product/add" children={<ProductAddItemFunc/>}/>
            <Route path="/store/:idStore/catalog/:idCatalog/product/:idProduct/edit" children={<ProductEditItemFunc/>}/>
            <Route path="/store/:idStore/catalog/:idCatalog/edit" children={<CatalogEditFunc/>}/>
            <Route path="/store/:idStore/catalog/:idCatalog/fullchange" children={<ProductAllChangeFunc/>}/>
            <Route path="/store/:idStore/catalog/:idCatalog/mass-crate" children={<ProductMassCreateFunc/>}/>
            <Route path="/store/:idStore/catalog/:idCatalog" children={<CatalogItemFunc/>}/>
            <Route path="/store/:idStore/catalog_add" children={<CatalogAddFunc/>}/>
            <Route path="/store/:idStore/catalog" children={<StoreItemFunc/>}/>
            <Route path="/store/:idStore/edit" children={<StoreEditFunc/>}/>
            <Route path="/store/add" children={<StoreAddFunc/>}/>
            <Route path="/site/add" children={<SiteAddFunc/>}/>
            <Route path="/store" children={<Store/>}/>
            <Route path="/order/:idOrder" children={<OrderItemFunc/>}/>
            <Route path="/orders/settings" children={<OrdersSettings/>}/>
            <Route path="/orders" children={<Orders/>}/>
            <Route path="/support/show/:idSupport" children={<ShowItemSupportFunc/>}/>
            <Route path="/support/add" children={<AddSupport/>}/>


            <Route path="/support" children={<Supports/>}/>


            <Route path="/site/pages/:idSite/add" children={<AddPageFunc/>}/>
            <Route path="/site/pagesAll/:idSite" children={<PagesFunc/>}/>
            <Route path="/site/page/:idPage" children={<ChangePageFunc/>}/>
            <Route path="/site/info/:idSite" children={<HomeSiteFunc/>}/>
            <Route path="/site/settings/:idSite" children={<SettingsSiteFunc/>}/>
            <Route path="/site/stat/:idSite" children={<StatFunc/>}/>
            <Route path="/site/" children={<Sites/>}/>
            <Route path="/finance" children={<Finance/>}/>
            <Route path="/services" children={<Services/>}/>
            <Route path="/chat" children={<Orders/>}/>
            <Route path="/profile" children={<Profile/>}/>
            <Route path="/login" children={<Login/>}/>
            <Route path="/reset/password/email/:email/:hash" children={<ChangePasswordItemFunc/>}/>
            <Route path="/reset/password" children={<ResetPassword/>}/>
            <Route path="/store/:idStore/catalog/add" children={<CatalogAddFunc/>}/>
            <Route path="/" children={<HomeFunc/>}/>


            {/*<Route path="/" children={<Shop/>}/>*/}

        </Switch>
    </Router>,
    document.getElementById('root')
);

function HomeFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let {IdShop, IdProduct} = useParams();

    return <Home/>;
}

function PagesFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let {idSite} = useParams();

    return <Pages idSite={idSite}/>;
}

function ChangePageFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let {idSite} = useParams();

    return <ChangePage idSite={idSite}/>;
}

function AddPageFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let {idSite} = useParams();

    return <AddPage idSite={idSite}/>;
}

function StatFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let {idSite} = useParams();

    return <Stat idSite={idSite}/>;
}

function HomeSiteFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let {idSite} = useParams();

    return <HomeSite idSite={idSite}/>;
}

function SettingsSiteFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let {idSite} = useParams();

    return <Settings idSite={idSite}/>;
}

function PageItemFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let {idPage} = useParams();

    return <DetailStore idStore={idPage}/>;
}

function StoreItemFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let {idStore, IdProduct} = useParams();

    return <DetailStore idStore={idStore}/>;
}

function ChangePasswordItemFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let {email, hash} = useParams();

    return <ChangePassword email={email} hash={hash}/>;
}

function CatalogAddFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let {idStore, IdProduct} = useParams();

    return <AddCatalog idStore={idStore}/>;
}

function StoreEditFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let {idStore} = useParams();

    return <EditStore idStore={idStore}/>;
}

function StoreAddFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.


    return <AddStore/>;
}

function SiteAddFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.


    return <AddSite/>;
}

function CatalogEditFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let {idStore, idCatalog} = useParams();

    return <EditCatalog idCatalog={idCatalog} idStore={idStore}/>;
}

function ShowItemSupportFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let {idSupport} = useParams();

    return <ShowItemSupport idSupport={idSupport}/>;
}

function OrderItemFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let {idOrder, IdProduct} = useParams();

    return <OrderItem idOrder={idOrder}/>;
}

function ProductAllChangeFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let {idStore, idCatalog} = useParams();
    let temp1 = parseInt(idStore)

    return <ProductAllChange idStore={idStore} idCatalog={idCatalog}/>;


}

function ProductMassCreateFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let {idStore, idCatalog} = useParams();
    let temp1 = parseInt(idStore)

    return <ProductMassCreate idStore={idStore} idCatalog={idCatalog}/>;


}


function CatalogItemFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let {idStore, idCatalog} = useParams();
    let temp1 = parseInt(idStore)
    if (idStore != 'add') {
        return <CatalogsShow idStore={idStore} idCatalog={idCatalog}/>;
    } else {
        return <AddCatalog idStore={idStore}/>;
    }

}

function ProductAddItemFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let {idStore, idCatalog, idProduct} = useParams();

    return <Product idStore={idStore} idCatalog={idCatalog} idProduct={null}/>;
}

function ProductEditItemFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let {idStore, idCatalog, idProduct} = useParams();

    return <Product idStore={idStore} idCatalog={idCatalog} idProduct={idProduct}/>;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
