// @ts-ignore
import React, {Component} from "react";
// import Menu from "../menu/Menu";
// import Cookies from "universal-cookie";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore
import Menu from "../menu/Menu";
// @ts-ignore
import Cookies from "universal-cookie";
import axios from "axios";

export class Settings extends Component <any, any> {

    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: true,
            idProduct: this.props.idProduct,
            status: [],
            specifications: [],
            cookies: cookies.get('bearer'),
            idStore: this.props.idStore,
            idCatalog: this.props.idCatalog,
            addStatusName: '',
            addSpecificationsValue: '',
            updateSpecificationsName: '',
            updateSpecificationsValue: '',
            updateSpecificationsId: '',


        };
        this.handleChangeAddStatusName = this.handleChangeAddStatusName.bind(this);
        this.handleChangeAddSpecificationsValue = this.handleChangeAddSpecificationsValue.bind(this);
        this.handleChangeUpdateSpecificationsValue = this.handleChangeUpdateSpecificationsValue.bind(this);
        this.handleChangeUpdateSpecificationsValue = this.handleChangeUpdateSpecificationsValue.bind(this);
        this.updateSpecifications = this.updateSpecifications.bind(this);
        this.updateSpecificationsApi = this.updateSpecificationsApi.bind(this);

    }

    handleChangeAddStatusName(event) {
        this.setState({addStatusName: event.target.value});
    }

    handleChangeAddSpecificationsValue(event) {
        this.setState({addSpecificationsValue: event.target.value});
    }

    handleChangeUpdateSpecificationsName(event) {
        this.setState({updateSpecificationsName: event.target.value});
    }

    handleChangeUpdateSpecificationsValue(event) {
        this.setState({updateSpecificationsValue: event.target.value});
    }

    componentDidMount() {
        const data = {limit: '10000'};
        axios({

            url: 'https://api.cliclishop.ru/lk/orders/category',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            console.log(result.data)
            this.setState(
                {
                    status: result.data,
                    isLoaded: true,
                });
        })
            .catch(error => {
                console.log(error)
            })


    }
    changePositions(idStatus,act) {
        const data = {

            idStatus: idStatus,
            act: act,

        };
        axios({

            url: 'https://api.cliclishop.ru/lk/orders/settings/positions/change',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.componentDidMount()
            console.log(result.data)
            this.setState(
                {
                    addStatusName: '',

                });
        })



    }
    addStatus() {
        const data = {

            nameStatus: this.state.addStatusName,

        };
        axios({

            url: 'https://api.cliclishop.ru/lk/orders/settings/addstatus',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.componentDidMount()
            this.setState(
                {
                    addStatusName: '',

                });
        })



    }

    deleteStatus(id) {
        const data = {

            idStatus: id,
        };
        axios({

            url: 'https://api.cliclishop.ru/lk/orders/settings/deletestatus',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.componentDidMount()

        })

    }

    updateSpecifications(items) {
        console.log(items)

        this.setState({
            updateSpecificationsName: items.name,
            updateSpecificationsValue: items.content,
            updateSpecificationsId: items.id,

        });
    }

    updateSpecificationsApi() {
        const dataOrder = {
            store_id: this.state.idStore,
            catalog_id: this.state.idCatalog,
            product_id: this.props.idProduct,
            content: this.state.updateSpecificationsValue,
            name: this.state.updateSpecificationsName,
            id_specifications: this.state.updateSpecificationsId,
        };
        axios({

            url: 'https://api.cliclishop.ru/lk/specification/edit',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: dataOrder,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.componentDidMount()
            this.setState(
                {
                    updateSpecificationsName: '',
                    updateSpecificationsValue: '',
                    updateSpecificationsId: '',
                });
        })


    }


    render() {
        const {isLoaded, status} = this.state;
        if (isLoaded) {
            return (<div style={{margin:'30px'}}>
                <Menu/>

                <div id="right_dark_space"></div>
                <div style={{width: 'calc(100% - 130px)'}} id="right_space">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Главная</a></li>
                            <li className="breadcrumb-item"><a href="/orders">Настройка заказов</a></li>


                        </ol>
                    </nav>
                    <div>
                        <h4 className="text-center" style={{marginBottom: '20px', marginTop: '30px'}}>Настройка заказов</h4>

                        <div className="overflow-auto">
                            <h3 style={{textAlign:'center'}}>Статусы заказов</h3>
                            <table className="table table-striped">
                                <tbody>
                                {status.map(items => (
                                    <tr>
                                        <td>{items.name}</td>
                                        <td>{items.content}   </td>
                                        <td><a className="delete_specification"
                                               onClick={() => this.updateSpecifications(items)}>Изменить</a>
                                        </td>
                                        <td><a className="delete_specification"
                                               onClick={() => this.deleteStatus(items.id)}>Удалить</a>
                                        </td>
                                        <td>
                                            <button onClick={()=>this.changePositions(items.id,'down')} type="button" className="btn btn-default" aria-label="Left Align">
                                                <svg width="1em" height="1em" viewBox="0 0 16 16"
                                                     className="bi bi-caret-down-fill" fill="currentColor"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                                </svg>
                                            </button>
                                            <button onClick={()=>this.changePositions(items.id,'up')} type="button" className="btn btn-default" aria-label="Left Align">
                                                <svg width="1em" height="1em" viewBox="0 0 16 16"
                                                     className="bi bi-caret-up-fill" fill="currentColor"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>

                                ))

                                }

                                </tbody>
                            </table>
                        </div>
                        <hr/>
                        <h4 className="text-center" style={{marginBottom: '20px', marginTop: '30px'}}>Добавить
                            статус</h4>
                        <form>
                            <div className={"row"}>
                                <div className="col-md-6 form-group">
                                    <label htmlFor="exampleInputEmail1">Наименование</label>
                                    <input value={this.state.addStatusName}
                                           onChange={this.handleChangeAddStatusName} type="text"
                                           className="form-control"
                                           id="exampleInputEmail1"
                                           aria-describedby="emailHelp"/>
                                </div>

                            </div>


                            <div onClick={() => this.addStatus()} style={{marginBottom: '30px'}}
                                 className="text-center">
                                <div className="middle_button text-center">
                                    <a style={{color: "white", textDecoration: "none"}}>Отправить</a>
                                </div>

                            </div>
                        </form>



                    </div></div>
            </div>)

        } else {
            return ('Загрузка...')
        }


    }
}

// @ts-ignore
export default Settings;