// @ts-ignore
import React, {Component} from "react";
// import Menu from "../menu/Menu";
// import Cookies from "universal-cookie";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore
import Menu from "../menu/Menu";
// @ts-ignore
import Cookies from "universal-cookie";
// @ts-ignore
import axios from 'axios';
import { Button, Toast } from 'react-bootstrap';
import Notification from "../Notification";

export class Photo extends Component <any, any> {

    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: false,
            idProduct: this.props.idProduct,
            product: [],
            image: [],
            notification: [],
            cookies: cookies.get('bearer'),
            idStore:this.props.idStore,
            idCatalog:this.props.idCatalog,
            selectedFile: null,







        };

        this.editPrioritet = this.editPrioritet.bind(this);


    }

    notification(text){
        const data = this.state.notification
        const element = { text: text, time: 25 };
        const newArray = [ ...data, element ];
        this.setState(
            {
                notification: newArray,

            });
    }
    updateData = (value) => {
        var filteredList = this.state.notification.filter(function (item) {
            return item.text.toLowerCase().search(value.toLowerCase()) == -1;
        });
        this.setState({notification: filteredList});
    }
    onChangeHandler=event=>{
        var files = event.target.files
        if(this.maxSelectFile(event)){
            // if return true allow to setState
            this.setState({
                selectedFile: files
            })
        }
    }
    renameFile(originalFile, newName) {
        return new File([originalFile], newName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });
    }
    onClickHandler = () => {

        for(var x = 0; x<this.state.selectedFile.length; x++) {
            const data = new FormData()
            this.renameFile(this.state.selectedFile[x].name,this.state.selectedFile[x].name.replace(''))
            console.log(this.state.selectedFile[x])
            console.log( this.renameFile(this.state.selectedFile[x].name,this.state.selectedFile[x].name.replace(/[\s,%]/g, '')))
            data.append('file', this.state.selectedFile[x])
            console.log(data)
            axios.post('https://image.cliclishop.ru/image?type=product&store_id'+this.state.idStore+'&catalog_id'+ this.state.idCatalog +
                '&product_id'+ this.props.idProduct, data, {
                headers: {

                }
                // receive two    parameter endpoint url ,form data
            }).then(res => { // then print response status

                this.uploads(res.data.link,res.data.size)

            })
        }


    }
    maxSelectFile=(event)=>{
        let files = event.target.files // create file object
        if (files.length > 10) {
            const msg = 'Only 3 images can be uploaded at a time'
            event.target.value = null // discard selected file
            console.log(msg)
            return false;

        }
        return true;

    }
    uploads(link,size){
        console.log('090')
        const data = {
            store_id: this.state.idStore,
            catalog_id: this.state.idCatalog,
            product_id: this.props.idProduct,
            imageLink: link,
            alt: '',
            imageSize: size,
            prioritet: '1',

        };
        axios({

            url: 'https://api.cliclishop.ru/lk/product/image/add',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => {
           this.componentDidMount()
            if(result.data.text){
                this.notification(result.data.text)
            }
        })
            .catch(error => console.log(error));

    }
    editPrioritet(event){
        console.log(event.target.value)
        const data = {
            store_id: this.state.idStore,
            catalog_id: this.state.idCatalog,
            product_id: this.props.idProduct,
            image_id: event.target.id,
            image_prioritet: event.target.value,


        };
        axios({

            url: 'https://api.cliclishop.ru/lk/product/image/prioritet',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.componentDidMount()
        })

    }
    deleteImage(idImage){
        const data = {
            store_id: this.state.idStore,
            catalog_id: this.state.idCatalog,
            product_id: this.props.idProduct,
            id_image: idImage,


        };
        axios({

            url: 'https://api.cliclishop.ru/lk/product/image/delete',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status

            this.componentDidMount()
        })


    }
    componentDidMount() {
        const data = {
            store_id: this.state.idStore,catalog_id: this.state.idCatalog,product_id: this.props.idProduct


        };
        axios({

            url: 'https://api.cliclishop.ru/lk/product/image/show',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.setState(
                {
                    image: result.data,
                    isLoaded: true,
                });
        })


    }



    render() {
        const {isLoaded,image} = this.state;
        if(isLoaded){
            return(<div>
                <Notification updateData={this.updateData} NotificationArray={this.state.notification}/>
                <h4 className="text-center" style={{marginBottom:'20px',marginTop:'30px'}}>Изображения товара</h4>
                <form>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlFile1">Выберите изображения</label>

                        <input multiple name="file" onChange={this.onChangeHandler} type="file" className="form-control-file" id="exampleFormControlFile1"/>
                    </div>
                </form>
                <button type="button" className="btn btn-success btn-block" onClick={this.onClickHandler}>Загрузить
                </button>
                <div className="row margin_padding_0 text-center"
                     style={{height:'36px',border:'1px solid #ced4da',borderRadius:'0.25rem 0.25rem 0 0',borderBottom:'none'}}>
                    <div className="col-12 col-sm-4 align-self-center">
                        Изображение
                    </div>
                    <div className="col-12 col-sm-3 align-self-center">
                        Alt
                    </div>
                    <div className="col-12 col-sm-2 align-self-center">
                        Приоритет
                    </div>
                    <div className="col-12 col-sm-2 align-self-center">
                        Размер
                    </div>
                    <div className="col-12 col-sm-1">

                    </div>
                </div>

                <ul className="margin_padding_0 sortableImg"
                 >
                    {image.map(items => (

                        <li  className="row text-center "
                            style={{margin:'0',padding:'10px 0 10px 0',borderBottom:'1px solid #ced4da'}}>
                            <div className="col-12 col-sm-4">
                                <img style={{height:"auto",width:'100%'}} className="image_from_goods_list" src={items.link} alt={items.alt}/>
                            </div>
                            <div className="col-12 col-sm-3 align-self-center">
                                {items.alt}
                            </div>
                            <div className="col-12 col-sm-2 align-self-center">
                                <input onChange={this.editPrioritet}   value={ items.prioritet  }
                                       type="text" className="form-control" id={items.id}
                                       aria-describedby="emailHelp"/>
                            </div>
                            <div className="col-12 col-sm-2 align-self-center">
                                {(items.size/1024).toFixed(0)}КБ
                            </div>
                            <div onClick={()=>this.deleteImage(items.id)} className="col-12 col-sm-1 align-self-center">
                                <img   src="/images/del.png" alt="delete"
                                     style={{width:'20px',cursor:'pointer'}}/>
                            </div>
                        </li>
                    ))}


                </ul>



            </div>)
        }
        else {
            return (<div className="preloader d-flex justify-content-center">
                <div className="align-middle spinner-grow text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>)
        }



    }
}

// @ts-ignore
export default Photo;