import React, {Component} from 'react';


export class Notification extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            error: null,
            notification: this.props.NotificationArray,
        };



    }


    componentDidMount() {
        let urlAPI = 'https://api.cliclishop.ru/lk/api/widget/customization/' + this.state.idShop;

        fetch(urlAPI)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log('result')
                    this.setState(
                        {
                            customization: result.settings.settings,
                            shopCompany: result.shop,
                            isLoaded: true,


                        });

                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )

    }



    render() {
        const {isLoaded,NotificationArray} = this.props;
        return(


                <div style={{position:'fixed',top:'0',right:'0',zIndex:99}}>

                    {NotificationArray.map(items => (

                        <div className="toast" style={{opacity:'1'}} >

                            <div className="toast-header">
                                <img style={{height:'2em'}} src="/cliclishop.png" className="rounded mr-2" alt="..."/>
                                <strong className="mr-auto">CliCliShop</strong>
                                {/*<small className="text-muted">just now</small>*/}
                                <button onClick={() => { this.props.updateData(items.text)}} type="button" className="ml-2 mb-1 close" data-dismiss="toast"
                                        aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="toast-body">
                                {items.text}
                            </div>
                        </div>
                    ))}


                </div>
            )


    }
}

export default Notification;