import React, {Component} from 'react';


export class Menu extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            'activeMenu':false,
            hover:false
        };

        this.activeMenu = this.activeMenu.bind(this);
        this.onMouseOverFunc = this.onMouseOverFunc.bind(this);
        this.onMouseLeaveFunc = this.onMouseLeaveFunc.bind(this);
    }


    componentDidMount() {


    }

    onMouseOverFunc(){
        console.log('+++++')
        this.setState({hover: true});
    }
    onMouseLeaveFunc(){
        console.log('----')
        this.setState({hover: false});
    }
    menu(full) {
        const {hover, activeMenu} = this.state;
        console.log(full)

        return (
            <div onMouseEnter={this.onMouseOverFunc.bind(this)} onMouseLeave={this.onMouseLeaveFunc.bind(this)} style={activeMenu?{width:'100%'}:{}} id="left_menu" className={full ? 'left_menu_full_width' : ''||hover ? 'left_menu_full_width' : ''}>
                {
                    full ?  <div style={{height: '1cm',zIndex:100}}>
                        <img onClick={()=>this.activeMenu(false)} style={{height:'100%', float:'left', margin:'10px'}} src='/images/cancel.png'/>

                    </div> : ''
                }
                {
                    hover?full = true:""
                }
                <a href="/">
                    <div className="left_menu_item">
                        <img className="menu_item_icon" src="/images/home.png" alt="dashboard"/>
                        <img className="menu_item_icon displayNone" src="/images/home_w.png" alt="dashboard"/>
                        <span className={ full ? 'menu_title_on menu_subnav_href' : ' menu_subnav_href' }>Главная</span>
                    </div>
                </a>

                <a href="/store">
                    <div className="left_menu_item">
                        <img className="menu_item_icon" src="/images/setting.png" alt="catalog_settings"/>
                        <img className="menu_item_icon displayNone" src="/images/setting_w.png"
                             alt="catalog_settings"/>
                        <span className={ full ? 'menu_title_on menu_subnav_href' : ' menu_subnav_href' }>Магазины</span>
                    </div>
                </a>
                <a href="/site">
                    <div className="left_menu_item">
                        <img className="menu_item_icon" src="/images/setting.png" alt="catalog_settings"/>
                        <img className="menu_item_icon displayNone" src="/images/setting_w.png"
                             alt="catalog_settings"/>
                        <span className={ full ? 'menu_title_on menu_subnav_href' : ' menu_subnav_href' }>Сайт</span>
                    </div>
                </a>

                <a href="/orders">
                    <div className="left_menu_item">
                        <img className="menu_item_icon" src="/images/massage_icon.png" alt="catalog_settings"/>
                        <img className="menu_item_icon displayNone" src="/images/massage_icon_w.png"
                             alt="catalog_settings"/>
                        <span className={ full ? 'menu_title_on menu_subnav_href' : ' menu_subnav_href' }>Заказы</span>
                    </div>
                </a>

                <a href="/finance">
                    <div className="left_menu_item">
                        <img className="menu_item_icon" src="/images/finance.png"/>
                        <img className="menu_item_icon displayNone" src="/images/finance_w.png"/>
                        <span className={ full ? 'menu_title_on menu_subnav_href' : ' menu_subnav_href' }>Баланс и оплата</span>
                    </div>
                </a>

                <a href="/services">
                    <div className="left_menu_item">
                        <img className="menu_item_icon" src="/images/services.png"/>
                        <img className="menu_item_icon displayNone" src="/images/services_w.png"/>
                        <span className={ full ? 'menu_title_on menu_subnav_href' : ' menu_subnav_href' }>Тарифы и услуги</span>
                    </div>
                </a>


                <a href="/support">
                    <div className="left_menu_item">
                        <img className="menu_item_icon" src="/images/support.png"/>
                        <img className="menu_item_icon displayNone" src="/images/support_w.png"/>
                        <span className={ full ? 'menu_title_on menu_subnav_href' : ' menu_subnav_href' }>Техподдержка</span>
                    </div>
                </a>

                {/*<a href="/profile">*/}
                {/*    <div className="left_menu_item">*/}
                {/*        <img className="menu_item_icon" src="/images/profile.png" alt="profile"/>*/}
                {/*            <img className="menu_item_icon displayNone" src="/images/profile_w.png" alt="profile"/>*/}
                {/*                <span className="menu_subnav_href">Профиль</span>*/}
                {/*    </div>*/}
                {/*</a>*/}
            </div>
        )
    }
    activeMenu(status){
        console.log('090')
        this.setState(
            {
                activeMenu:status
            });
    }
    render() {
        const {activeMenu} = this.state;
        if (window.screen.width > 650) {
            return (this.menu(false))

        } else {

            return (<div style={{height: '1cm',zIndex:999999, marginBottom:'10px'}}>
                <img onClick={()=>this.activeMenu(true)} style={{height:'80%', float:'left', margin:'10px'}} src='/images/menu.png'/>
                {activeMenu?this.menu(true):''}
            </div>)
        }


    }
}

export default Menu;