// @ts-ignore
import React, {Component} from "react";
// import Menu from "../menu/Menu";
// import Cookies from "universal-cookie";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore

// @ts-ignore
import Cookies from "universal-cookie";
import axios from "axios";

export class ShowProducts extends Component <any, any> {
    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: false,
            product: [],
            cookies: cookies.get('bearer'),
            idStore: this.props.idStore,
            idCatalog: this.props.idCatalog,
            idCategory: this.props.children,
            search: null,
            productActive: [],
            category: [],
            actionShowView: 'list',
            sort: 3
        };
        console.log('090')
        this.setState(this.state);
        // this.changeStatus = this.changeStatus.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.changeShowView = this.changeShowView.bind(this);
        this.handleChangeSort = this.handleChangeSort.bind(this);
    }


    updateListProduct() {
        const data = {store_id: this.state.idStore, catalog_id: this.state.idCatalog};
        axios({

            url: 'https://api.cliclishop.ru/lk/product',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            console.log(this.props.idCategory)
            if (this.props.idCategory) {
                const temp = result.data.filter(item => item.categories != null && item.categories.id === this.props.idCategory);
                console.log('+')
                console.log(temp)
                this.setState(
                    {
                        product: result.data,
                        productsActive: temp,
                        isLoaded: true,
                    });
            } else {
                console.log('-')
                this.setState(
                    {
                        product: result.data,
                        productsActive: result.data,
                        isLoaded: true,
                    });
            }


        })

    }

    componentDidMount() {
        this.updateListProduct()
        const dataCategory = {
            store_id: this.state.idStore,
            catalog_id: this.state.idCatalog,
            product_id: this.props.idProduct
        };
        axios({

            url: 'https://api.cliclishop.ru/lk/catalog/categories',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: dataCategory,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            if (this.props.idCategory) {
                const temp = result.data.filter(item => item.id === this.props.idCategory);
                this.setState(
                    {
                        category: temp[0],
                    });
            } else {
                this.setState(
                    {
                        category: result.data,
                    });
            }


        })

    }

    handleSearch(event) {
        this.setState({search: event.target.value});
        var filteredList = this.state.product.filter(function (item) {
            return item.name.toLowerCase().search(event.target.value.toLowerCase()) !== -1;
        });
        console.log(event.target.value)
        console.log(filteredList)
        this.setState({productsActive: filteredList});
    }

    changeShowView(action) {
        console.log(action)
        this.setState({actionShowView: action});
    }

    handleClickDeleteProduct(id) {
        const data = {store_id: this.state.idStore, catalog_id: this.state.idCatalog};
        axios({

            url: 'https://api.cliclishop.ru/lk/product/' + id + '/deleteproduct',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.updateListProduct()
            this.setState(
                {});
        })


    }

    sort(product) {
        const {sort} = this.state;
        console.log(sort)
        if (sort == 1) {
            product.sort(function (a, b) {
                return b.price - a.price
            })
        }
        if (sort == 2) {
            product.sort(function (a, b) {
                return a.price - b.price
            })
        }
        if (sort == 3) {
            product.sort(function (a, b) {
                var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase()
                if (nameA < nameB) //сортируем строки по возрастанию
                    return -1
                if (nameA > nameB)
                    return 1
                return 0 // Никакой сортировки
            })
        }
        if (sort == 4) {
            product.sort(function (a, b) {
                var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase()
                if (nameA > nameB) //сортируем строки по возрастанию
                    return -1
                if (nameA < nameB)
                    return 1
                return 0 // Никакой сортировки
            })
        }
        if (sort == 5) {
            product.sort(function (a, b) {
                return a.status - b.status
            })
        }
        if (sort == 6) {
            product.sort(function (a, b) {
                return b.status - a.status
            })
        }
        console.log(product)
        return product;
    }
    price(product) {


        if (product.attrproducts.length > 0) {
            if (product.attrproducts.length == 1) {
                return (product.attrproducts[0].value)
            } else {
                return product.attrproducts[0].value + ' - ' + product.attrproducts[product.attrproducts.length - 1].value
            }
        } else {
            return ('-')
        }
    }
    ShowViewProduct() {
        const {category, productsActive, actionShowView, idStore, idCatalog} = this.state;
        let products = this.sort(productsActive)
        if (actionShowView === 'grid') {
            return (
                <div className="row margin_padding_0 w-100">
                    {products.map(items => (<div className="col-12 col-md-6 col-lg-3 margin_t_b_20">
                        <div className="text-center">
                            <div className="middle_button text-center low_button">
                                <a href={'/store/' + idStore + '/catalog/' + idCatalog + '/product/' + items.id + '/edit'}
                                   style={{color: "white", textDecoration: "none"}}>Изменить</a>
                            </div>
                            <div className="middle_button text-center low_button"
                                 style={{backgroundColor: '#ff8267'}}>
                                <a onClick={() => this.handleClickDeleteProduct(items.id)}
                                   style={{color: "white", textDecoration: "none"}}>Удалить</a>
                            </div>
                        </div>
                        {
                            items.status ? '' : ''
                        }
                        <div className="shadow-card" style={(items.status) ? {
                            boxShadow: '0 0 5px 0 green',
                            minHeight: '390px',
                            marginTop: '-10px'
                        } : {boxShadow: '0 0 5px 0 red', minHeight: '390px', marginTop: '-10px'}}>
                            <div className="catalogList_product_mainImg media">

                                <img className="card_img align-self-center"
                                     src={items.image ? items.image.link : '/img/no_image.png'}/>

                            </div>
                            <div className="margin_t_b_20">
                                <h5 className="text-center catalogList_product_name">{items.name}</h5>
                                <h3 className="text-center">Цена: {this.price(items)}</h3>
                            </div>
                        </div>
                    </div>))}


                </div>
            )
        }

        else if(actionShowView === 'list') {
            return (
                <div className="row margin_padding_0 w-100">
                    <table  style={{margin: '25px'}} className="table table-hover table-responsive">
                        <thead>
                        <tr>
                            <th scope="col-1">Наименование</th>
                            <th scope="col">Статус</th>
                            <th scope="col">Стоимость</th>
                            <th scope="col">Категория</th>

                            <th scope="col"></th>

                            <th scope="col"></th>

                        </tr>
                        </thead>
                        <tbody>

                        {products.map(items => (<tr>
                            <th scope="row">{items.name}</th>
                            <td>{items.status?'Активно':'Не активно'}</td>
                            <td>{this.price(items)}</td>
                            <td>{items.category}</td>

                            <td><a
                                href={'/store/' + idStore + '/catalog/' + idCatalog + '/product/' + items.id + '/edit'}
                                style={{color: "blue", textDecoration: "none"}}>Изменить</a></td>
                            <td><a onClick={() => this.handleClickDeleteProduct(items.id)}
                                   style={{color: "red", textDecoration: "none"}}>Удалить</a></td>

                        </tr>))}

                        </tbody>
                    </table>


                </div>
            )
        }
    }

    handleChangeSort(event) {
        this.setState({sort: event.target.value});
    }

    render() {
        const {category, productsActive, isLoaded, idStore, idCatalog} = this.state;

        if (isLoaded) {
            return (<div>


                <div className={'row margin_padding_0 w-100'}>
                    <div className={'col-12 col-sm-4'}>
                        <div className="form-group p-0 m-0 clicli_lg_fr clicli_search">
                            <input value={this.state.search} onChange={this.handleSearch}
                                   className="form-control mr-sm-2" type="search"
                                   placeholder="Поиск по магазину"
                                   aria-label="Поиск"/>
                        </div>
                    </div>
                    <div className={'col-12 col-sm-6 '}>
                        <div className="form-group">
                            <select onChange={this.handleChangeSort}>
                                {/*<option value='1'>По стоимости(Дороже)</option>*/}
                                {/*<option value='2'>По стоимости(Дешевле)</option>*/}
                                <option value='3'>По наименованию(А-Я)</option>
                                <option value='4'>По наименованию(Я-А)</option>
                                <option value='5'>По статусу(Не активные)</option>
                                <option value='6'>По статусу(Активные)</option>
                            </select>
                        </div>

                    </div>
                    <div className={'col-12 col-sm-2'}>
                        <div onClick={() => this.changeShowView("list")}>
                            <svg style={{float: 'right'}} width="2em"
                                 height="2em" viewBox="0 0 16 16"
                                 className="bi bi-list"
                                 fill="currentColor"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                      d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                        </div>
                        <div onClick={() => this.changeShowView("grid")}>
                            <svg style={{float: 'right'}} width="2em"
                                 height="2em" viewBox="0 0 16 16"
                                 className="bi bi-grid-3x2-gap-fill"
                                 fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V4zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V4zM1 9a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V9zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V9zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V9z"/>
                            </svg>
                        </div>
                    </div>
                </div>
                {this.ShowViewProduct()}

                {/*</div>*/}

            </div>)
        } else {
            return (<div>


                <div className="preloader d-flex justify-content-center">
                    <div className="align-middle spinner-grow text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>


            </div>)
        }


    }
}

// @ts-ignore
export default ShowProducts;