// @ts-ignore
import React, {Component} from "react";
import Menu from "../menu/Menu";
import Cookies from "universal-cookie";
import axios from "axios";
import Notification from "../Notification";

export class AddStore extends Component <any, any> {
    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: true,
            store: [],
            notification: [],
            name:'',


        };
        this.changeName = this.changeName.bind(this);



    }
    changeName(event){
        this.setState({name: event.target.value});
    }
    updateData = (value) => {
        var filteredList = this.state.notification.filter(function (item) {
            return item.text.toLowerCase().search(value.toLowerCase()) == -1;
        });
        this.setState({notification: filteredList});
    }
    notification(text){
        const data = this.state.notification
        const element = { text: text, time: 25 };
        const newArray = [ ...data, element ];
        this.setState(
            {
                notification: newArray,

            });
    }
    componentDidMount() {



    }
    addStoreApi() {

        const data = {name_shop: this.state.name};
        axios({

            url: 'https://api.cliclishop.ru/lk/store/add',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            if(result.data.error == 1){
                this.notification('Превышен лимит на количество магазинов.')
            }
            else {
                window.location.assign('/store/'+result.data.idStore+'/catalog');
            }

        })
    }

    render() {
        const {store, isLoaded} = this.state;
        if(isLoaded){
            return(<div>
                <Menu/>
                <div id="right_dark_space"></div>
                <div style={window.screen.width>650?{width: 'calc(100% - 100px)'}:{width: '100%'}} id="right_space">
                    <Notification updateData={this.updateData} NotificationArray={this.state.notification}/>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Главная</a></li>
                            <li className="breadcrumb-item"><a href="/store">Магазины</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Добавить магазин</li>
                        </ol>
                    </nav>

                    <div className="shadow-card col-sm-3" style={{margin:'0 auto'}}>

                        <form  ><br/>

                            <div className="form-group">
                                <h3 className="mb-4 text-center ">Создание магазина</h3>
                                <input onChange={this.changeName} value={this.state.name} type="text" className=" form-control" id="exampleFormControlInput1"
                                       placeholder="Название магазина"/>
                            </div>



                        </form>

                        <div onClick={()=>this.addStoreApi()}  style={{marginBottom:'30px'}} className="text-center">
                            <div className="middle_button text-center">
                                <a style={{color: "white", textDecoration: "none"}}>Создать</a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>)
        }
        else {
            return (<div>
                <Menu/>
                <div id="right_dark_space"></div>
                <div style={window.screen.width>650?{width: 'calc(100% - 100px)'}:{width: '100%'}} id="right_space">

                    <div className="preloader d-flex justify-content-center">
                        <div className="align-middle spinner-grow text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>

            </div>)
        }



    }
}

// @ts-ignore
export default AddStore;