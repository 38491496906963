// @ts-ignore
import React, {Component} from "react";
import Notification from "../Notification";
// import Menu from "../menu/Menu";
// import Cookies from "universal-cookie";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore

// @ts-ignore
import Cookies from "universal-cookie";
import ShowShop from "./shop/ShowShop";
import AddShop from "./shop/AddShop";
import axios from "axios";

export class Pay extends Component <any, any> {
    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: true,
            catalog: [],
            cookies: cookies.get('bearer'),
            idStore: this.props.idStore,
            idCatalog: this.props.idCatalog,
            search: null,
            wayPay: [],
            wayPayUser: [],
            productActive: [],
            payAddId: [],
            notification: [],

        };
        this.payAll = this.payAll.bind(this);
        this.changePay = this.changePay.bind(this);
        this.notification= this.notification.bind(this);

    }
    updateData = (value) => {
        var filteredList = this.state.notification.filter(function (item) {
            return item.text.toLowerCase().search(value.toLowerCase()) == -1;
        });
        this.setState({notification: filteredList});
    }
    changePay(event) {
        this.setState({payAddId: event.target.value});
    }

    componentDidMount() {

        this.payAll()
        this.payUser()


    }
    notification(text){
       const data = this.state.notification
        const element = { text: text, time: 25 };
        const newArray = [ ...data, element ];
        this.setState(
            {
                notification: newArray,

            });
    }
    deletePay(id){
        const data = {};

        axios({

            url: 'https://api.cliclishop.ru/lk/way_pay_user/delete/'+id,
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            console.log(result.data)
            this.notification('Успешно удален способ оплаты')
            this.payUser();



        })
    }
    payAll() {
        const data = {};

        axios({

            url: 'https://api.cliclishop.ru/lk/way_pay',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            console.log(result.data)



            this.setState(
                {
                    payAddId: result.data[0].id,
                    wayPay: result.data
                });
        })
    }

    payUser() {
        const data = {};
        axios({

            url: 'https://api.cliclishop.ru/lk/way_pay_user/' + this.props.idStore + '/' + this.props.idCatalog,
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            console.log(result.data)
            this.setState(
                {
                    wayPayUser: result.data
                });
        })
    }

    payUserAdd(idPay) {
        const data = {idPay: idPay};
        axios({

            url: 'https://api.cliclishop.ru/lk/way_pay_user/add/' + this.props.idStore + '/' + this.props.idCatalog,
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.payUser()
            this.notification('Успешно добавлен способ оплаты')
            this.setState(
                {});
        })
    }


    render() {
        const {wayPay, isLoaded, wayPayUser, idCatalog} = this.state;
        if (isLoaded) {
            return (<div>
                <Notification updateData={this.updateData} NotificationArray={this.state.notification}/>
                <h4 className="text-center" style={{margin: '0 auto 20px auto'}}>Способы доставки</h4>
                <div>
                    <h5>Добавить способ доставки</h5>
                    <select onChange={this.changePay} value={this.state.payAddId} className="form-control"
                            id="exampleFormControlSelect1">

                        {wayPay.map(items => (

                            <option value={items.id}>{items.name}</option>
                        ))}
                    </select>
                </div>
                <div onClick={() => this.payUserAdd(this.state.payAddId)} style={{marginBottom: '30px'}}
                     className="text-center">
                    <div className="middle_button text-center">
                        <a style={{color: "white", textDecoration: "none"}}>Отправить изменения</a>
                    </div>

                </div>
                <hr style={{width: "100%", margin: '20px 0 20px 0'}}/>
                <div>
                    <h5>Активные способы оплаты</h5>
                    {wayPayUser.map(items => (

                        <div>{items.payment.name} <a onClick={()=>this.deletePay(items.id)} style={{color:"red"}}>Удалить</a> <a style={{color:"red"}}>Настройки</a></div>
                    ))}
                </div>
                <hr style={{width: "100%", margin: '20px 0 20px 0'}}/>


            </div>)
        } else {
            return (<div>

                <div id="right_dark_space"></div>
                <div style={{width: 'calc(100% - 100px)'}} id="right_space">
                    <div className="preloader d-flex justify-content-center">
                        <div className="align-middle spinner-grow text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>

            </div>)
        }


    }
}

// @ts-ignore
export default Pay;