// @ts-ignore
import React, {Component} from "react";
// import Menu from "../menu/Menu";
// import Cookies from "universal-cookie";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore
import Menu from "../menu/Menu";
// @ts-ignore
import Cookies from "universal-cookie";
import Info from "./Info";
import Photo from "./Photo";
import Category from "./Category";
import Specifications from "./Specifications";
import {Toast} from "react-bootstrap";
import axios from "axios";
import Headings from "./Headings";
export class Product extends Component <any, any> {
    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: false,
            productId: '',
            product: [],
            cookies: cookies.get('bearer'),
            idStore: this.props.idStore,
            idCatalog: this.props.idCatalog,
            activeInfo: true,
            activePhoto: false,
            activeCategory: false,
            activeSpecifications: false,
            activeHeadings: false,


        };

        this.ActivateMenu = this.ActivateMenu.bind(this);
    }

    componentDidMount() {
        if(this.props.idProduct){
            this.setState(
                {
                    productId: this.props.idProduct,
                    isLoaded: true,
                });
        }
        else {
            const data = {store_id: this.state.idStore, catalog_id: this.state.idCatalog};
            axios({

                url: 'https://api.cliclishop.ru/lk/product/add',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                withCredentials: true,
                method: "POST",
                data: data,

                // receive two    parameter endpoint url ,form data
            }).then(result => { // then print response status
                this.setState(
                    {
                        productId: result.data.id_product,
                        isLoaded: true,
                    });
            })

        }


    }

    ActivateMenu(param) {
        switch(param) {
            case 'info':
                return(this.setState({
                    activeInfo: true,
                    activePhoto: false,
                    activeCategory: false,
                    activeSpecifications: false,
                    activeHeadings: false,

                }))

            case 'photo':
                return(this.setState({
                    activeInfo: false,
                    activePhoto: true,
                    activeCategory: false,
                    activeSpecifications: false,
                    activeHeadings: false,

                }))
            case 'category':
                return(this.setState({
                    activeInfo: false,
                    activePhoto: false,
                    activeCategory: true,
                    activeSpecifications: false,
                    activeHeadings: false,

                }))
            case 'specifications':
                return(this.setState({
                    activeInfo: false,
                    activePhoto: false,
                    activeCategory: false,
                    activeSpecifications: true,
                    activeHeadings: false,

                }))
            case 'headings':
                return(this.setState({
                    activeInfo: false,
                    activePhoto: false,
                    activeCategory: false,
                    activeSpecifications: false,
                    activeHeadings: true,

                }))
        }
    }
    body(){
        const {activeInfo,activeCategory,activePhoto,activeSpecifications,activeHeadings} = this.state;

        if( activeInfo){
            return(<Info idStore={this.state.idStore} idCatalog={this.state.idCatalog} idProduct={this.state.productId}/>);
        }
        else if(activeCategory){
            return(<Category idStore={this.state.idStore} idCatalog={this.state.idCatalog} idProduct={this.state.productId}/>);
        }
        else if(activePhoto){
            return(<Photo idStore={this.state.idStore} idCatalog={this.state.idCatalog} idProduct={this.state.productId}/>);
        }
        else if(activeSpecifications){
            return(<Specifications idStore={this.state.idStore} idCatalog={this.state.idCatalog} idProduct={this.state.productId}/>);

        }
        else if(activeHeadings){
            return(<Headings idStore={this.state.idStore} idCatalog={this.state.idCatalog} idProduct={this.state.productId}/>);
        }
    }

    render() {
        const {product, isLoaded, idStore, idCatalog,activeInfo,activeCategory,activePhoto,activeSpecifications,activeHeadings} = this.state;
        if (isLoaded) {
            return (<div  >
                <Menu/>

                <div id="right_dark_space"></div>
                <div style={window.screen.width>650?{width: 'calc(100% - 100px)'}:{width: '100%'}} id="right_space">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Главная</a></li>
                            <li className="breadcrumb-item"><a href="/store">Магазины</a></li>
                            <li className="breadcrumb-item"><a href={'/store/'+ idStore+'/catalog'}>Каталоги</a></li>
                            <li className="breadcrumb-item"><a href={'/store/'+ idStore+'/catalog/'+idCatalog}>Товары</a></li>

                        </ol>
                    </nav>
                    <div><h1 className="text-center margin_t_b_20">Настройки товара</h1></div>
                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <a className={'nav-link'+ (activeInfo ? ' active':'')} onClick={() => this.ActivateMenu('info')} href="#">Информация о товаре</a>
                        </li>
                        <li className="nav-item">
                            <a className={'nav-link'+ (activePhoto ? ' active':'')} onClick={() => this.ActivateMenu('photo')} href="#">Фотографии</a>
                        </li>
                        <li className="nav-item">
                            <a className={'nav-link'+ (activeCategory ? ' active':'')} onClick={() => this.ActivateMenu('category')} href="#">Категории</a>
                        </li>
                        <li className="nav-item">
                            <a className={'nav-link'+ (activeSpecifications ? ' active':'')} onClick={() => this.ActivateMenu('specifications')} href="#">Характеристики</a>
                        </li>
                        <li className="nav-item">
                            <a className={'nav-link'+ (activeHeadings ? ' active':'')} onClick={() => this.ActivateMenu('headings')} href="#">Рубрики</a>
                        </li>
                    </ul>

                    {this.body()}
                </div>
            </div>)
        } else {
            return ('Загрузка...')
        }


    }
}

// @ts-ignore
export default Product;