// @ts-ignore
import React, {Component} from 'react';
import Menu from "../menu/Menu";
// import Cookies from 'js-cookie';
import Cookies from 'universal-cookie';
import axios from "axios";


export class Services extends Component <any, any> {

    constructor(props: any) {

        super(props);
        const cookies = new Cookies();
        this.state = {
            info: [],
            logging: [],
            order: [],
            isLoadedLogging: false,
            isLoadedOrder: false,
            isLoadedInfo: false,

            cookies: cookies.get('bearer'),


        };


    }

    componentDidMount() {
        axios('https://api.cliclishop.ru/lk/tariffs/show/all', {

            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            console.log(result.data)
            this.setState(
                {
                    info: result.data,
                    isLoaded: true,
                });
        })


    }
    activateTariff(id_service){
        const data = {
            id_service: id_service


        };
        axios({

            url: 'https://api.cliclishop.ru/lk/finance/tarifs/activate',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.setState(
                {

                });
        })
    }

    tarifs(){
        return(<div className="row margin_padding_0" style={{marginTop:'20px'}}>
            <div className="col-12">
                <div className="shadow-card">
                    <h4 className="text-center">Тарифы</h4>
                    <div className="row">
                        {this.state.info.map(item => (
                            <div className="col-12 col-sm-6 margin_padding_0">
                                <div className="row"
                                     style={{backgroundColor:item.attr.color,margin:'50px 20px 0 20px',padding:"20px",color:'white',borderRadius:'20px'}}>
                                    <div className="col-12">
                                        <h5 className="text-center">"{item.name}"</h5>
                                        <hr style={{backgroundColor:'white'}}/>
                                            <h5 style={{margin:'10px auto'}}> {item.attr.price} рублей/день
                                                или {item.attr.price * 30} рублей/месяц</h5>
                                            <p>
                                                *Данный тариф активен в течении 1 месяца.<br/>
                                            </p>
                                    </div>
                                    <div className="col-3 text-center">
                                        <img src="/images/shop.png" style={{height:'50px'}}/>
                                            Магазинов:<br/>
                                            {item.attr.max_store}
                                    </div>
                                    <div className="col-3 text-center">
                                        <img src="/images/catalogue.png" style={{height:'50px'}}/>
                                            Каталогов:<br/>
                                            {item.attr.max_catalog}
                                    </div>
                                    <div className="col-3 text-center">
                                        <img src="/images/good.png" style={{height:'50px'}}/>
                                            Продуктов:<br/>
                                            {item.attr.max_product}
                                    </div>
                                    <div className="col-3 text-center">
                                        <img src="/images/img.png" style={{height:'50px'}}/>
                                            Хранилище:<br/>
                                            {item.attr.max_image}МБ
                                    </div>
                                </div>
                                <div className="text-center">

                                    {/*<div className="middle_button text-center"*/}
                                    {/*     style={{display:"inline-block",backgroundColor:'white',color:'#50A2EE',border:"2px solid #50A2EE"}}>*/}
                                    {/*    Активный*/}
                                    {/*</div>*/}

                                    <div className="middle_button text-center" style={{display:"inline-block"}}>
                                        <a style={{color:"white"}}
                                          onClick={()=>this.activateTariff(item.id)}>Активировать</a>


                                    </div>




                                    <div className="middle_button text-center" style={{display:"inline-block"}}>
                                        Подробнее
                                    </div>

                                </div>
                            </div>

                        ))

                        }



                    </div>
                </div>
            </div>
        </div>)
    }



    render() {
        const {info, logging, isLoaded, order, categories, Breadcrumbs1ID, Breadcrumbs2ID, sort} = this.state;
        console.log(info)
        if (isLoaded) {
            return (<div>
                    <Menu/>

                    <div id="right_dark_space"></div>
                    <div style={window.screen.width>650?{width: 'calc(100% - 100px)'}:{width: '100%'}} id="right_space">
                        <div className="text-center">
                            <h1 className="text-center" style={{margin: '40px', display: 'inline-block'}}>Тарифы и услуги</h1>
                            {this.tarifs()}
                        </div>
                    </div>
                </div>
            )

        } else {
            return (<div>
                <Menu/>
                <div id="right_dark_space"></div>
                <div style={window.screen.width>650?{width: 'calc(100% - 100px)'}:{width: '100%'}} id="right_space">

                    <div className="preloader d-flex justify-content-center">
                        <div className="align-middle spinner-grow text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>

            </div>)
        }


    }
}

// @ts-ignore
export default Services;