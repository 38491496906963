// @ts-ignore
import React, {Component} from "react";
// import Menu from "../menu/Menu";
// import Cookies from "universal-cookie";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore

// @ts-ignore
import Cookies from "universal-cookie";
import ShowPage from "./page/ShowPage";
import AddPage from "./page/AddPage";
import axios from "axios";

export class Page extends Component <any, any> {
    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: true,
            catalog: [],
            cookies: cookies.get('bearer'),
            idStore: this.props.idStore,
            idCatalog: this.props.idCatalog,
            search: null,
            productActive: [],

        };


    }


    componentDidMount() {

        const data = {store_id: this.state.idStore, catalog_id: this.state.idCatalog};
        axios({

            url: 'https://api.cliclishop.ru/lk/catalog/edit',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            console.log(result.data)
            this.setState(
                {
                    catalog: result.data
                });
        })

    }


    render() {
        const {productsActive, isLoaded, idStore, idCatalog} = this.state;
        if (isLoaded) {
            return (<div>

                <h4 className="text-center" style={{margin: '0 auto 20px auto'}}>Страницы с дополнительной
                    информацией</h4>
                <ShowPage idStore={this.state.idStore} idCatalog={this.state.idCatalog}/>
                <h4 className="text-center" style={{margin: '0 auto 20px auto'}}>Добавить страницу</h4>
                <AddPage idStore={this.state.idStore} idCatalog={this.state.idCatalog}/>


            </div>)
        } else {
            return (<div>

                <div id="right_dark_space"></div>
                <div style={{width: 'calc(100% - 100px)'}} id="right_space">
                    <div className="preloader d-flex justify-content-center">
                        <div className="align-middle spinner-grow text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>

            </div>)
        }


    }
}

// @ts-ignore
export default Page;