// @ts-ignore
import React, {Component} from "react";
// import Menu from "../menu/Menu";
// import Cookies from "universal-cookie";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore

// @ts-ignore
import Cookies from "universal-cookie";
import axios from "axios";

export class ShowPage extends Component <any, any> {
    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: true,
            page: [],
            cookies: cookies.get('bearer'),
            idStore: this.props.idStore,
            idCatalog: this.props.idCatalog,

        };


    }


    componentDidMount() {

        const data = {store_id: this.state.idStore, catalog_id: this.state.idCatalog};
        axios({

            url: 'https://api.cliclishop.ru/lk/catalog/page/all',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            console.log(result.data)
            this.setState(
                {
                    page:result.data
                });
        })

    }
    delete(id){
        const data = {store_id: this.state.idStore, catalog_id: this.state.idCatalog,shop_id:id};
        axios({

            url: 'https://api.cliclishop.ru/lk/catalog/shop/delete',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.componentDidMount()
            this.setState(
                {

                });
        })
    }


    render() {
        const {page, isLoaded, idStore, idCatalog} = this.state;
        if (isLoaded) {
            return (<div>
                {page.map(item => (<div className='row'>
                    <div className='col-md-12'>
                        <h6>{item.name}</h6>
                        <p>{item.content}  </p>

                    </div>

<hr className='w-100'/>
                </div>))}


            </div>)
        } else {
            return (<div>


                <div className="preloader d-flex justify-content-center">
                    <div className="align-middle spinner-grow text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>


            </div>)
        }


    }
}

// @ts-ignore
export default ShowPage;