import React, {Component} from "react";
import Menu from "../menu/Menu";
import Cookies from "universal-cookie";
import axios from "axios";
import Notification from "../Notification";


export class HomeSite extends Component <any, any> {

    constructor(props: any) {

        super(props);
        const cookies = new Cookies();
        this.state = {
            info: [],
            logging: [],
            isLoadedLogging: false,
            isLoadedOrder: false,
            isLoadedInfo: false,
            activeSite: [],
            fullSite: [],


        };
        console.log(JSON.parse(localStorage.getItem('activeSite') as string))
        this.activeSiteChange = this.activeSiteChange.bind(this);
        this.handleChangeSite = this.handleChangeSite.bind(this);

    }

    handleChangeSite(event) {

        const {fullSite} = this.state;
        console.log(fullSite)

        function b(idToSearch) {
            return fullSite.filter(item => {
                console.log(idToSearch)
                return item.id == idToSearch
            })
        };
        let activeSiteAlias = b(event.target.value)[0].alias
        console.log(activeSiteAlias)
        localStorage.setItem('activeSite', JSON.stringify({
            activeSiteId: event.target.value,
            activeSiteAlias: activeSiteAlias
        }))
        this.setState(
            {
                activeSite: {
                    activeSiteId: event.target.value,
                    activeSiteAlias: activeSiteAlias
                },

            });
    }

    activeSiteChange() {

        axios('https://site.cliclishop.ru/admin/sites/all', {

            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            console.log(result.data)
            console.log(localStorage.getItem('activeSite'))


            if (result.data.length > 0) {
                if (!localStorage.getItem('activeSite')) {
                    localStorage.setItem('activeSite', JSON.stringify({
                        activeSiteId: result.data[0].id,
                        activeSiteHost: result.data[0].host
                    }))
                }

                localStorage.setItem('FullSite', JSON.stringify({fullSite: result.data}))
                this.setState(
                    {
                        activeSite: JSON.parse(localStorage.getItem('activeSite') as string),
                        fullSite: result.data,
                        isLoadedInfo: true,

                    });
            }


        })
            .catch(function (error) {
                console.log(error)

            })
    }

    componentDidMount() {


        this.activeSiteChange();


    }

    history() {
        const {logging, isLoadedLogging} = this.state;
        if (isLoadedLogging) {
            return (
                logging.map(items => (<tr>
                    <td>{items.date}</td>
                    <td>{items.action}</td>
                </tr>))
            )
        } else {
            return ('Загрузка...')

        }

    }


    render() {
        const {info, logging, isLoaded, order, categories, Breadcrumbs1ID, Breadcrumbs2ID, fullSite} = this.state;


        return (<div>
                <Menu/>


                <div id="right_dark_space"></div>

                <div style={window.screen.width > 650 ? {width: 'calc(100% - 100px)'} : {width: '100%'}}
                     id="right_space">

                    <div className="text-center">
                        <h1 className="text-center"
                            style={{margin: '40px 0 20px 0', display: 'inline-block'}}> Информация о сайте:
                        </h1>
                    </div>

                    <div className="row margin_padding_0" style={{boxSizing: 'border-box'}}>
                    <div className="col-12 col-md-4 col-lg-4 col-xl-4 margin_padding_0">

                        <div className="shadow-card" style={{marginTop: '20px', height: 'auto'}}>
                            <div style={{padding: '20px'}}>
                                <h4 className="text-center">Страницы сайта</h4>
                                <a href={"/site/pagesAll/"+this.props.idSite}>
                                    <div
                                        className="btn btn-primary btn-user btn-block mt-5">
                                        Перейти
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-4 col-xl-4 margin_padding_0">

                        <div className="shadow-card" style={{marginTop: '20px', height: 'auto'}}>
                            <div style={{padding: '20px'}}>
                                <h4 className="text-center">Настройки сайта</h4>
                                <a href={"/site/settings/"+this.props.idSite}>
                                    <div
                                        className="btn btn-primary btn-user btn-block mt-5">
                                        Перейти
                                    </div>
                                </a>

                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-4 col-xl-4 margin_padding_0">

                        <div className="shadow-card" style={{marginTop: '20px', height: 'auto'}}>
                            <div style={{padding: '20px'}}>
                                <h4 className="text-center">Статистика сайта</h4>

                                <a href={"/site/stat/"+this.props.idSite}>
                                    <div
                                        className="btn btn-primary btn-user btn-block mt-5">
                                        Перейти
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            </div>


        )


    }
}

// @ts-ignore
export default HomeSite;