// @ts-ignore
import React, {Component} from "react";
// import Menu from "../menu/Menu";
// import Cookies from "universal-cookie";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore

// @ts-ignore
import Cookies from "universal-cookie";
import ShowShop from "./shop/ShowShop";
import AddShop from "./shop/AddShop";
import axios from "axios";

export class Shop extends Component <any, any> {
    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: true,
            catalog: [],
            cookies: cookies.get('bearer'),
            idStore: this.props.idStore,
            idCatalog: this.props.idCatalog,
            search: null,
            productActive: [],

        };


    }




    componentDidMount() {

        const data = {store_id: this.state.idStore, catalog_id: this.state.idCatalog};
        axios({

            url: 'https://api.cliclishop.ru/lk/catalog/edit',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            console.log(result.data)
            this.setState(
                {
                    catalog:result.data
                });
        })

    }




    render() {
        const {productsActive, isLoaded, idStore, idCatalog} = this.state;
        if (isLoaded) {
            return (<div>

                    <h4 className="text-center" style={{margin:'0 auto 20px auto'}}>Точки продаж</h4>
                <ShowShop idStore={this.state.idStore} idCatalog={this.state.idCatalog}/>
                <h4 className="text-center" style={{margin:'0 auto 20px auto'}}>Добавить точку продаж</h4>
                <AddShop idStore={this.state.idStore} idCatalog={this.state.idCatalog}/>




            </div>)
        } else {
            return (<div>

                <div id="right_dark_space"></div>
                <div style={window.screen.width>650?{width: 'calc(100% - 100px)'}:{width: '100%'}} id="right_space">
                    <div className="preloader d-flex justify-content-center">
                        <div className="align-middle spinner-grow text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>

            </div>)
        }


    }
}

// @ts-ignore
export default Shop;