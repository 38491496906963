// @ts-ignore
import React, {Component} from "react";
import Menu from "../menu/Menu";
import Cookies from "universal-cookie";
import axios from "axios";
import Notification from "../Notification";

export class AddSite extends Component <any, any> {
    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: true,
            store: [],
            notification: [],
            name: '',
            hash: '',
            alias: '',
            theme: '',


        };
        this.changeName = this.changeName.bind(this);
        this.changeAlias = this.changeAlias.bind(this);
        this.changeHash = this.changeHash.bind(this);
        this.changeTheme = this.changeTheme.bind(this);


    }

    changeName(event) {
        this.setState({name: event.target.value});
    }

    changeAlias(event) {
        this.setState({alias: event.target.value});
    }
    changeHash(event) {
        this.setState({hash: event.target.value});
    }
    changeTheme(event) {
        this.setState({theme: event.target.value});
    }

    updateData = (value) => {
        var filteredList = this.state.notification.filter(function (item) {
            return item.text.toLowerCase().search(value.toLowerCase()) == -1;
        });
        this.setState({notification: filteredList});
    }

    notification(text) {
        const data = this.state.notification
        const element = {text: text, time: 25};
        const newArray = [...data, element];
        this.setState(
            {
                notification: newArray,

            });
    }

    componentDidMount() {


    }

    addSiteApi() {

        const data = {
            host: this.state.name,
            alias: this.state.alias,
            hash: this.state.hash,
            theme: this.state.theme,
        };
        axios({

            url: 'https://site.cliclishop.ru/admin/site/add',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            if (result.data.error == 1) {
                this.notification('Превышен лимит на количество сайтов.')
            } else {
                window.location.assign('/site/info/' + result.data.idSite );
            }

        })
    }

    render() {
        const {store, isLoaded} = this.state;
        if (isLoaded) {
            return (<div>
                <Menu/>
                <div id="right_dark_space"></div>
                <div style={window.screen.width > 650 ? {width: 'calc(100% - 100px)'} : {width: '100%'}}
                     id="right_space">
                    <Notification updateData={this.updateData} NotificationArray={this.state.notification}/>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Главная</a></li>
                            <li className="breadcrumb-item"><a href="/site">Сайт</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Добавить сайт</li>
                        </ol>
                    </nav>

                    <div className="shadow-card col-sm-3" style={{margin: '0 auto'}}>

                        <form><br/>

                            <div className="form-group">
                                <h3 className="mb-4 text-center ">Создание сайта</h3>
                                <input onChange={this.changeName} value={this.state.name} type="text"
                                       className=" form-control" id="exampleFormControlInput1"
                                       placeholder="Домен сайта"/>

                            </div>
                            <div className="form-group">
                                <input onChange={this.changeAlias} value={this.state.alias} type="text"
                                       className=" form-control" id="exampleFormControlInput1"
                                       placeholder="Алиас сайта"/>
                            </div>
                            <div className="form-group">
                                <input onChange={this.changeHash} value={this.state.hash} type="text"
                                       className=" form-control" id="exampleFormControlInput1"
                                       placeholder="hash каталога"/>
                            </div>
                            <div className="form-group">
                                <input onChange={this.changeTheme} value={this.state.theme} type="text"
                                       className=" form-control" id="exampleFormControlInput1"
                                       placeholder="Алиас сайта"/>
                            </div>



                        </form>

                        <div onClick={() => this.addSiteApi()} style={{marginBottom: '30px'}} className="text-center">
                            <div className="middle_button text-center">
                                <a style={{color: "white", textDecoration: "none"}}>Создать</a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>)
        } else {
            return (<div>
                <Menu/>
                <div id="right_dark_space"></div>
                <div style={window.screen.width > 650 ? {width: 'calc(100% - 100px)'} : {width: '100%'}}
                     id="right_space">

                    <div className="preloader d-flex justify-content-center">
                        <div className="align-middle spinner-grow text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>

            </div>)
        }


    }
}

// @ts-ignore
export default AddSite;