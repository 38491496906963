// @ts-ignore
import React, {Component} from "react";
// import Menu from "../menu/Menu";
// import Cookies from "universal-cookie";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore

// @ts-ignore
import Cookies from "universal-cookie";
import axios from "axios";

export class AddShop extends Component <any, any> {
    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: true,
            shop: [],
            cookies: cookies.get('bearer'),
            idStore: this.props.idStore,
            idCatalog: this.props.idCatalog,
            status: false,
            name: '',
            address: '',
            time: '',
            phone: '',
            coordinates1: '',
            coordinates2: '',

        };

        this.changeName = this.changeName.bind(this);
        this.changeAddress = this.changeAddress.bind(this);
        this.changeTime = this.changeTime.bind(this);
        this.changePhone = this.changePhone.bind(this);
        this.changeCoordinates1 = this.changeCoordinates1.bind(this);
        this.changeCoordinates2 = this.changeCoordinates2.bind(this);
    }


    changeName(event) {
        this.setState({name: event.target.value});
    }

    changeAddress(event) {
        this.setState({address: event.target.value});
    }

    changeTime(event) {
        this.setState({time: event.target.value});
    }

    changePhone(event) {
        this.setState({phone: event.target.value});
    }

    changeCoordinates1(event) {
        this.setState({coordinates1: event.target.value});
    }

    changeCoordinates2(event) {
        this.setState({coordinates2: event.target.value});
    }

    componentDidMount() {


    }

    send() {
        const data = {
            store_id: this.state.idStore,
            catalog_id: this.state.idCatalog,
            name: this.state.name,
            time: this.state.time,
            address: this.state.address,
            coordinates1: this.state.coordinates1,
            coordinates2: this.state.coordinates2,
            phone: this.state.phone,
        };
        axios({

            url: 'https://api.cliclishop.ru/lk/catalog/shop/add',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            console.log(result.data)
            this.setState(
                {

                });
        })
    }


    render() {
        const {shop, isLoaded, idStore, idCatalog} = this.state;
        if (isLoaded) {
            return (<div>
                <form><br/>

                    <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">Название магазина</label>
                        <input onChange={this.changeName} value={this.state.name} type="text" className="form-control"
                               id="exampleFormControlInput1"
                               placeholder="Название магазина"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">Адрес</label>
                        <input onChange={this.changeAddress} value={this.state.address} type="text"
                               className="form-control" id="exampleFormControlInput1"
                               placeholder="Адрес"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">Время работы</label>
                        <input onChange={this.changeTime} value={this.state.time} type="text" className="form-control"
                               id="exampleFormControlInput1"
                               placeholder="С 8:00 до 18:00"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">Телефон</label>
                        <input onChange={this.changePhone} value={this.state.phone} type="text" className="form-control"
                               id="exampleFormControlInput1"
                               placeholder="Телефон"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">Координата Широта</label>
                        <input onChange={this.changeCoordinates1} value={this.state.coordinates1} type="text"
                               className="form-control" id="exampleFormControlInput1"
                               placeholder="Координата 1"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">Координата Долгота</label>
                        <input onChange={this.changeCoordinates2} value={this.state.coordinates2} type="text"
                               className="form-control" id="exampleFormControlInput1"
                               placeholder="Координата 2"/>
                    </div>


                </form>

                <div onClick={() => this.send()} style={{marginBottom: '30px'}} className="text-center">
                    <div className="middle_button text-center">
                        <a style={{color: "white", textDecoration: "none"}}>Добавить</a>
                    </div>

                </div>


            </div>)
        } else {
            return (<div>


                <div className="preloader d-flex justify-content-center">
                    <div className="align-middle spinner-grow text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>


            </div>)
        }


    }
}

// @ts-ignore
export default AddShop;