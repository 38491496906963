// @ts-ignore
import React, {Component} from 'react';
import Menu from "../menu/Menu";
// import Cookies from 'js-cookie';
import Cookies from 'universal-cookie';
import axios from "axios";



export class Home extends Component <any, any> {

    constructor(props: any) {

        super(props);
        const cookies = new Cookies();
        this.state = {
            info: [],
            logging: [],
            order: [],
            isLoadedLogging: false,
            isLoadedOrder: false,
            isLoadedInfo: false,
            balance: 0,

            cookies: cookies.get('bearer'),


        };


    }

    componentDidMount() {
        axios('https://api.cliclishop.ru/lk/profile', {

            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.setState(
                {
                    info: result.data,
                    isLoadedInfo: true,
                });
        })
            .catch(function (error) {


            })

        const dataLogging = {limit: '5'};
        axios.defaults.withCredentials = true;
        axios({
            url: 'https://api.cliclishop.ru/lk/logging/show/user',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            withCredentials: true,
            method: "POST",
            data: dataLogging,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.setState(
                {
                    logging: result.data,
                    isLoadedLogging: true,
                });
        })

        axios.defaults.withCredentials = true;
        axios({
            url: 'https://api.cliclishop.ru/lk/balance/show',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            withCredentials: true,
            method: "POST",


            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.setState(
                {
                    balance: result.data.balance,
                });
        })
        const dataOrder = {limit: '5'};
        axios({

            url: 'https://api.cliclishop.ru/lk/orders/category/showOrder/default',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            withCredentials: true,
            method: "POST",
            data: dataOrder,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            console.log(result.data)
            this.setState(
                {
                    order: result.data,
                    isLoadedOrder: true,
                });
        }).catch(
            error=>{

                console.log(error)
            }
        )


    }

    history() {
        const {logging, isLoadedLogging} = this.state;
        if (isLoadedLogging) {
            return (
                logging.map(items => (<tr>
                    <td>{items.date}</td>
                    <td>{items.action}</td>
                </tr>))
            )
        } else {
            return ('Загрузка...')

        }

    }

    balance() {
        const {info, isLoadedInfo} = this.state;
        if (isLoadedInfo) {

            return (
                <div style={{padding: '20px'}}>

                    <h3>Баланс: {this.state.balance}  &#x20bd;</h3>


                    <p style={{marginBottom: '5px'}}>
                        Дней до блокировки:
                        {(this.state.balance / info.servicesUser.price).toFixed(0)}

                    </p>
                    <p style={{marginBottom: '5px'}}>Суммарная
                        стоимость: {info.servicesUser.price} р/день</p>
                    {/*<p>ID: {info.idUser} </p>*/}

                    <div className="text-center">
                        <a href="/finance">
                            <div className="middle_button text-center">
                                Пополнить баланс
                            </div>
                        </a>
                    </div>
                </div>
            )
        } else {
            return (<div className="preloader d-flex justify-content-center">
                <div className="align-middle spinner-grow text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>)

        }

    }
    order(){
        const {order, isLoadedOrder} = this.state;

        if (isLoadedOrder) {
            // console.log()
            if(order.length>0){
                return (
                    <div style={{padding: '20px'}}>
                        <h4 className="text-center">Заказы</h4>
                        <table className="table table-hover table-responsive-xl">
                            <thead>
                            <tr>
                                <th scope="col">Номер</th>
                                <th scope="col">Каталог</th>
                                <th scope="col">Дата</th>
                                {/*<th scope="col">Количество</th>*/}
                                <th scope="col">Сумма</th>
                                <th scope="col"></th>
                            </tr>
                            </thead>
                            <tbody>

                            {order.map(item => (<tr>
                                <th scope="row">{item.id}</th>
                                <td>{item.store}}</td>
                                <td>{item.date}</td>
                                {/*<td>{item.date}</td>*/}
                                <td>{item.fullprice}</td>
                                <td><a href={'/order/' + item.id}>Перейти</a></td>
                            </tr>))}

                            </tbody>
                        </table>


                        <div className="text-center"><a href="/orders">
                            <div className="middle_button text-center">
                                Все заказы!
                            </div>
                        </a>
                        </div>
                    </div>
                )
            }
            else {
                return (
                <div style={{padding: '20px'}}>
                    <h4 className="text-center">Заказов нет</h4>

                </div>)
            }

        } else {
            return (<div className="preloader d-flex justify-content-center">
                <div className="align-middle spinner-grow text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>)

        }

    }
    progress() {
        const {info, isLoadedInfo} = this.state;

        if (isLoadedInfo) {
            if(info.servicesUser!=null){
                return (

                    <div style={{padding: '20px'}}>
                        <p style={{marginBottom: '5px'}}>Занято памяти: {info.memoryImage} МБ
                            из {info.servicesUser.settingsService.maxImage} МБ</p>
                        <div className="progress">
                            <div className="progress-bar progress-bar-striped bg-warning"
                                 role="progressbar"
                                 style={{width: info.memoryImage * 100 / info.servicesUser.settingsService.maxImage+'%'}}
                                 aria-valuenow={info.memoryImage} aria-valuemin={0}
                                 aria-valuemax={info.servicesUser.settingsService.maxImage}></div>
                        </div>
                        <p style={{marginBottom: '5px'}}>Количество магазинов: {info.quantityStore}
                            из {info.servicesUser.settingsService.maxStore}</p>
                        <div className="progress">
                            <div className="progress-bar progress-bar-striped bg-danger"
                                 role="progressbar"
                                 style={{width: info.quantityStore * 100 / info.servicesUser.settingsService.maxStore+'%'}}
                                 aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></div>
                        </div>
                        <p style={{marginBottom: '5px'}}>Количество каталогов: {info.quantityCatalog}
                            из {info.servicesUser.settingsService.maxCatalog}</p>
                        <div className="progress">
                            <div className="progress-bar progress-bar-striped bg-success"
                                 role="progressbar"
                                 style={{width: info.quantityCatalog * 100 / info.servicesUser.settingsService.maxCatalog+'%'}}
                                 aria-valuenow={20} aria-valuemin={0} aria-valuemax={100}></div>
                        </div>
                        <p style={{marginBottom: '5px'}}>Количество товаров: {info.quantityProduct}
                            из {info.servicesUser.settingsService.maxProduct}</p>
                        <div className="progress">
                            <div className="progress-bar progress-bar-striped" role="progressbar"
                                 style={{width: info.quantityProduct * 100 / info.servicesUser.settingsService.maxProduct+'%'}}
                                 aria-valuenow={10} aria-valuemin={0} aria-valuemax={100}></div>
                        </div>
                        <div className="text-center">
                            <a href="/services">
                                <div className="middle_button text-center">
                                    Изменить тариф
                                </div>
                            </a>
                        </div>

                    </div>

                )
            }

        } else {
            return (<div className="preloader d-flex justify-content-center">
                <div className="align-middle spinner-grow text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>)

        }

    }

    render() {
        const {info, logging, isLoaded, order, categories, Breadcrumbs1ID, Breadcrumbs2ID, sort} = this.state;
        console.log(info)

            return (<div>
                    <Menu/>

                    <div id="right_dark_space"></div>

                    <div style={window.screen.width>650?{width: 'calc(100% - 100px)'}:{width: '100%'}} id="right_space">
                        <div className="text-center">
                            <h1 className="text-center"
                                style={{margin: '40px 0 20px 0', display: 'inline-block'}}> CliCliShop
                                Главная</h1>
                        </div>

                        <div className="row margin_padding_0" style={{boxSizing: 'border-box'}}>
                            <div className="col-12 col-md-12 col-lg-12 col-xl-5 margin_padding_0" style={{height: '100%'}}>
                                {/*<div className="shadow-card" style={{marginTop: '0px', height: 'auto'}}>*/}
                                {/*    {this.balance()}*/}
                                {/*</div>*/}
                                {/*<div className="shadow-card" style={{marginTop: '20px', height: 'auto'}}>*/}

                                {/*    {this.progress()}*/}
                                {/*</div>*/}

                                <div className="shadow-card" style={{marginTop: '20px', height: 'auto'}}>
                                    <div style={{padding: '20px'}}>
                                        <h4 className="text-center">История действий</h4>
                                        <table className="table table-hover">
                                            <thead>
                                            <tr>
                                                <th scope="col">Дата</th>
                                                <th scope="col">Действие</th>

                                            </tr>
                                            </thead>
                                            <tbody>

                                            {this.history()}


                                            </tbody>
                                        </table>
                                        <div className="text-center">
                                            <a href="/history/main">
                                                <div className="middle_button text-center">
                                                    Вся история
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-12 col-md-12 col-lg-12 col-xl-7 margin_padding_0">
                                <div className="shadow-card" style={{marginTop: '20px', height: 'auto'}}>
                                    {this.order()}
                                </div>
                                <div className="shadow-card" style={{marginTop: '20px', height: 'auto'}}>
                                    <div style={{padding: '20px'}}>
                                        <h4 className="text-center">Отзывы</h4>
                                        <p className="text-center">У вас пока нет отзывов</p>
                                        <div className="text-center">
                                            <div className="middle_button text-center">
                                                Все отзывы
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            )




    }
}

// @ts-ignore
export default Home;