// @ts-ignore
import React, {Component} from "react";
// import Menu from "../menu/Menu";
// import Cookies from "universal-cookie";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore
import Menu from "../menu/Menu";
// @ts-ignore
import Cookies from "universal-cookie";
import axios from "axios";
import Notification from "../Notification";

export class Info extends Component <any, any> {
    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: true,
            idProduct: this.props.idProduct,
            product: [],
            category: [],
            categoryActive: [],
            image: [],
            notification: [],
            cookies: cookies.get('bearer'),
            idStore: this.props.idStore,
            idCatalog: this.props.idCatalog,
            name: '',
            price: '',
            oldPrice: '',
            content: '',
            status: '',
            vendorCode: '',
            code: '',
            availability: '',
            categoryId: '0',
            OldPrice: '0',
            OldPrice2: '0',
            unit: '',
            ValueAttr: '',
            NameAttr: '',
            ValueAttrChange: '',
            NameAttrChange: '',
            AttrProductActivity: '',
            arrayAttr: [],


        };


        this.changeStatus = this.changeStatus.bind(this);
        this.changeCode = this.changeCode.bind(this);
        this.changeVendorCode = this.changeVendorCode.bind(this);
        this.changeContent = this.changeContent.bind(this);
        this.changePrice = this.changePrice.bind(this);
        this.changeOldPrice = this.changeOldPrice.bind(this);
        this.changeAvailability = this.changeAvailability.bind(this);
        this.changeName = this.changeName.bind(this);
        this.changeOldPrice = this.changeOldPrice.bind(this);
        this.changeCategory = this.changeCategory.bind(this);
        this.changeUnit = this.changeUnit.bind(this);
        this.changeNameAttr = this.changeNameAttr.bind(this);
        this.changeValueAttr = this.changeValueAttr.bind(this);
        this.changeNameAttr2 = this.changeNameAttr2.bind(this);
        this.changeValueAttr2 = this.changeValueAttr2.bind(this);
        this.changeOldPrice2 = this.changeOldPrice2.bind(this);
        this.changeOldPrice = this.changeOldPrice.bind(this);
        this.changeAttrProductActivity = this.changeAttrProductActivity.bind(this);
    }

    updateData = (value) => {
        var filteredList = this.state.notification.filter(function (item) {
            return item.text.toLowerCase().search(value.toLowerCase()) == -1;
        });
        this.setState({notification: filteredList});
    }

    notification(text) {
        const data = this.state.notification
        const element = {text: text, time: 25};
        const newArray = [...data, element];
        this.setState(
            {
                notification: newArray,

            });
    }

    changeStatus(event) {

        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({status: value});
    }

    changeCode(event) {
        this.setState({code: event.target.value});
    }

    changeVendorCode(event) {
        this.setState({vendorCode: event.target.value});
    }

    changeContent(event) {
        this.setState({content: event.target.value});
    }

    changePrice(event) {
        this.setState({price: event.target.value});
    }

    changeOldPrice(event) {
        this.setState({OldPrice: event.target.value});
    }



    changeAvailability(event) {
        this.setState({availability: event.target.value});
    }

    changeUnit(event) {
        this.setState({unit: event.target.value});
    }

    changeName(event) {
        this.setState({name: event.target.value});
    }

    changeNameAttr(event) {
        this.setState({NameAttr: event.target.value});
    }

    changeValueAttr(event) {
        this.setState({ValueAttr: event.target.value});
    }
    // changeOldPrice(event) {
    //     this.setState({OldPrice: event.target.value});
    // }
    changeNameAttr2(event) {
        this.setState({NameAttrChange: event.target.value});
    }

    changeValueAttr2(event) {
        this.setState({ValueAttrChange: event.target.value});
    }
    changeOldPrice2(event) {
        this.setState({OldPrice2: event.target.value});
    }

    changeCategory(event) {
        this.setState({categoryId: event.target.value});
    }

    componentDidMount() {
        if (this.state.idProduct) {
            console.log('090')
            const data = {
                store_id: this.props.idStore,
                catalog_id: this.props.idCatalog,
                product_id: this.props.idProduct
            };
            axios({

                url: 'https://api.cliclishop.ru/lk/product/item',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                withCredentials: true,
                method: "POST",
                data: data,

                // receive two    parameter endpoint url ,form data
            }).then(result => { // then print response status
                if (result.data.categories) {
                    this.setState(
                        {
                            categoryId: result.data.categories.id,
                        }
                    );
                }

                this.setState(
                    {
                        product: result.data,
                        name: result.data.name,
                        content: result.data.content,
                        price: result.data.price,
                        oldPrice: result.data.oldprice,
                        categoryActive: result.data.categories,
                        code: result.data.code,
                        unit: result.data.unit,
                        status: result.data.status,
                        vendorCode: result.data.vendorCode,
                        availability: result.data.availability,

                    }
                );
            })
            const dataCategory = {
                store_id: this.state.idStore,
                catalog_id: this.state.idCatalog,
                product_id: this.props.idProduct
            };
            axios({

                url: 'https://api.cliclishop.ru/lk/catalog/categories',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                withCredentials: true,
                method: "POST",
                data: dataCategory,

                // receive two    parameter endpoint url ,form data
            }).then(result => { // then print response status
                this.setState(
                    {
                        category: result.data,
                    });
            })
            this.showAttrProduct()
        } else {
            const data = {store_id: this.state.idStore, catalog_id: this.state.idCatalog};
            axios({

                url: 'https://api.cliclishop.ru/lk/product/add',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                withCredentials: true,
                method: "POST",
                data: data,

                // receive two    parameter endpoint url ,form data
            }).then(result => { // then print response status
                let url = '/store/' + this.state.idStore + '/catalog/' + this.state.catalog_id + '/product/' + result.data.id_product + '/edit'
                window.location.assign(url);

            })
        }


    }

    editInfoProduct() {
        const data = {
            store_id: this.props.idStore, catalog_id: this.props.idCatalog, product_id: this.props.idProduct,
            name_product: this.state.name,
            categories: this.state.categoryId,
            price: this.state.price,

            code: this.state.code,
            vendorcode: this.state.vendorCode,
            availability: this.state.availability,
            unit: this.state.unit,
            content: this.state.content,
            status: this.state.status,
            oldPrice: this.state.oldPrice,
        };
        axios({

            url: 'https://api.cliclishop.ru/lk/product/edit',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            if (result.data.text) {
                this.notification(result.data.text)
            }

        })

    }

    showAttrProduct() {
        const data = {
            store_id: this.props.idStore,
            catalog_id: this.props.idCatalog,
            product_id: this.props.idProduct,


        };
        axios({

            url: 'https://api.cliclishop.ru/lk/product/attr/show',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status

            this.setState(
                {

                    arrayAttr: result.data.attr,
                });

        })

    }

    addAttrProduct() {
        const data = {
            store_id: this.props.idStore,
            catalog_id: this.props.idCatalog,
            product_id: this.props.idProduct,
            title: this.state.NameAttr,
            value: this.state.ValueAttr,
            oldPrice: this.state.OldPrice,

        };
        axios({

            url: 'https://api.cliclishop.ru/lk/product/attr/add',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.showAttrProduct()
            if (result.data.text) {
                this.notification(result.data.text)
            }
        })

    }

    deleteAttrProduct(id) {
        const data = {
            store_id: this.props.idStore,
            catalog_id: this.props.idCatalog,
            product_id: this.props.idProduct,
            id: id,


        };
        axios({

            url: 'https://api.cliclishop.ru/lk/product/attr/delete',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.showAttrProduct()
            if (result.data.text) {
                this.notification(result.data.text)
            }
        })

    }

    changeAttrProduct() {
        const data = {
            store_id: this.props.idStore,
            catalog_id: this.props.idCatalog,
            product_id: this.props.idProduct,
            attr_id: this.state.AttrProductActivity,
            nameAttr: this.state.NameAttrChange,
            valueAttr: this.state.ValueAttrChange,
            oldPrice: this.state.OldPrice2,


        };
        axios({

            url: 'https://api.cliclishop.ru/lk/product/attr/change',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.showAttrProduct()
            if (result.data.text) {

                this.notification(result.data.text)
            }
            this.setState(
                {
                    NameAttrChange: '',
                    ValueAttrChange: '',
                    AttrProductActivity: '',
                });
        })

    }

    changeAttrProductActivity(items) {

        this.setState(
            {
                NameAttrChange: items.title,
                ValueAttrChange: items.value,
                OldPrice2: items.oldPrice,
                AttrProductActivity: items.id,
            });


    }

    renderAttr(items) {
        if(items.id == this.state.AttrProductActivity){

            return (
                <div className='row col-12'> <div className='col-12 col-sm-4'>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">Наименование варианта</label>
                        <input onChange={this.changeNameAttr2} value={this.state.NameAttrChange} type="text"
                               className="form-control" id="exampleFormControlInput1"
                               placeholder="Название варианта"/>
                    </div>
                </div>
                    <div className='col-12 col-sm-4'>
                        <div className="form-group">
                            <label htmlFor="exampleFormControlInput1">Стоимость</label>
                            <input onChange={this.changeValueAttr2} value={this.state.ValueAttrChange} type="text"
                                   className="form-control" id="exampleFormControlInput1"
                                   placeholder="Стоимость товара(Только цифры)"/>
                        </div>
                    </div>
                    <div className='col-12 col-sm-4'>
                        <div className="form-group">
                            <label htmlFor="exampleFormControlInput1">Старая цена</label>
                            <input onChange={this.changeOldPrice2} value={this.state.OldPrice2} type="text"
                                   className="form-control" id="exampleFormControlInput1"
                                   placeholder="Стоимость товара(Только цифры)"/>
                        </div>
                    </div>
                    <div className='col-12 col-sm-4'>
                        <div onClick={() => this.changeAttrProduct()} className="text-center">
                            <div className="middle_button text-center">
                                <a  style={{color: "white", textDecoration: "none"}}>Отправить изменения</a>
                            </div>

                        </div>
                    </div></div>
            )
        }
        else{
            return (
                <ol className="rounded">
                    <li>{items.title} - {items.value}
                        <span style={{color: 'red'}} onClick={() => this.deleteAttrProduct(items.id)}> Удалить </span>
                        <span style={{color: 'orange'}} onClick={() => this.changeAttrProductActivity(items)} > Изменить </span>
                    </li>

                </ol>
            )
        }


    }


    render() {
        const {product, category, isLoaded, idStore, idCatalog} = this.state;
        if (isLoaded) {
            return (<div>
                <Notification updateData={this.updateData} NotificationArray={this.state.notification}/>
                <form><br/>
                    <div className="form-group form-check">
                        <input checked={this.state.status}
                               onChange={this.changeStatus}
                               type="checkbox" className="form-check-input" id="exampleCheck1"/>
                        <label className="form-check-label" htmlFor="exampleCheck1">Статус товар(Актвен/ Не
                            активен)</label>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">Общее название товара</label>
                        <input onChange={this.changeName} value={this.state.name} type="text" className="form-control"
                               id="exampleFormControlInput1"
                               placeholder="Название товара"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlSelect1">Категория</label>
                        <select onChange={this.changeCategory}
                                value={this.state.categoryId ? this.state.categoryId : '0'} className="form-control"
                                id="exampleFormControlSelect1">
                            <option value='0'>Нет категории</option>
                            {category.map(items => (

                                <option value={items.id}>{items.name}</option>
                            ))}
                        </select>
                    </div>
                    <hr/>
                    <label htmlFor="exampleFormControlTextarea1">Варианты товара</label>
                    <div className='row col-12'>
                        <div className='col-12 col-sm-3'>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlInput1">Наименование варианта</label>
                                <input onChange={this.changeNameAttr} value={this.state.NameAttr} type="text"
                                       className="form-control" id="exampleFormControlInput1"
                                       placeholder="Название варианта"/>
                            </div>
                        </div>
                        <div className='col-12 col-sm-3'>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlInput1">Стоимость</label>
                                <input onChange={this.changeValueAttr} value={this.state.ValueAttr} type="text"
                                       className="form-control" id="exampleFormControlInput1"
                                       placeholder="Стоимость товара(Только цифры)"/>
                            </div>
                        </div>
                        <div className='col-12 col-sm-3'>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlInput1">Старая стоимость</label>
                                <input onChange={this.changeOldPrice} value={this.state.OldPrice} type="text"
                                       className="form-control" id="exampleFormControlInput1"
                                       placeholder="Только цифры"/>
                            </div>
                        </div>
                        <div className='col-12 col-sm-3'>
                            <div onClick={() => this.addAttrProduct()} className="text-center">
                                <div className="middle_button text-center">
                                    <a style={{color: "white", textDecoration: "none"}}>Добавить вариант товара</a>
                                </div>

                            </div>
                        </div>


                    </div>
                    <hr/>
                    {this.state.arrayAttr.map(items => this.renderAttr(items)
                    )}

                    <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">Остаток на складе</label>
                        <input onChange={this.changeAvailability} value={this.state.availability} type="text"
                               className="form-control" id="exampleFormControlInput1"
                               placeholder="Количество"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">Еденица измерения</label>
                        <input onChange={this.changeUnit} value={this.state.unit} type="text" className="form-control"
                               id="exampleFormControlInput1"
                               placeholder="Пример:ШТ"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">Код товара</label>
                        <input onChange={this.changeCode} value={this.state.code} type="text" className="form-control"
                               id="exampleFormControlInput1"
                               placeholder="Код товара"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">Артикул товара</label>
                        <input onChange={this.changeVendorCode} value={this.state.vendorCode} type="text"
                               className="form-control" id="exampleFormControlInput1"
                               placeholder="Артикул товара"/>
                    </div>

                    <div className="form-group">
                        <label htmlFor="exampleFormControlTextarea1">Описание товара</label>
                        <textarea onChange={this.changeContent} className="form-control"
                                  id="exampleFormControlTextarea1" rows={12} value={this.state.content}></textarea>
                    </div>

                </form>

                <div onClick={() => this.editInfoProduct()} style={{marginBottom: '30px'}} className="text-center">
                    <div className="middle_button text-center">
                        <a style={{color: "white", textDecoration: "none"}}>Отправить изменения</a>
                    </div>

                </div>


            </div>)
        } else {
            return ('Загрузка...')
        }


    }
}

// @ts-ignore
export default Info;