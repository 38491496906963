// @ts-ignore
import React, {Component} from "react";
// import Menu from "../menu/Menu";
// import Cookies from "universal-cookie";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore

// @ts-ignore
import Cookies from "universal-cookie";
import ShowPage from "./page/ShowPage";
import AddPage from "./page/AddPage";
import axios from "axios";

export class SettingsInfoCatalog extends Component <any, any> {
    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: true,
            catalog: [],
            cookies: cookies.get('bearer'),
            idStore: this.props.idStore,
            idCatalog: this.props.idCatalog,
            city: '',
            time: '',
            phone: '',
            statusPhone: false,
            statusEmail: false,
            statusName: false,
            statusComment: false,
            statusSurName: false,
            LinkBasket: '',
            FullProductImg: '',
            productActive: [],

        };
        this.changeTime = this.changeTime.bind(this);
        this.changeStatusPhone = this.changeStatusPhone.bind(this);
        this.changeStatusEmail = this.changeStatusEmail.bind(this);
        this.changeStatusComment = this.changeStatusComment.bind(this);
        this.changeStatusName = this.changeStatusName.bind(this);
        this.changeStatusSurName = this.changeStatusSurName.bind(this);
        this.changeLinkBasket = this.changeLinkBasket.bind(this);
        this.changeCity = this.changeCity.bind(this);
        this.changePhone = this.changePhone.bind(this);

    }

    changeStatusName(event) {

        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value;
        console.log(value)
        this.setState({statusName: value});
    }

    changeStatusSurName(event) {

        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value;
        console.log(value)
        this.setState({statusSurName: value});
    }

    changeStatusComment(event) {

        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value;
        console.log(value)
        this.setState({statusComment: value});
    }

    changeStatusEmail(event) {

        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value;
        console.log(value)
        this.setState({statusEmail: value});
    }

    changeStatusPhone(event) {

        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value;
        console.log(value)
        this.setState({statusPhone: value});
    }


    changePhone(event) {
        this.setState({phone: event.target.value});
    }

    changeCity(event) {
        this.setState({city: event.target.value});
    }

    changeLinkBasket(event) {
        this.setState({LinkBasket: event.target.value});
    }

    changeTime(event) {
        this.setState({time: event.target.value});
    }

    componentDidMount() {

        const data = {store_id: this.state.idStore, catalog_id: this.state.idCatalog};
        axios({

            url: 'https://api.cliclishop.ru/lk/catalog/' + this.state.idCatalog + '/edit',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            console.log(result.data)
            this.setState(
                {
                    catalog: result.data,
                    statusName: result.data.setting.name,
                    statusSurName: result.data.setting.surname,
                    statusComment: result.data.setting.comment,
                    statusEmail: result.data.setting.email,
                    statusPhone: result.data.setting.phone,
                    FullProductImg: result.data.imgFullProduct,
                    LinkBasket: result.data.linkBasket,
                    phone: result.data.setting.phoneCompany,
                    time: result.data.setting.timeWorkCompany,
                    city: result.data.setting.city,
                });
        })

    }

    editCatalog() {
        const data = {
            storeId: this.props.idStore,
            catalogId: this.props.idCatalog,
            city: this.state.city,
            timeWork: this.state.time,
            phoneCompany: this.state.phone,
            statusPhone: this.state.statusPhone,
            statusName: this.state.statusName,
            statusSurname: this.state.statusSurName,
            statusEmail: this.state.statusEmail,
            statusComment: this.state.statusComment,
            linkBasket: this.state.LinkBasket,
        };
        axios({

            url: 'https://api.cliclishop.ru/lk/catalog/edit',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.componentDidMount()
        })

    }

    onChangeHandler = event => {
        var files = event.target.files
        if (this.maxSelectFile(event)) {
            // if return true allow to setState
            this.setState({
                selectedFile: files
            })
        }
    }
    onClickHandler = () => {

        for (var x = 0; x < this.state.selectedFile.length; x++) {
            const data = new FormData()
            console.log(this.state.selectedFile[x])
            data.append('file', this.state.selectedFile[x])
            console.log(data)
            axios.post('https://image.cliclishop.ru/image', data, {
                headers: {}
                // receive two    parameter endpoint url ,form data
            }).then(res => { // then print response status
                console.log(res.data)
                this.uploads(res.data.link, res.data.size)

            })
        }


    }
    maxSelectFile = (event) => {
        let files = event.target.files // create file object
        if (files.length > 1) {
            const msg = 'Only 3 images can be uploaded at a time'
            event.target.value = null // discard selected file
            console.log(msg)
            return false;

        }
        return true;

    }

    uploads(link, size) {

        const data = {
            store_id: this.state.idStore,
            catalog_id: this.state.idCatalog,
            product_id: this.props.idProduct,
            imageLink: link,
            alt: '',
            imageSize: size,
            prioritet: '2',

        };
        axios({

            url: 'https://api.cliclishop.ru/lk/catalog/image/add',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => {
            this.componentDidMount()
            this.setState(
                {});
        })
            .catch(error => console.log(error));

    }

    render() {
        const {productsActive, isLoaded, idStore, idCatalog} = this.state;
        if (isLoaded) {
            return (<div>

                <h4 className="text-center" style={{margin: '0 auto 20px auto'}}>Настройка информации в каталоге</h4>
                <form><br/>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">Город </label>
                        <input onChange={this.changeCity} value={this.state.city} type="text" className="form-control"
                               id="exampleFormControlInput1"
                               placeholder="г. Йошкар-Ола"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">Время работы</label>
                        <input onChange={this.changeTime} value={this.state.time} type="text" className="form-control"
                               id="exampleFormControlInput1"
                               placeholder="9:00-16:00"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">Номер телефона</label>
                        <input onChange={this.changePhone} value={this.state.phone} type="text" className="form-control"
                               id="exampleFormControlInput1"
                               placeholder="8-800-555-55-55"/>
                    </div>
                    <div className="form-group form-check">
                        <input checked={this.state.statusName}
                               onChange={this.changeStatusName}
                               type="checkbox" className="form-check-input" id="exampleCheck1"/>
                        <label className="form-check-label" htmlFor="exampleCheck1">Статус ИМЯ (Актвен/ Не
                            активен)</label>
                    </div>
                    <div className="form-group form-check">
                        <input checked={this.state.statusSurName}
                               onChange={this.changeStatusSurName}
                               type="checkbox" className="form-check-input" id="exampleCheck2"/>
                        <label className="form-check-label" htmlFor="exampleCheck2">Статус ФАМИЛИЯ (Актвен/ Не
                            активен)</label>
                    </div>
                    <div className="form-group form-check">
                        <input checked={this.state.statusComment}
                               onChange={this.changeStatusComment}
                               type="checkbox" className="form-check-input" id="exampleCheck3"/>
                        <label className="form-check-label" htmlFor="exampleCheck3">Статус КОММЕНТАРИЙ (Актвен/ Не
                            активен)</label>
                    </div>
                    <div className="form-group form-check">
                        <input checked={this.state.statusEmail}
                               onChange={this.changeStatusEmail}
                               type="checkbox" className="form-check-input" id="exampleCheck4"/>
                        <label className="form-check-label" htmlFor="exampleCheck4">Статус EMAIL (Актвен/ Не
                            активен)</label>
                    </div>
                    <div className="form-group form-check">
                        <input checked={this.state.statusPhone}
                               onChange={this.changeStatusPhone}
                               type="checkbox" className="form-check-input" id="exampleCheck5"/>
                        <label className="form-check-label" htmlFor="exampleCheck5">Статус ТЕЛЕФОН (Актвен/ Не
                            активен)</label>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">Путь к корзине </label>
                        <input onChange={this.changeLinkBasket} value={this.state.LinkBasket} type="text"
                               className="form-control" id="exampleFormControlInput1"
                               placeholder="/basket"/>
                    </div>


                </form>

                <div onClick={() => this.editCatalog()} style={{marginBottom: '30px'}} className="text-center">
                    <div className="middle_button text-center">
                        <a style={{color: "white", textDecoration: "none"}}>Отправить изменения</a>
                    </div>

                </div>
                <hr/>
                <h4 className="text-center" style={{margin: '0 auto 20px auto'}}>Настройка изображения категории "Все
                    товары"</h4>
                <br/>
                <div className='row'>
                    <div className='col-6'>
                        <form>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlFile1">Выберите изображение</label>

                                <input name="file" onChange={this.onChangeHandler} type="file"
                                       className="form-control-file" id="exampleFormControlFile1"/>
                            </div>
                        </form>
                        <button type="button" className="btn btn-success btn-block"
                                onClick={this.onClickHandler}>Загрузить
                        </button>
                    </div>
                    <div className='col-6'>
                        <div className="col-4">
                            <img style={{height: '250px', width: 'auto'}} className="image_from_goods_list"
                                 src={this.state.FullProductImg}/>
                        </div>
                    </div>
                </div>


            </div>)
        } else {
            return (<div>

                <div id="right_dark_space"></div>
                <div style={{width: 'calc(100% - 100px)'}} id="right_space">
                    <div className="preloader d-flex justify-content-center">
                        <div className="align-middle spinner-grow text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>

            </div>)
        }


    }
}

// @ts-ignore
export default SettingsInfoCatalog;