// @ts-ignore
import React, {Component} from "react";
import Menu from "../menu/Menu";
import Cookies from "universal-cookie";
import axios from "axios";

export class Store extends Component <any, any> {
    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: false,
            store: [],
            cookies: cookies.get('bearer'),
            size:window.screen.width

        };




    }
    componentDidMount() {
        axios({

            url: 'https://api.cliclishop.ru/lk/store',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.setState(
                {
                    store: result.data,
                    isLoaded: true,
                });
        })


    }



    render() {
        const {store, isLoaded,size} = this.state;
        if(isLoaded){
            return(<div>
                <Menu/>
                <div id="right_dark_space"></div>
                <div style={size>650?{width: 'calc(100% - 100px)'}:{width: '100%'}} id="right_space">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Главная</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Магазины</li>
                        </ol>
                    </nav>
                    <h1 className="text-center" style={{margin:'margin: 40px auto 20px auto'}}>Все магазины</h1>
                    <div className="text-center">
                        <div className="middle_button text-center">
                            <a href="/store/add" style={{color: "white", textDecoration: "none"}}>Добавить новый магазин</a>
                        </div>
                    </div>
                    <div className="row" style={{margin: "20px 0", padding: "0"}}>
                        <div className="col-12">
                            <div className="shadow-card"  style={size>650?{paddingRight:'40px', paddingLeft:'40px'}:{}}>

                                <table className="table table-hover">
                                    <thead style={{borderTop:'none'}}>
                                    <tr>
                                        <th scope="col" style={{borderTop:'none'}}>Название</th>
                                        <th scope="col" style={{borderTop:'none'}}></th>
                                        <th scope="col" style={{borderTop:'none'}}></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {store.map(items => (<tr>

                                        <td>{items.name}</td>
                                        <td>
                                            <a href={"/store/"+items.id+"/catalog"}>Перейти </a>&nbsp;&nbsp;&nbsp;
                                            <br/>

                                            <a href={"/store/"+items.id+"/edit"} style={{color:'red'}}>Изменить </a>
                                        </td>
                                    </tr>))}



                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>)
        }
        else {
            return (<div>
                <Menu/>
                <div id="right_dark_space"></div>
                <div style={{width: 'calc(100% - 100px)'}} id="right_space">

                    <div className="preloader d-flex justify-content-center">
                        <div className="align-middle spinner-grow text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>

            </div>)
        }



    }
}

// @ts-ignore
export default Store;