// @ts-ignore
import React, {Component} from "react";
// import Menu from "../menu/Menu";
// import Cookies from "universal-cookie";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore
import Menu from "../menu/Menu";
// @ts-ignore
import Cookies from "universal-cookie";

import {Toast} from "react-bootstrap";
import axios from "axios";

export class Orders extends Component <any, any> {
    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: true,
            productId: '',
            orders: [],
            cookies: cookies.get('bearer'),
            idStore: this.props.idStore,
            idCatalog: this.props.idCatalog,


        };
        this.uploadBasket = this.uploadBasket.bind(this);
        // this.ActivateMenu = this.ActivateMenu.bind(this);
    }
    timer(){
        setTimeout(this.uploadBasket, 10000);
    }
    uploadBasket(){
        const data = {limit: '10000'};
        axios({

            url: 'https://api.cliclishop.ru/lk/orders',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            console.log(result.data)
            this.setState(
                {
                    orders: result.data,
                    isLoaded: true,
                });
        })
            .catch(error => {
                console.log(error)
            })
    }
    componentDidMount() {
        this.uploadBasket()
        this.timer()


    }


    render() {
        const {product, isLoaded, idStore, idCatalog, orders, activeCategory, activePhoto, activeSpecifications, activeCustomization} = this.state;
        var dynamicWidth = 'calc(25% *' + orders.length + ')';
        if (isLoaded) {
            return (<div >
                <Menu/>

                <div id="right_dark_space"></div>
                <div style={window.screen.width>650?{width: 'calc(100% - 100px)'}:{width: '100%'}} id="right_space">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Главная</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Заказы</li>
                        </ol>
                        <a href='/orders/settings' style={{float:'right'}} className="middle_button text-center">
                            Настройки
                        </a>
                    </nav>

                    <div className="text-center"><h1 className="top_header">Заказы</h1></div>
                    <div style={{width: dynamicWidth}} className="row ">
                        {orders.map(items => (
                            <div style={{}} className="col-3 col-md-3">
                                <h5 className="text-center order_columns_title">{items.name}</h5>
                                <div className="row row-cols-1">
                                    {items.orders.map(items => (
                                        <div className="col-12 ">
                                            <div className="shadow-card" style={{width: '100%',}}>
                                                <div style={{padding:'5px'}} className="card-body">
                                                    <h6 className="">id: #{items.id}</h6>
                                                    <h6 className="">Дата: {items.dateOrder}</h6>
                                                    <h6 className="">Фамилия:
                                                        {items.surname ? " " + items.surname : " Не указана"}
                                                    </h6>
                                                    <h6 className="">Имя: {items.name}</h6>
                                                    {/*<h6 className="">Тип: {items.type}</h6>*/}
                                                    <h6 className="">Телефон: {items.phone}</h6>
                                                    <h6 className="">Итого без доставки: {items.fullprice}</h6>
                                                    {/*<p className="card-text">Some quick example text to build on the card title*/}
                                                    {/*    and make up the bulk of the card's content.</p>*/}
                                                    <div className="text-center">
                                                        <a href={"/order/" + items.id}>
                                                            <div className="middle_button text-center">
                                                                Подробнее
                                                            </div>
                                                        </a>
                                                    </div>
                                                    {/*<a style={{margin:'0 auto',display:'block'}} href={"/order/"+items.id} className="card-link text-center">Подробнее</a>*/}
                                                    {/*<div className="triangle-left"></div>*/}
                                                    {/*<div className="triangle-right"></div>*/}
                                                </div>
                                            </div>
                                        </div>

                                    ))

                                    }


                                </div>
                            </div>
                        ))}


                    </div>

                </div>
            </div>)
        } else {
            return ('Загрузка...')
        }


    }
}

// @ts-ignore
export default Orders;