// @ts-ignore
import React, {Component} from "react";
// import Menu from "../menu/Menu";
// import Cookies from "universal-cookie";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore
import Menu from "../menu/Menu";
// @ts-ignore
import Cookies from "universal-cookie";
import axios from "axios";

export class SettingsCatalog extends Component <any, any> {
    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: true,
            catalog: [],
            cookies: cookies.get('bearer'),
            idStore: this.props.idStore,
            idCatalog: this.props.idCatalog,
            search: null,
            productActive: [],

        };


    }




    componentDidMount() {

        const data = {store_id: this.state.idStore, catalog_id: this.state.idCatalog};
        axios({

            url: 'https://api.cliclishop.ru/lk/catalog/'+this.state.idCatalog+'/edit',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            console.log(result.data)
            this.setState(
                {
                    catalog:result.data
                });
        })

    }




    render() {
        const {productsActive, isLoaded, idStore, idCatalog} = this.state;
        if (isLoaded) {
            return (<div>

                    <h4 className="text-center" style={{margin:'0 auto 20px auto'}}>Код каталога</h4>
                    <div className="row">
                        <div className="col-4">
                            <p className="mx-sm-3 mb-2">Чтобы разместить каталог на сайте - скопиируйте код и вставьте
                                его в то
                                место, где хотите видеть каталог.</p>
                            <form className="form-inline">
                                <div className="form-group mx-sm-3 mb-2">
                                    <button type="button"  className="btn btn-info">Скопировать
                                        код
                                    </button>
                                </div>
                                <div className="form-group mx-sm-3 mb-2">
                                    <button type="button" className="btn btn-outline-info">Подробная инструкция по
                                        установке
                                    </button>
                                </div>
                                <div className="form-group mx-sm-3 mb-2">
                                    <button type="button" className="btn btn-outline-info">Заявка на бесплатную
                                        установку
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="col-8">
                            <div className="form-group ">
                        <textarea value={"<div id=\"cliclishopWidget\"></div>\n" +
                        "<script>\n" +
                        "    (function () {\n" +
                        "    var s = document.createElement('script');\n" +
                        "    s.type = 'text/javascript';\n" +
                        "    s.async = true;\n" +
                        "    s.src = \"https://api.cliclishop.ru/lk/widget/"+this.state.catalog.widget_code+"\";\n" +
                        "    document.head.appendChild(s)\n" +
                        "})();\n" +
                        "</script>"} className="form-control" readOnly={true} id="codeScrTextarea" rows={10}>
</textarea>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <h4 className="text-center" style={{margin:'0 auto 20px auto'}}>Код корзины</h4>
                    <div className="row">
                        <div className="col-4">
                            <p className="mx-sm-3 mb-2">Чтобы разместить корзину на сайте - скопиируйте код и
                                вставьте его на
                                страницу корзины.</p>
                            <form className="form-inline">
                                <div className="form-group mx-sm-3 mb-2">
                                    <button type="button"  className="btn btn-info">Скопировать
                                        код
                                    </button>
                                </div>
                                <div className="form-group mx-sm-3 mb-2">
                                    <button type="button" className="btn btn-outline-info">Подробная инструкция по
                                        установке
                                    </button>
                                </div>
                                <div className="form-group mx-sm-3 mb-2">
                                    <button type="button" className="btn btn-outline-info">Заявка на бесплатную
                                        установку
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="col-8">
                            <div className="form-group ">
                        <textarea value={
                            "<div id=\"cliclishopWidget\"></div>\n" +
                            "<script>\n" +
                            "(function () {\n" +
                            "    var s = document.createElement('script');\n" +
                            "    s.type = 'text/javascript';\n" +
                            "    s.async = true;\n" +
                            "    s.src = \"https://api.cliclishop.ru/lk/widget/basket/"+this.state.catalog.widget_code+"\";\n" +
                            "    document.head.appendChild(s)\n" +
                            "})();\n" +
                            "</script>"} className="form-control" readOnly={true} id="codeScrTextarea" rows={10}>
                        </textarea>
                            </div>
                        </div>
                    </div>

                    {/*<hr/>*/}

                    {/*<h4 className="text-center" style={{margin:'0 auto 20px auto'}}>Включение/выключение*/}
                    {/*    каталога</h4>*/}
                    {/*<div className="row">*/}
                    {/*    <div className="col-4">*/}
                    {/*        <form className="form-inline">*/}
                    {/*            <div className="form-group mx-sm-3 mb-2">*/}
                    {/*                <label>Ваш каталог включен</label>*/}
                    {/*                <label className="switch" style={{margin:'auto 10px'}}>*/}
                    {/*                    <input type="checkbox"/>*/}
                    {/*                    <span className="slider round"></span>*/}
                    {/*                </label>*/}
                    {/*            </div>*/}
                    {/*        </form>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-8">*/}
                    {/*        <div className="alert alert-danger">*/}
                    {/*            Что будет если выключить каталог?<br/>*/}
                    {/*            - Он будет автоматически скрыт на вашем сайте. Если в этот момент клиент начал*/}
                    {/*            делать заказ*/}
                    {/*            -*/}
                    {/*            ему придет уведомление о том, что продавец*/}
                    {/*            скрыл все товары.<br/><br/>*/}
                    {/*            Удалятся ли товары из этого каталога, если его выключить?<br/>*/}
                    {/*            - Все товары останутся, просто станут недоступны для просмотра.<br/><br/>*/}
                    {/*            Смогу ли я изменять информацию в выключенном каталоге?<br/>*/}
                    {/*            - Да, можно будет добавлять, удалять товары и изменять любую информацию о них.*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<hr/>*/}

                    {/*<h4 className="text-center" style={{margin:'0 auto 20px auto'}}>Включение/выключение*/}
                    {/*    каталога</h4>*/}
                    {/*<div className="row">*/}
                    {/*    <div className="col-4">*/}
                    {/*        <form className="form-inline">*/}
                    {/*            <div className="form-group mx-sm-3 mb-2">*/}
                    {/*                <label>Ваш каталог включен</label>*/}
                    {/*                <label className="switch" style={{margin:'auto 10px'}}>*/}
                    {/*                    <input type="checkbox"/>*/}
                    {/*                    <span className="slider round"></span>*/}
                    {/*                </label>*/}
                    {/*            </div>*/}
                    {/*        </form>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-8">*/}
                    {/*        <div className="alert alert-danger">*/}
                    {/*            Что будет если выключить каталог?<br/>*/}
                    {/*            - Он будет автоматически скрыт на вашем сайте. Если в этот момент клиент*/}
                    {/*            начал делать заказ*/}
                    {/*            -*/}
                    {/*            ему придет уведомление о том, что продавец*/}
                    {/*            скрыл все товары.<br/><br/>*/}
                    {/*            Удалятся ли товары из этого каталога, если его выключить?<br/>*/}
                    {/*            - Все товары останутся, просто станут недоступны для просмотра.<br/><br/>*/}
                    {/*            Смогу ли я изменять информацию в выключенном каталоге?<br/>*/}
                    {/*            - Да, можно будет добавлять, удалять товары и изменять любую информацию о*/}
                    {/*            них.*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<hr/>*/}

                    {/*<h4 className="text-center" style={{margin:'0 auto 20px auto'}}>Изменение названия*/}
                    {/*    каталога</h4>*/}
                    {/*<div className="row">*/}
                    {/*    <div className="col-4">*/}
                    {/*        <form className="form-inline">*/}
                    {/*            <div className="form-group mx-sm-3 mb-2">*/}
                    {/*                <label htmlFor="inputPassword2" className="sr-only">Password</label>*/}
                    {/*                <input type="text" className="form-control" id="inputPassword2"*/}
                    {/*                       placeholder="Название"/>*/}
                    {/*            </div>*/}
                    {/*            <button type="submit" className="btn btn-primary mb-2">Сохранить*/}
                    {/*            </button>*/}
                    {/*        </form>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-8">*/}
                    {/*        <div className="alert alert-danger">*/}
                    {/*            Что будет если изменить название каталога?<br/>*/}
                    {/*            - Если название каталога отображается на вашем сайте - оно будет*/}
                    {/*            изменено. Вся остальная*/}
                    {/*            информация останется неизменной, включая категории и товары.*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<hr/>*/}

                    {/*<h4 className="text-center" style={{margin:'0 auto 20px auto'}}>Удаление категорий</h4>*/}
                    {/*<div className="row">*/}
                    {/*    <div className="col-4">*/}
                    {/*        <form className="form-inline">*/}
                    {/*            <div className="form-group mx-sm-3 mb-2">*/}
                    {/*                <label htmlFor="inputPassword2" className="sr-only">Name</label>*/}
                    {/*                <input type="text" className="form-control" id="inputPassword2"*/}
                    {/*                       placeholder="Название магазина"/>*/}
                    {/*            </div>*/}
                    {/*            <div className="form-group mx-sm-3 mb-2">*/}
                    {/*                <label htmlFor="inputPassword2" className="sr-only">Name</label>*/}
                    {/*                <input type="text" className="form-control" id="inputPassword2"*/}
                    {/*                       placeholder="Название каталога"/>*/}
                    {/*            </div>*/}
                    {/*            <div className="form-group mx-sm-3 mb-2">*/}
                    {/*                <label htmlFor="inputPassword2" className="sr-only">Name</label>*/}
                    {/*                <input type="text" className="form-control" id="inputPassword2"*/}
                    {/*                       placeholder="Название категории"/>*/}
                    {/*            </div>*/}
                    {/*            <div className="form-group mx-sm-3 mb-2">*/}
                    {/*                Что сделать с товарами*/}
                    {/*            </div>*/}
                    {/*            <div className="form-group mx-sm-3 mb-2">*/}
                    {/*                Что сделать с подкатегориями*/}
                    {/*            </div>*/}
                    {/*            <button type="submit" className="btn btn-danger mb-2">Удалить*/}
                    {/*            </button>*/}
                    {/*        </form>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-8">*/}
                    {/*        <div className="alert alert-danger">*/}
                    {/*            Как удалить категорию?<br/>*/}
                    {/*            - Для удаления категории введие название магазина, название*/}
                    {/*            каталога, название категории,*/}
                    {/*            выберите что сделать с товарами категории и подкатегориями, нажмите*/}
                    {/*            на кнопку "Удалить" и*/}
                    {/*            подтвердите действие*/}
                    {/*            в появившемся модальном окне.<br/><br/>*/}
                    {/*            Что будет с товарами категории, если я ее удалю?<br/>*/}
                    {/*            - Вообще без понятия<br/><br/>*/}
                    {/*            Что будет с подкатегориями, если я удалю категорию?<br/>*/}
                    {/*            - Вообще без понятия<br/><br/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<hr/>*/}

                    {/*<h4 className="text-center" style={{margin:'0 auto 20px auto'}}>Удаление каталога</h4>*/}
                    {/*<div className="row">*/}
                    {/*    <div className="col-4">*/}
                    {/*        <form className="form-inline">*/}
                    {/*            <div className="form-group mx-sm-3 mb-2">*/}
                    {/*                <label htmlFor="inputPassword2"*/}
                    {/*                       className="sr-only">Name</label>*/}
                    {/*                <input type="text" className="form-control"*/}
                    {/*                       id="inputPassword2"*/}
                    {/*                       placeholder="Название магазина"/>*/}
                    {/*            </div>*/}
                    {/*            <div className="form-group mx-sm-3 mb-2">*/}
                    {/*                <label htmlFor="inputPassword2"*/}
                    {/*                       className="sr-only">Name</label>*/}
                    {/*                <input type="text" className="form-control"*/}
                    {/*                       id="inputPassword2"*/}
                    {/*                       placeholder="Название каталога"/>*/}
                    {/*            </div>*/}
                    {/*            <button type="submit" className="btn btn-danger mb-2">Удалить*/}
                    {/*            </button>*/}
                    {/*        </form>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-8">*/}
                    {/*        <div className="alert alert-danger">*/}
                    {/*            Как удалить каталог?<br/>*/}
                    {/*            - Для удаления каталога введие название магазина, название*/}
                    {/*            каталога, нажмите на кнопку*/}
                    {/*            "Удалить"*/}
                    {/*            и подтвердите действие*/}
                    {/*            в появившемся модальном окне.<br/><br/>*/}

                    {/*            Что случится с товарами, если удалить каталог?<br/>*/}
                    {/*            - Внимание! При удаление каталога удаляются все товары,*/}
                    {/*            категории и настройки.<br/><br/>*/}

                    {/*            Можно ли восстановить удаленный каталог?<br/>*/}
                    {/*            - Восстановить удаленный каталог, его категории, либо товары*/}
                    {/*            невозможно.*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}



            </div>)
        } else {
            return (<div>
                <Menu/>
                <div id="right_dark_space"></div>
                <div style={{width: 'calc(100% - 100px)'}} id="right_space">
                    <div className="preloader d-flex justify-content-center">
                        <div className="align-middle spinner-grow text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>

            </div>)
        }


    }
}

// @ts-ignore
export default SettingsCatalog;