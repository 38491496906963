// @ts-ignore
import React, {Component} from "react";
// import Menu from "../menu/Menu";
// import Cookies from "universal-cookie";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore
import Menu from "../menu/Menu";
// @ts-ignore
import Cookies from "universal-cookie";
import axios from "axios";

export class DeleteStore extends Component <any, any> {

    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: true,
            idProduct: this.props.idProduct,
            product: [],
            specifications: [],
            cookies: cookies.get('bearer'),
            idStore: this.props.idStore,
            idCatalog: this.props.idCatalog,
            name:'',
            email:'',


        };
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);



    }

    handleChangeName(event) {
        this.setState({name: event.target.value});
    }

    handleChangeEmail(event) {
        this.setState({email: event.target.value});
    }






    deleteStore() {
        const data = {
            store_id: this.props.idStore,
            email: this.state.idCatalog,
            nameStore: this.state.name,

        };
        axios({

            url: 'https://api.cliclishop.ru/lk/store/delete',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status

            window.location.assign('/store/');
        })

    }




    render() {
        const {isLoaded, specifications} = this.state;
        if (isLoaded) {
            return (<div className="row">
                <div className="col-4">
                    <form className="form-inline">

                        <div  className="form-group mx-sm-3 mb-2">
                            <input onChange={this.handleChangeName} value={this.state.name} type="text" className="form-control" id="inputPassword2"
                                   placeholder="Название магазина"/>
                        </div>
                        <button onClick={()=>this.deleteStore()}  className="btn btn-danger mb-2">Удалить</button>
                    </form>

                </div>
                <div className="col-8">
                    <div className="alert alert-danger">
                        Как удалить магазин?<br/>
                        - Для удаления магазина введие свой email, название магазина, нажмите на
                        кнопку "Удалить"
                        и подтвердите действие в появившемся модальном окне.<br/><br/>

                        Что случится с каталогами и товарами, если удалить магазин?<br/>
                        - Внимание! При удаление магазина удаляются все его каталоги товары,
                        категории и настройки.<br/><br/>

                        Можно ли восстановить удаленный магазин?<br/>
                        - Восстановить удаленный магазин, его каталоги, категории, либо товары
                        невозможно.
                    </div>
                </div>
            </div>)
        } else {
            return ('Загрузка...')
        }


    }
}

// @ts-ignore
export default DeleteStore;