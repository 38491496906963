import React, {Component} from 'react';
import Notification from "./Notification";


export class GlobalVariable extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {

        };


    }


    static host() {
        return 'https://site.cliclishop.ru';
    }
    static hostApiCliCliShop() {
        return 'https://api.cliclishop.ru';
    }
}
export default GlobalVariable;