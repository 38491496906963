// @ts-ignore
import React, {Component} from "react";
// import Menu from "../menu/Menu";
// import Cookies from "universal-cookie";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore
import Menu from "../menu/Menu";
// @ts-ignore
import Cookies from "universal-cookie";

import {Toast} from "react-bootstrap";
import axios from "axios";

export class Supports extends Component <any, any> {
    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: false,
            support: [],
            cookies: cookies.get('bearer'),
            idStore: this.props.idStore,
            idCatalog: this.props.idCatalog,


        };

        // this.ActivateMenu = this.ActivateMenu.bind(this);
    }

    componentDidMount() {
        const data = {limit: '10000'};
        axios({

            url: 'https://api.cliclishop.ru/lk/support/show',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            console.log(result.data)
            this.setState(
                {
                    support: result.data,
                    isLoaded: true,
                });
        })
            .catch(error => {
                console.log(error)
            })


    }


    render() {
        const {product, isLoaded, idStore, idCatalog, support, activeCategory, activePhoto, activeSpecifications, activeCustomization} = this.state;

        if (isLoaded) {
            return (<div style={{margin: '30px'}}>
                <Menu/>

                <div id="right_dark_space"></div>
                <div style={window.screen.width>650?{width: 'calc(100% - 100px)'}:{width: '100%'}} id="right_space">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Главная</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Техническая поддержка</li>
                        </ol>
                        <a href='/support/add' style={{float:'right'}} className="middle_button text-center">
                            Создать обращение
                        </a>
                    </nav>

                    <div className="text-center"><h1 className="top_header">Техническая поддержка</h1></div>
                    <div style={{margin: "20px 0", padding: "0"}} className="row ">
                        <div className="col-12">
                            <div className="shadow-card" style={{paddingRight:'40px', paddingLeft:'40px'}}>

                                <table className="table table-hover">
                                    <thead style={{borderTop:'none'}}>
                                    <tr>
                                        <th scope="col" style={{borderTop:'none'}}>Тема</th>
                                        <th scope="col" style={{borderTop:'none'}}></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {support.map(items => (<tr>

                                        <td>{items.title}</td>
                                        <td>
                                            <a href={"/support/show/"+items.id}>Перейти </a>&nbsp;&nbsp;&nbsp;
                                        </td>
                                    </tr>))}



                                    </tbody>
                                </table>

                            </div>
                        </div>


                    </div>

                </div>
            </div>)
        } else {
            return (<div>
                <Menu/>
                <div id="right_dark_space"></div>
                <div style={window.screen.width>650?{width: 'calc(100% - 100px)'}:{width: '100%'}} id="right_space">

                    <div className="preloader d-flex justify-content-center">
                        <div className="align-middle spinner-grow text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>

            </div>)
        }


    }
}

// @ts-ignore
export default Supports;