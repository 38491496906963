// @ts-ignore
import React, {Component} from "react";
import Menu from "../../menu/Menu";
import Cookies from "universal-cookie";
import axios from "axios";
import Global from "../../GlobalVariable";


export class Settings extends Component <any, any> {
    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: false,
            isLoadedSettings: false,
            settingsSite: [],
            activeChangethemeSettingTitle: '',
            activeChangethemeSettingValue: '',
            size:window.screen.width,
            activeSite:JSON.parse(localStorage.getItem('activeSite')as string)

        };

        this.handleInputChange = this.handleInputChange.bind(this);


    }
    changeSettings() {


        const data = {

            title: this.state.activeChangethemeSettingTitle,
            value: this.state.activeChangethemeSettingValue,
            themeId: this.state.activeTheme,
            siteId: this.props.idSite,


        };
        axios({

            url: Global.host()+'/admin/sites/user/templates/settings/change',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            if (result.status == 200) {

            }
            this.setState(
                {});

        })
    }
    settingsSiteAll() {
        const data = {


            siteId: this.props.idSite,

        };
        axios({

            url: Global.host() + '/admin/sites/user/templates/settings/show',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            if (result.status == 200) {

            }

            this.setState(
                {
                    settingsSite: Object.entries(result.data.settings),
                    isLoadedSettings: true,


                });


        })
    }
    componentDidMount() {
        this.settingsSiteAll()


    }

    changeActiveSettings(title, value) {
        console.log(title, value)
        this.setState({activeChangethemeSettingTitle: title, activeChangethemeSettingValue: value});
    }

    renderSettingsTemplate() {
        if (this.state.isLoadedSettings) {
            return (
                this.state.settingsSite.map(item => (
                    <div><p>{item[0]} <span onClick={() => this.changeActiveSettings(item[0], item[1])}>Изменить</span>
                    </p></div>

                ))
            )


        }
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }
    render() {
        const {pages, isLoadedSettings,size} = this.state;
        if(isLoadedSettings){
            return(<div>
                <Menu/>
                <div id="right_dark_space"></div>
                <div style={size>650?{width: 'calc(100% - 100px)'}:{width: '100%'}} id="right_space">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Главная</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Настройка сайта</li>
                        </ol>
                    </nav>
                    <h1 className="text-center" style={{margin:'margin: 40px auto 20px auto'}}>Настройка сайта</h1>

                    <div className="row" style={{margin: "20px 0", padding: "0"}}>
                        <div className="col-12">
                            <div className="shadow-card"  style={size>650?{paddingRight:'40px', paddingLeft:'40px'}:{}}>

                                <table className="table table-hover">
                                    <thead style={{borderTop:'none'}}>
                                    <tr>
                                        <th scope="col" style={{borderTop:'none'}}>Название</th>
                                        <th scope="col" style={{borderTop:'none'}}>Значение</th>
                                        <th scope="col" style={{borderTop:'none'}}></th>
                                        <th scope="col" style={{borderTop:'none'}}></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.settingsSite.map(items => (<tr>

                                        <td>{items[0]}</td>
                                        <td>{items[1]}</td>
                                        <td><span onClick={() => this.changeActiveSettings(items[0], items[1])}>Изменить</span></td>
                                    </tr>))}



                                    </tbody>
                                </table>
                                <textarea style={{width: '100%', height: '200px'}}
                                          name='activeChangethemeSettingValue'
                                          onChange={this.handleInputChange}
                                          value={this.state.activeChangethemeSettingValue}>{}</textarea>
                                <button onClick={() => this.changeSettings()} type="button"
                                        className="btn btn-primary">Изменить сайт
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>)
        }
        else {
            return (<div>
                <Menu/>
                <div id="right_dark_space"></div>
                <div style={{width: 'calc(100% - 100px)'}} id="right_space">

                    <div className="preloader d-flex justify-content-center">
                        <div className="align-middle spinner-grow text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>

            </div>)
        }



    }
}

// @ts-ignore
export default Settings;