// @ts-ignore
import React, {Component} from "react";
// import Menu from "../menu/Menu";
// import Cookies from "universal-cookie";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore

// @ts-ignore
import Cookies from "universal-cookie";
import axios from "axios";

export class AddPage extends Component <any, any> {
    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: true,
            shop: [],
            cookies: cookies.get('bearer'),
            idStore: this.props.idStore,
            idCatalog: this.props.idCatalog,
            status: false,
            content: '',
            address: '',


        };
        this.changeStatus = this.changeStatus.bind(this);
        this.changeName = this.changeName.bind(this);
        this.changeAddress = this.changeAddress.bind(this);
        this.changeTime = this.changeTime.bind(this);
        this.changePhone = this.changePhone.bind(this);
        this.changeCoordinates1 = this.changeCoordinates1.bind(this);
        this.changeCoordinates2 = this.changeCoordinates2.bind(this);
        this.changeContent = this.changeContent.bind(this);
    }

    changeStatus(event) {

        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({status: value});
    }

    changeName(event) {
        this.setState({name: event.target.value});
    }

    changeAddress(event) {
        this.setState({address: event.target.value});
    }

    changeTime(event) {
        this.setState({time: event.target.value});
    }

    changePhone(event) {
        this.setState({phone: event.target.value});
    }

    changeCoordinates1(event) {
        this.setState({coordinates1: event.target.value});
    }

    changeCoordinates2(event) {
        this.setState({coordinates2: event.target.value});
    }
    changeContent(event){
        this.setState({content: event.target.value});
    }
    componentDidMount() {


    }

    send() {
        const data = {
            store_id: this.state.idStore,
            catalog_id: this.state.idCatalog,
            status: this.state.status,
            name: this.state.name,
            content: this.state.content,

        };
        axios({

            url: 'https://api.cliclishop.ru/lk/catalog/page/add',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            console.log(result.data)
            this.setState(
                {

                });
        })
    }


    render() {
        const {shop, isLoaded, idStore, idCatalog} = this.state;
        if (isLoaded) {
            return (<div>
                <form><br/>
                    <div className="form-group form-check">
                        <input checked={this.state.status}
                               onChange={this.changeStatus}
                               type="checkbox" className="form-check-input" id="exampleCheck1"/>
                        <label className="form-check-label" htmlFor="exampleCheck1">Статус (Актвен/ Не активен)</label>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">Название страницы</label>
                        <input onChange={this.changeName} value={this.state.name} type="text" className="form-control"
                               id="exampleFormControlInput1"
                               placeholder="О доставке"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlTextarea1">Описание страницы</label>
                        <textarea onChange={this.changeContent} className="form-control" id="exampleFormControlTextarea1" rows={8} value={this.state.content}></textarea>
                    </div>


                </form>

                <div onClick={() => this.send()} style={{marginBottom: '30px'}} className="text-center">
                    <div className="middle_button text-center">
                        <a style={{color: "white", textDecoration: "none"}}>Отправить изменения</a>
                    </div>

                </div>


            </div>)
        } else {
            return (<div>


                <div className="preloader d-flex justify-content-center">
                    <div className="align-middle spinner-grow text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>


            </div>)
        }


    }
}

// @ts-ignore
export default AddPage;