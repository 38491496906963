// @ts-ignore
import React, {Component} from "react";
// import Menu from "../menu/Menu";
// import Cookies from "universal-cookie";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore
import Menu from "../menu/Menu";
// @ts-ignore
import Cookies from "universal-cookie";
import axios from "axios";
import ShowProducts from "./product/ShowProducts";
import ShowCategory from "./category/ShowCategory";

export class ProductMassCreate extends Component <any, any> {
    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: true,
            product: [],
            cookies: cookies.get('bearer'),
            idStore: this.props.idStore,
            idCatalog: this.props.idCatalog,
            search: null,
            productActive: [],
            category: [],
            idCategory: null,
            activeAttr: false,
            activeAttrValue: false,
            activeAttrTitle: false,
            activeProductId: null,
            valueTextArea: null,
            arrayProduct: [],
            productIdActive: ''

        };
        this.changeAttribute = this.changeAttribute.bind(this);
        this.onKeyPressHandler = this.onKeyPressHandler.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.temp = this.temp.bind(this);

    }

    updateData = (value) => {
        console.log(value)
        this.setState({idCategory: value});
    }

    ActivateCategory(value) {
        this.setState({idCategory: value});
    }

    postChangeAttr() {
        console.log(this.state)
        let dataCategory
        let url
        if (this.state.activeAttrTitle) {
            dataCategory = {
                store_id: this.state.idStore,
                catalog_id: this.state.idCatalog,
                product_id: this.state.activeProductId,
                title: this.state.valueTextArea,
                attr_id: this.state.activeAttr,
            };
            url = "https://api.cliclishop.ru/lk/product/attr/change/title"
        }
        if (this.state.activeAttrValue) {
            dataCategory = {
                store_id: this.state.idStore,
                catalog_id: this.state.idCatalog,
                product_id: this.state.activeProductId,
                value: this.state.valueTextArea,
                attr_id: this.state.activeAttr,
            };
            url = "https://api.cliclishop.ru/lk/product/attr/change/value"
        }


        console.log(url)
        console.log(dataCategory)
        axios({

            url: url,
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: dataCategory,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status

        })
    }

    componentDidMount() {
        let arrayProduct: any[] = [];
        for (var i = 1; i <= 100; i++) {
            let key = String(i);
            let arr = []
            arr['id'] = i;
            arr['title'] = '';
            arr['idCategory'] = '';
            arr['price'] = '';
            arr['text'] = '';
            arr['attrTitle'] = '';
            arrayProduct[i] = arr;
        }
        console.log(arrayProduct)
        this.setState(
            {
                arrayProduct: arrayProduct
            });
        const dataCategory = {
            store_id: this.state.idStore,
            catalog_id: this.state.idCatalog,
            product_id: this.props.idProduct
        };
        axios({

            url: 'https://api.cliclishop.ru/lk/catalog/categories',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: dataCategory,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            const temp = result.data.filter(item => item.parent === null);
            console.log(temp)
            this.setState(
                {
                    category: result.data,
                });
        })
        const data = {store_id: this.state.idStore, catalog_id: this.state.idCatalog};
        axios({

            url: 'https://api.cliclishop.ru/lk/product',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            console.log(this.props.idCategory)
            if (this.props.idCategory) {
                const temp = result.data.filter(item => item.categories != null && item.categories.id === this.props.idCategory);
                this.setState(
                    {
                        product: result.data,

                        isLoaded: true,
                    });
            } else {
                console.log('-')
                this.setState(
                    {
                        product: result.data,
                        isLoaded: true,
                    });
            }


        })

    }

    onKeyPressHandler(event) {
        if (event.keyCode === 13) {
            console.log(this.state.valueTextArea)
            console.log('OK')
        }
    }

    changeAttribute(item, status, idProduct) {
        console.log(item)
        console.log(status)
        console.log(idProduct)
        if (this.state.activeAttr) {
            console.log('090')
            this.postChangeAttr()
        }

        if (status === 'value') {
            this.setState(
                {
                    activeAttr: item.id,
                    activeAttrValue: true,
                    activeAttrTitle: false,
                    activeProductId: idProduct,
                    valueTextArea: item.value,
                });
        } else if (status === 'title') {
            this.setState(
                {
                    activeAttr: item.id,
                    activeAttrTitle: true,
                    activeAttrValue: false,
                    valueTextArea: item.title,
                    activeProductId: idProduct,
                });
        }


    }

    handleChange(event, idProduct, idAttr, status) {
        const {product, activeAttrValue, activeAttrTitle} = this.state;
        console.log(event.target.value)
        let temp = product.find(el => el.id === idProduct)
        this.setState(
            {
                valueTextArea: event.target.value,

            });
        if (status === 'value') {
            const newState = temp.attrproducts.map(obj =>
                obj.id === idAttr ? {...obj, value: event.target.value} : obj
            );
            const newState2 = product.map(obj =>
                obj.id === idProduct ? {...obj, attrproducts: newState} : obj
            );
            this.setState(
                {
                    product: newState2,

                });
        } else if (status === 'title') {
            const newState = temp.attrproducts.map(obj =>
                obj.id === idAttr ? {...obj, title: event.target.value} : obj
            );
            const newState2 = product.map(obj =>
                obj.id === idProduct ? {...obj, attrproducts: newState} : obj
            );
            this.setState(
                {
                    product: newState2,

                });
        }


        // this.setState({valueTextArea: event.target.value});
    }

    handleChange2(event, idProduct, attr) {
        let arrayProduct = this.state.arrayProduct
        arrayProduct[idProduct][attr] = event.target.value;
        console.log(arrayProduct)


        this.setState({arrayProduct: arrayProduct});
    }

    temp(item, idProduct) {

        const {activeAttr, activeAttrValue, activeAttrTitle} = this.state;
        if (activeAttr === item.id) {

            if (activeAttrTitle) {
                return (<tr>
                    <td><textarea onChange={(e) => this.handleChange(e, idProduct, item.id, 'title')}
                                  onKeyPress={this.onKeyPressHandler}>{item.title}</textarea></td>
                    <td onClick={() => this.changeAttribute(item, 'value', idProduct)}>{item.value}</td>

                </tr>)
            } else if (activeAttrValue) {
                return (<tr>

                    <td onClick={() => this.changeAttribute(item, 'title', idProduct)}>{item.title}</td>
                    <td><textarea onChange={(e) => this.handleChange(e, idProduct, item.id, 'value')}
                                  onKeyPress={this.onKeyPressHandler}>{item.value}</textarea></td>

                </tr>)
            }

        } else {
            return (
                <tr>
                    <td onClick={() => this.changeAttribute(item, 'title', idProduct)}>{item.title}</td>
                    <td onClick={() => this.changeAttribute(item, 'value', idProduct)}>{item.value}</td>

                </tr>)
        }
    }

    editProductAndAttr(i,id_product) {
        const data2 = {
            store_id: this.props.idStore, catalog_id: this.props.idCatalog, product_id: id_product,
            name_product: this.state.arrayProduct[i]['title'],
            categories: this.state.arrayProduct[i]['category'],
            price: this.state.arrayProduct[i]['price'],

            code: '',
            vendorcode: '',
            availability: 10,
            unit: '',
            content: this.state.arrayProduct[i]['text'],
            status: '',
            oldPrice: '',
        };
        axios({

            url: 'https://api.cliclishop.ru/lk/product/edit',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data2,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status


        })

        const data3 = {
            store_id: this.props.idStore,
            catalog_id: this.props.idCatalog,
            product_id: id_product,
            title: this.state.arrayProduct[i]['attrTitle'],
            value: this.state.arrayProduct[i]['price'],

        };
        axios({

            url: 'https://api.cliclishop.ru/lk/product/attr/add',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data3,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status

        })

    }

    save() {
        for (let i = 1; i < this.state.arrayProduct.length; i++) {


            if (this.state.arrayProduct[i]['title'] != '') {
                console.log(this.state.arrayProduct[i])
                const data = {store_id: this.state.idStore, catalog_id: this.state.idCatalog};
                let productId = '';
                axios({

                    url: 'https://api.cliclishop.ru/lk/product/add',
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    withCredentials: true,
                    method: "POST",
                    data: data,

                    // receive two    parameter endpoint url ,form data
                }).then(result => { // then print response status.
                    this.editProductAndAttr(i, result.data.id_product)


                })


            }
        }

    }


    show() {
        const {activeAttr, activeAttrValue, activeAttrTitle} = this.state;


        return (
            <table className={'table table-bordered table-hover table-sm p-0 price_table'}>
                <thead>
                <tr>
                    <th scope="col">Название</th>
                    <th scope="col">Аттрибуты</th>
                    <th scope="col">Стоимость</th>
                    <th scope="col">Категория</th>
                    <th scope="col">Описание</th>


                </tr>
                </thead>
                {

                    this.state.arrayProduct.map(items => (
                        <tbody>

                        <tr>
                            <td rowSpan={14}>
                                <textarea onChange={(e) => this.handleChange2(e, items.id, 'title')}
                                          onKeyPress={this.onKeyPressHandler}>{items.title}</textarea>
                            </td>

                        </tr>
                        <tr>
                            <td rowSpan={20}>
                                <textarea onChange={(e) => this.handleChange2(e, items.id, 'price')}
                                          onKeyPress={this.onKeyPressHandler}>{items.price}</textarea>
                            </td>

                        </tr>
                        <tr>
                            <td rowSpan={8}>
                                <textarea onChange={(e) => this.handleChange2(e, items.id, 'attrTitle')}
                                          onKeyPress={this.onKeyPressHandler}>{items.attrTitle}</textarea>
                            </td>

                        </tr>
                        <tr>
                            <td rowSpan={8}>
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Категория</label>
                                    <select onChange={(e) => this.handleChange2(e, items.id, 'category')}
                                            value={this.state.arrayProduct[items.id]['category'] ? this.state.categoryId : '0'}
                                            className="form-control"
                                            id="exampleFormControlSelect1">
                                        <option value='0'>Нет категории</option>
                                        {this.state.category.map(items => (

                                            <option value={items.id}>{items.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </td>

                        </tr>
                        <tr>
                            <td rowSpan={8}>
                                <textarea onChange={(e) => this.handleChange2(e, items.id, 'text')}
                                          onKeyPress={this.onKeyPressHandler}>{items.title}</textarea>
                            </td>

                        </tr>

                        </tbody>
                    ))
                }
                {/*{*/}

                {/*    this.state.product.map(items => (*/}
                {/*        <tbody>*/}

                {/*        <tr>*/}
                {/*            <td rowSpan={8}><a*/}
                {/*                href={'/store/' + this.props.idStore + '/catalog/' + this.props.idCatalog + '/product/' + items.id + '/edit'}>{items.name}</a>*/}
                {/*            </td>*/}

                {/*        </tr>*/}
                {/*        {*/}

                {/*            items.attrproducts.map(item => this.temp(item, items.id))*/}


                {/*        }*/}

                {/*        </tbody>*/}
                {/*    ))*/}
                {/*}*/}


            </table>
        )


    }

    unit(id) {
        const {product, category, isLoaded, idStore, idCatalog} = this.state;
        if (id === 'all') {
            return product.length
        } else {

            const temp = product.filter(item => item.categories != null && item.categories.id === id);

            return temp.length
        }


    }

    menuNode(item) {


        if (item.parent == null) {
            return (<li>{item.name} ({this.unit(item.id)}) <span onClick={() => this.ActivateCategory(item.id)}
                                                                 style={{color: 'royalblue'}}>Показать товары</span>
                <ul>{item.categories.map(items => (
                    this.childrenCategory(items)
                ))}</ul>
            </li>)
        }
    }

    childrenCategory(item) {
        if (item.categories.length > 0) {
            return (<li>{item.name} ({this.unit(item.id)}) <span onClick={() => this.ActivateCategory(item.id)}
                                                                 style={{color: 'royalblue'}}>Показать товары</span>
                <ul>{item.categories.map(items => (
                    this.childrenCategory(items)
                ))}</ul>
            </li>)
        }
        if (item.categories.length === 0) {
            return (<li>{item.name} ({this.unit(item.id)}) <span onClick={() => this.ActivateCategory(item.id)}
                                                                 style={{color: 'royalblue'}}>Показать товары</span>
            </li>)
        }
    }

    render() {
        const {productsActive, category, isLoaded, idStore, idCatalog} = this.state;
        if (isLoaded) {
            return (<div>

                <Menu/>
                <div id="right_dark_space"></div>
                <div style={window.screen.width > 650 ? {width: 'calc(100% - 100px)'} : {width: '100%'}}
                     id="right_space">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Главная</a></li>
                            <li className="breadcrumb-item"><a href="/store">Магазины</a></li>
                            <li className="breadcrumb-item"><a href={'/store/' + idStore + '/catalog'}>Каталоги</a></li>

                            <li className="breadcrumb-item active" aria-current="page">Товары</li>
                        </ol>
                    </nav>
                    <div><h1 className="text-center margin_t_b_20">Быстрое добавление товаров</h1></div>

                    <div className="text-center">
                        <div className="middle_button text-center">
                            <a href={'/store/' + idStore + '/catalog/' + idCatalog + '/product/add'}
                               style={{color: "white", textDecoration: "none"}}>Добавить товар</a>
                        </div>
                        <div className="middle_button text-center">
                            <a href={'/store/' + idStore + '/catalog/' + idCatalog}
                               style={{color: "white", textDecoration: "none"}}>Просмотр товаров</a>
                        </div>
                        <div className="middle_button text-center" onClick={() => this.save()}>
                            Сохранить
                        </div>

                    </div>
                    <div className={'shadow-card'}>
                        <h4 className={'text-center'}>Категории </h4>
                        <ul>
                            <li>Все товары ({this.unit('all')}) <span onClick={() => this.ActivateCategory(null)}
                                                                      style={{color: 'royalblue'}}>Показать товары</span>
                            </li>
                            {category.map(items => (

                                this.menuNode(items)
                            ))

                            }
                        </ul>
                    </div>

                    <div className={'shadow-card'}>
                        <h4 className={'text-center'}>Товары </h4>
                        {this.show()}
                    </div>


                </div>
            </div>)
        } else {
            return (<div>
                <Menu/>
                <div id="right_dark_space"></div>
                <div style={{width: 'calc(100% - 100px)'}} id="right_space">
                    <div className="preloader d-flex justify-content-center">
                        <div className="align-middle spinner-grow text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>

            </div>)
        }


    }
}

// @ts-ignore
export default ProductMassCreate;