// @ts-ignore
import React, {Component} from "react";
// import Menu from "../menu/Menu";
// import Cookies from "universal-cookie";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore
import Menu from "../menu/Menu";
// @ts-ignore
import Cookies from "universal-cookie";
import axios from "axios";
import ShowProducts from "./product/ShowProducts";
import ShowCategory from "./category/ShowCategory";

export class CatalogsShow extends Component <any, any> {
    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: true,
            product: [],
            cookies: cookies.get('bearer'),
            idStore: this.props.idStore,
            idCatalog: this.props.idCatalog,
            search: null,
            productActive: [],
            category: [],
            idCategory: null,

        };


    }

    updateData = (value) => {
        console.log(value)
        this.setState({idCategory: value});
    }

    ActivateCategory(value) {
        this.setState({idCategory: value});
    }

    componentDidMount() {
        const dataCategory = {
            store_id: this.state.idStore,
            catalog_id: this.state.idCatalog,
            product_id: this.props.idProduct
        };
        axios({

            url: 'https://api.cliclishop.ru/lk/catalog/categories',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: dataCategory,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            const temp = result.data.filter(item => item.parent === null);
            console.log(temp)
            this.setState(
                {
                    category: result.data,
                });
        })
        const data = {store_id: this.state.idStore, catalog_id: this.state.idCatalog};
        axios({

            url: 'https://api.cliclishop.ru/lk/product',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status


            this.setState(
                {
                    product: result.data,
                });
        })

    }

    show() {

        return (
            <ShowProducts key={this.state.idCategory} idCategory={this.state.idCategory} idStore={this.props.idStore}
                          idCatalog={this.props.idCatalog}/>)


    }

    unit(id) {
        const {product, category, isLoaded, idStore, idCatalog} = this.state;
        if (id === 'all') {
            return product.length
        } else {

            const temp = product.filter(item => item.categories != null && item.categories.id === id);
            console.log(product)
            return temp.length
        }


    }

    menuNode(item) {


        if (item.parent == null) {
            return (<li>{item.name} ({this.unit(item.id)}) <span onClick={() => this.ActivateCategory(item.id)}
                                                                 style={{color: 'royalblue'}}>Показать товары</span>
                <ul>{item.categories.map(items => (
                    this.childrenCategory(items)
                ))}</ul>
            </li>)
        }
    }

    childrenCategory(item) {
        if (item.categories.length > 0) {
            return (<li>{item.name} ({this.unit(item.id)}) <span onClick={() => this.ActivateCategory(item.id)}
                                                                 style={{color: 'royalblue'}}>Показать товары</span>
                <ul>{item.categories.map(items => (
                    this.childrenCategory(items)
                ))}</ul>
            </li>)
        }
        if (item.categories.length === 0) {
            return (<li>{item.name} ({this.unit(item.id)}) <span onClick={() => this.ActivateCategory(item.id)}
                                                                 style={{color: 'royalblue'}}>Показать товары</span>
            </li>)
        }
    }

    render() {
        const {productsActive, category, isLoaded, idStore, idCatalog} = this.state;
        if (isLoaded) {
            return (<div>

                <Menu/>
                <div id="right_dark_space"></div>
                <div style={window.screen.width>650?{width: 'calc(100% - 100px)'}:{width: '100%'}} id="right_space">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Главная</a></li>
                            <li className="breadcrumb-item"><a href="/store">Магазины</a></li>
                            <li className="breadcrumb-item"><a href={'/store/' + idStore + '/catalog'}>Каталоги</a></li>

                            <li className="breadcrumb-item active" aria-current="page">Товары</li>
                        </ol>
                    </nav>
                    <div><h1 className="text-center margin_t_b_20">Товары</h1></div>

                    <div className="text-center">
                        <div className="middle_button text-center">
                            <a href={'/store/' + idStore + '/catalog/' + idCatalog + '/product/add'}
                               style={{color: "white", textDecoration: "none"}}>Добавить товар</a>
                        </div>
                        <div className="middle_button text-center">
                            <a href={'/store/' + idStore + '/catalog/' + idCatalog + '/fullchange'}
                               style={{color: "white", textDecoration: "none"}}>Быстрое редактирование товаров</a>
                        </div>
                        <div className="middle_button text-center">
                            <a href={'/store/' + idStore + '/catalog/' + idCatalog + '/mass-crate'}
                               style={{color: "white", textDecoration: "none"}}>Быстрое добавление товаров</a>
                        </div>

                    </div>
                    <div className={'shadow-card'}>
                        <h4 className={'text-center'}>Категории </h4>
                        <ul>
                            <li>Все товары ({this.unit('all')}) <span onClick={() => this.ActivateCategory(null)}
                                                                      style={{color: 'royalblue'}}>Показать товары</span>
                            </li>
                            {category.map(items => (

                                this.menuNode(items)
                            ))

                            }
                        </ul>
                    </div>

                    <div className={'shadow-card'}>
                        <h4 className={'text-center'}>Товары </h4>
                        {this.show()}
                                            </div>


                </div>
            </div>)
        } else {
            return (<div>
                <Menu/>
                <div id="right_dark_space"></div>
                <div style={{width: 'calc(100% - 100px)'}} id="right_space">
                    <div className="preloader d-flex justify-content-center">
                        <div className="align-middle spinner-grow text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>

            </div>)
        }


    }
}

// @ts-ignore
export default CatalogsShow;