// @ts-ignore
import React, {Component} from "react";
import Menu from "../../menu/Menu";
import Cookies from "universal-cookie";
import axios from "axios";
import Notification from "../../Notification";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Global from "../../GlobalVariable";





export class AddPage extends Component <any, any> {
    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: true,
            store: [],
            notification: [],
            name:'',
            content:'',
            activeSite:JSON.parse(localStorage.getItem('activeSite')as string)

        };
        this.changeName = this.changeName.bind(this);
        this.changeContent = this.changeContent.bind(this);



    }

    changeName(event){
        this.setState({name: event.target.value});
    }
    changeContent(content){
        this.setState({content: content});
    }
    updateData = (value) => {
        var filteredList = this.state.notification.filter(function (item) {
            return item.text.toLowerCase().search(value.toLowerCase()) == -1;
        });
        this.setState({notification: filteredList});
    }
    notification(text){
        const data = this.state.notification
        const element = { text: text, time: 25 };
        const newArray = [ ...data, element ];
        this.setState(
            {
                notification: newArray,

            });
    }
    componentDidMount() {



    }
    addPageApi() {

        const data = {title: this.state.name,content: this.state.content,idSite: this.state.activeSite.activeSiteId};
        axios({

            url: Global.host()+'/admin/page/add',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            console.log(result.data)

        })
    }

    render() {
        const {store, isLoaded} = this.state;
        if(isLoaded){
            return(<div>
                <Menu/>
                <div id="right_dark_space"></div>
                <div style={window.screen.width>650?{width: 'calc(100% - 100px)'}:{width: '100%'}} id="right_space">
                    <Notification updateData={this.updateData} NotificationArray={this.state.notification}/>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Главная</a></li>
                            <li className="breadcrumb-item"><a href={"/site/pagesAll/"+this.props.idSite}>Страницы</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Добавить страницу</li>
                        </ol>
                    </nav>
                    <h1 className="text-center" style={{margin:'40px auto 20px auto'}}>Добавить новую страницу</h1>
                    <div className="shadow-card col-sm-12" style={{margin:'0 auto'}}>

                        <form  ><br/>

                            <div className="form-group row-cols-sm-3">
                                <label htmlFor="exampleFormControlInput1">Заголовок страницы</label>
                                <input onChange={this.changeName} value={this.state.name} type="text" className="form-control" id="exampleFormControlInput1"
                                       placeholder="Title"/>
                            </div>
                            <CKEditor
                                editor={ ClassicEditor }
                                data="<p>Здесь можно создать свою страницу</p>"



                                onReady={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                   // console.log( 'Editor is ready to use!', editor );
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    this.changeContent(data)
                                    //console.log( { event, editor, data } );
                                } }
                                onBlur={ ( event, editor ) => {
                                   // console.log( 'Blur.', editor );
                                } }
                                onFocus={ ( event, editor ) => {
                                  //  console.log( 'Focus.', editor );
                                } }
                            />


                        </form>

                        <div onClick={()=>this.addPageApi()}  style={{marginBottom:'30px'}} className="text-center">
                            <div className="middle_button text-center">
                                <a style={{color: "white", textDecoration: "none"}}>Отправить изменения</a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>)
        }
        else {
            return (<div>
                <Menu/>
                <div id="right_dark_space"></div>
                <div style={window.screen.width>650?{width: 'calc(100% - 100px)'}:{width: '100%'}} id="right_space">

                    <div className="preloader d-flex justify-content-center">
                        <div className="align-middle spinner-grow text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>

            </div>)
        }



    }
}

// @ts-ignore
export default AddPage;