// @ts-ignore
import React, {Component} from "react";
// import Menu from "../menu/Menu";
// import Cookies from "universal-cookie";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore
import Menu from "../menu/Menu";
// @ts-ignore
import Cookies from "universal-cookie";
import axios from "axios";

export class Headings extends Component <any, any> {

    constructor(props: any) {
        super(props);
        const cookies = new Cookies();
        this.state = {
            error: null,
            isLoaded: true,
            idProduct: this.props.idProduct,
            HeadingsTypeArraySite: [],
            HeadingsTypeArrayMobile: [],
            specifications: [],
            cookies: cookies.get('bearer'),
            idStore: this.props.idStore,
            idCatalog: this.props.idCatalog,
            name_HeadingType: '',
            type_HeadingType: 'site',
            updateSpecificationsName: '',
            updateSpecificationsValue: '',
            updateSpecificationsId: '',


        };
        this.handleChangeAddSpecificationsName = this.handleChangeAddSpecificationsName.bind(this);
        this.handleChangeAddSpecificationsValue = this.handleChangeAddSpecificationsValue.bind(this);
        this.handleChangeUpdateSpecificationsValue = this.handleChangeUpdateSpecificationsValue.bind(this);
        this.handleChangeUpdateSpecificationsValue = this.handleChangeUpdateSpecificationsValue.bind(this);
        this.handleChangeUpdateSpecificationsName = this.handleChangeUpdateSpecificationsName.bind(this);
        this.updateSpecifications = this.updateSpecifications.bind(this);
        this.changeHeadings = this.changeHeadings.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

    }

    handleChangeAddSpecificationsName(event) {
        this.setState({addSpecificationsName: event.target.value});
    }

    handleChangeAddSpecificationsValue(event) {
        this.setState({addSpecificationsValue: event.target.value});
    }

    handleChangeUpdateSpecificationsName(event) {
        this.setState({updateSpecificationsName: event.target.value});
    }

    handleChangeUpdateSpecificationsValue(event) {
        this.setState({updateSpecificationsValue: event.target.value});
    }

    componentDidMount() {
        const data = {
            store_id: this.state.idStore,
            catalog_id: this.state.idCatalog,
            product_id: this.props.idProduct
        };
        axios({

            url: 'https://api.cliclishop.ru/lk/product/headingsType/show',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status

            this.setState(
                {
                    HeadingsTypeArraySite:result.data.filter(word => word.type == 'site'),
                    HeadingsTypeArrayMobile:result.data.filter(word => word.type == 'mobile'),
                    isLoaded: true,
                });
        })


    }


    addHeadingType() {
        const data = {
            store_id: this.state.idStore,
            catalog_id: this.state.idCatalog,
            product_id: this.props.idProduct,
            name_HeadingType: this.state.name_HeadingType,
            type_HeadingType: this.state.type_HeadingType,
        };
        axios({

            url: 'https://api.cliclishop.ru/lk/product/headingsType/add',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.componentDidMount()

        })



    }

    deleteHeadings(id) {
        const data = {
            store_id: this.state.idStore,
            catalog_id: this.state.idCatalog,
            product_id: this.props.idProduct,
            id_headings: id,
        };
        axios({

            url: 'https://api.cliclishop.ru/lk/product/headings/delete',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: data,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.componentDidMount()
            this.setState(
                {

                });
        })

    }

    updateSpecifications(items) {
        console.log(items)

        this.setState({
            updateSpecificationsName: items.name,
            updateSpecificationsValue: items.content,
            updateSpecificationsId: items.id,

        });
    }

    changeHeadings(id) {
        const dataHeadings = {
            store_id: this.state.idStore,
            catalog_id: this.state.idCatalog,
            product_id: this.props.idProduct,
            headings_id: id,
        };
        axios({

            url: 'https://api.cliclishop.ru/lk/product/headings/add',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            withCredentials: true,
            method: "POST",
            data: dataHeadings,

            // receive two    parameter endpoint url ,form data
        }).then(result => { // then print response status
            this.componentDidMount()
            this.setState(
                {

                });
        })


    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    render() {
        const {isLoaded, specifications} = this.state;
        if (isLoaded) {
            return (<div>
                <h4 className="text-center" style={{marginBottom: '20px', marginTop: '30px'}}>Рубрики</h4>
                <div style={{margin:'0 auto'}} className="overflow-auto col-lg-8 ">
                <form >
                    <div   className={"row"}>
                        <div className="col-md-3 form-group">
                            <label htmlFor="exampleInputEmail1">Наименование</label>
                            <input value={this.state.name_HeadingType}
                                   name='name_HeadingType'
                                   onChange={this.handleInputChange} type="text"
                                   className="form-control"
                                   id="exampleInputEmail1"
                                   aria-describedby="emailHelp"/>
                        </div>
                        <div className="col-md-3 form-group">
                            <label htmlFor="exampleFormControlSelect1">Категория</label>
                            <select
                                name='type_HeadingType'
                                onChange={this.handleInputChange}
                                     className="form-control"
                                    id="exampleFormControlSelect1">
                                <option value='site'>Сайт</option>
                                <option value='mobile'>Mobile</option>

                            </select>
                        </div>
                    </div>


                    <div onClick={() => this.addHeadingType()} style={{marginBottom: '30px'}}
                         className="text-center">
                        <div className="middle_button text-center">
                            <a style={{color: "white", textDecoration: "none"}}>Отправить</a>
                        </div>

                    </div>
                </form>
                </div>
                <hr/>

                <hr/>
                <h4 className="text-center" style={{marginBottom: '20px', marginTop: '30px'}}>Рубрики сайта</h4>
                <div style={{margin:'0 auto'}} className="overflow-auto col-lg-8 ">

                    <table className="table table-striped">
                        <tbody>
                        {this.state.HeadingsTypeArraySite.map(items => (
                            <tr>
                                <td className='col-lg-4'>{items.title}</td>
                                <td className='col-lg-4'>{items.type}   </td>
                                <td className='col-lg-2'><input checked={items.status} onChange={()=>this.changeHeadings(items.id)} type='checkbox'/>
                                </td>
                                <td className='col-lg-2'><a className="delete_specification"
                                                            onClick={() => this.deleteHeadings(items.id)}>Удалить рубрику</a>
                                </td>
                            </tr>

                        ))

                        }

                        </tbody>
                    </table>
                </div>
                <h4 className="text-center" style={{marginBottom: '20px', marginTop: '30px'}}>Рубрики мобильного приложения</h4>
                <div style={{margin:'0 auto'}} className="overflow-auto col-lg-8">
                    <table className="table table-striped">
                        <tbody>
                        {this.state.HeadingsTypeArrayMobile.map(items => (
                            <tr>
                                <td className='col-lg-4'>{items.title}</td>
                                <td className='col-lg-4'>{items.type}   </td>
                                <td className='col-lg-2'><input checked={items.status} onChange={()=>this.changeHeadings(items.id)} type='checkbox'/>
                                </td>
                                <td className='col-lg-2'><a className="delete_specification"
                                       onClick={() => this.deleteHeadings(items.id)}>Удалить рубрику</a>
                                </td>
                            </tr>

                        ))

                        }

                        </tbody>
                    </table>
                </div>

            </div>)
        } else {
            return ('Загрузка...')
        }


    }
}

// @ts-ignore
export default Headings;